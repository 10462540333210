import Module from './base/module';
import {debounce} from 'throttle-debounce';


export default class Invoice extends Module{
  init() {
    this.data.dateFromInstance = null;
    this.data.dateToInstance = null;
    this.data.page = 1;

    this.initSelectors();
  }

  initSelectors() {
    this.selectors.screen = '#invoices-screen';
    this.selectors._screen = document.querySelector(this.selectors.screen);

    this.selectors.pagi = `#invoices-pagination`;
    this.selectors.download = `#invoices-excel-download`;
    this.selectors.prevPage = `${this.selectors.pagi} .prev`;
    this.selectors.nextPage = `${this.selectors.pagi} .next`;
    this.selectors.downloadButton = `${this.selectors.download} .download`;
    this.selectors.filter = `#invoices-filter`;

    this.selectors.list = '.invoices-list';
    this.selectors.tplListItem = 'invoice-list__item';
    this.selectors.listItem = '.invoice-list__item';

    this.selectors.totals = '#invoices-totals';

    this.selectors.modal = '#invoiceModal';
    this.selectors.createBtn = '#create__invoice';
    this.selectors.deleteBtn = `${this.selectors.listItem} .delete`; 
    this.selectors.productsTable = `${this.selectors.modal} .productsTable`;
    this.selectors.productDiscountType = `${this.selectors.modal} .invoiceProductDiscountType input`;
    this.selectors.productDiscountValue = `${this.selectors.modal} #invoiceProductDiscount`;
    this.selectors.productItem = `${this.selectors.productsTable} .product__item`;
    this.selectors.productItemSelect = '.select';

    this.selectors.modalSearch = `#invoiceProductSearch`;
    this.selectors.total = `${this.selectors.modal} tfoot .total`;
    this.selectors.discount_total = `${this.selectors.modal} tfoot .discount_total`;
  }

  run() {
    this.data.modal = M.Modal.init($(this.selectors.modal))[0];
    this.listeners();
    this.load();
    this.initDatetime(); 
  } 

  initDatetime() {
    const dateFrom = this.selectors._screen.querySelector('input[name="date_from"]');
    const dateTo = this.selectors._screen.querySelector('input[name="date_to"]');
    const dateFromInstance = M.Datepicker.init(dateFrom, this.app.data.config.datetime);
    const dateToInstance = M.Datepicker.init(dateTo, this.app.data.config.datetime);

    this.data.dateFromInstance = dateFromInstance;
    this.data.dateToInstance = dateToInstance;

    dateFrom.addEventListener('change', e => {
      dateToInstance.options.minDate = dateFromInstance.date;
    });
    dateTo.addEventListener('change', e => {
      dateFromInstance.options.maxDate = e.target.value ? new Date(dateToInstance.date) : new Date();
    });
  }

  listeners() {
    $(this.selectors.list).on('click', this.selectors.deleteBtn, async (e) => {
        let invoice_id = $(e.target).closest(this.selectors.listItem).data('id');
        const res = await this.app.fetch('Invoice', 'getInvoiceProductIds', {invoice_id: invoice_id});
        if (res.status){ 
          this.deleteInvoiceById(invoice_id, res.data.product_ids);
        } 
    });  
    
    $(this.selectors.createBtn).on('click', this.create.bind(this));
    $(this.selectors.modalSearch).on('keydown', debounce(500, () => this.search()).bind(this));

    $(this.selectors.productsTable).on('change', 'input[name=\'discountPrice\']', e => {
        let price = parseFloat($(e.target)?.val()) || 0;
        $(e.target).closest('tr').attr('data-discountprice', price);
        this.calculateTotal();
    }); 
    
    $(this.selectors.productDiscountValue).on('change', e => {
        this.totalDiscountHandler();
        this.calculateTotal();
    });  
    
    $(this.selectors.productDiscountType).on('change', e => {
        this.totalDiscountHandler();
        this.calculateTotal();
    }); 

    $(this.selectors.productsTable).on('click', this.selectors.productItemSelect, e => {
      this.checkHandler(e);
      this.calculateTotal();
    });
    
    $(this.selectors.downloadButton).on('click', () => {
        this.downloadInvoicesTable();
    });

    $(this.selectors.filter).on('change keypress', debounce(350, () => this.load()));

    $(this.selectors.prevPage).on('click', () => {
      if ($(this.selectors.prevPage).hasClass('disabled')) return;
      this.load(this.data.page - 1);
    });
    $(this.selectors.nextPage).on('click', () => {
      if ($(this.selectors.nextPage).hasClass('disabled')) return;
      this.load(this.data.page + 1);
    });
  }

  async deleteInvoiceById(id, product_ids){
      if (!confirm(`Вы уверены, что хотите удалить товарный чек №"${id}"`)) return;
      let pass = prompt('Введите секретный ключ');
      if (pass !== '701735') return M.toast({html: 'Неверный секретный ключ'}); 
      const res = await this.app.fetch('Invoice', 'delete', {id: id, product_ids: product_ids}); 
      if (!res.status) return M.toast({html: res.message || 'Произошла ошибка, повторите попытку'});
      this.load();
      return M.toast({html: `Товарный чек №${id} успешно удален`}); 
  }
  
  handlePagination(page, pages) {
    const $prev = $(this.selectors.prevPage);
    const $next = $(this.selectors.nextPage);

    $prev.add($next).removeClass('disabled');

    if (page <= 1) $prev.addClass('disabled');
    if (page >= pages) $next.addClass('disabled');
  }

  getFilters() {
    let dateFrom = this.data.dateFromInstance?.date / 1000 || null;
    let dateTo = this.data.dateToInstance?.date / 1000 || null;
    let id = parseInt($(this.selectors.filter).find('[name="id"]').val()) || null;
    let price = parseFloat($(this.selectors.filter).find('[name="price"]').val());
    if (isNaN(price)) price = null;

    return {
      id,
      price,
      dateFrom,
      dateTo,
    };
  }

  async downloadInvoicesTable(){
      let response = await this.app.fetch('invoice', 'getAll', {
          ...this.getFilters(),
      });
      if (!response.status) return;
      window.open(response.data.file,'_blank' );
  }
  
  async load(page = 1) {
    let response = await this.app.fetch('invoice', 'get', {
      page,
      ...this.getFilters(),
    });
    if (!response.status) return;

    this.handlePagination(response.data.page, response.data.pages);

    this.data.page = page;

    let $list = $(this.selectors.list).find('tbody');

    $list.html('');
    
    //Установка итого
    const totals = document.querySelector(this.selectors.totals);
    totals.textContent = new Intl.NumberFormat('ru-RU').format(response.data.totals);

    response.data.items.forEach(el => {
      if ($(`${this.selectors.listItem}[data-id="${el.id}"]`).length) return;
      let item = this.app.getTemplate(this.selectors.tplListItem, el);
      $list.append(item);
    });
  }

  totalDiscountHandler(){
      if($(this.selectors.productDiscountType)[0].checked){
          let $products = $(this.selectors.productItem + '.added');
          let discount = 0;
          $products.each((idx, el) => {
              discount = discount + (parseFloat($(el).attr('data-discountprice')) || 0);
          });
          const DiscPrice = discount > 0 ? discount : this.getSelectedPrices();
          let totalDiscount = DiscPrice / 100 * $(this.selectors.productDiscountValue).val();
          
          if($(this.selectors.productDiscountValue).val() > 100) $(this.selectors.productDiscountValue).val(100)
          
          $(this.selectors.productDiscountValue).attr('data-totaldiscount', totalDiscount)
          $(this.selectors.productDiscountValue).attr('max', 100)
      } else { 
          $(this.selectors.productDiscountValue).attr('data-totaldiscount', $(this.selectors.productDiscountValue).val())
          $(this.selectors.productDiscountValue).removeAttr('max')
      }  
  }
  
  checkHandler(e) {
        let $el = $(e.target);
        let checked = $el.prop('checked');
        if (checked){
            $el.closest('tr').addClass('added');
            $el.closest('tr').find('input[name=\'discountPrice\']').removeAttr('readonly');
        } else {
            $el.closest('tr').removeClass('added');
            $el.closest('tr').find('input[name=\'discountPrice\']').attr('readonly', true);
        }
      const preTotalDisc = this.getSelectedDiscount() > 0 ? this.getSelectedDiscount() : this.getSelectedPrices();
      if(preTotalDisc === 0){
          $(this.selectors.productDiscountType).attr('disabled', '');
          $(this.selectors.productDiscountValue).attr('disabled', '');
          $el.closest('tr').find('input[name=\'discountPrice\']').attr('readonly', true);
      } else {
          $(this.selectors.productDiscountType).removeAttr('disabled');
          $(this.selectors.productDiscountValue).removeAttr('disabled');
          $el.closest('tr').find('input[name=\'discountPrice\']').removeAttr('readonly');
      }
  }

  calculateTotal() {
    let $tfoot = $(this.selectors.modal).find('tfoot');
    let $total = $(this.selectors.total);
    let $discount_total = $(this.selectors.discount_total);
    let $selected = $(this.selectors.productItem + '.added');

    let total = 0;
    let discount = 0;
    $selected.each((idx, el) => {
      total += parseFloat($(el).data('price')) || 0;
      discount += parseFloat($(el).attr('data-discountprice')) || 0;
    });
    discount -= parseFloat($(this.selectors.productDiscountValue).attr('data-totaldiscount')) || 0;
      
    if (total >= 0) {
      $tfoot.show();
      $total.html(total);
      $discount_total.html(discount < 0 ? 0 : discount);
      return;
    }

    $tfoot.hide();
  }

  search() {
    let q = $(this.selectors.modalSearch).val().trim();

    let $products = $(this.selectors.productsTable).find('tbody');

    $products.find('tr').each((idx, el) => {
      let $el = $(el);
      if (!$el.find(this.selectors.productItemSelect).prop('checked')) $el.remove();
    });

    if (q.length <= 2) return;

    this.app.doAjax('invoice', 'search', {q: q}, data => {
      if (!data.status) return;
      
      data.data.products.forEach(el => {
        
          if ($products.find(`tr[data-id="${el.type}_${el.id}"]`).length) return;

          let price = (() => { return el.price !== null ? el.price : '&mdash;'; });
        
          let discount_price = (() => {
            if(el.discount !== null) {
              let today = new Date().toLocaleString().slice(0, 10).split('.');
              today = new Date(today[2],today[1],today[0]);
              let date = JSON.parse(el.discount).date_end.split('.');
              date = new Date(date[0], date[1], date[2]);
              if(today <= date){
                if(JSON.parse(el.discount).type === 'percent'){
                  return el.price - (JSON.parse(el.discount).value * el.price) / 100;
                } else {
                  return el.price - JSON.parse(el.discount).value;
                }
              } else { 
                return '&mdash;';
              }
            } else {
              return '&mdash;';
            }
          });

          let discount = (() => {
              if (el.discount !== null){
                  let today = new Date().toLocaleString().slice(0, 10).split('.');
                  today = new Date(today[2], today[1], today[0]);
                  let date = JSON.parse(el.discount).date_end.split('.');
                  date = new Date(date[0], date[1], date[2]);
                  if (today <= date) {
                      let type = JSON.parse(el.discount).type === 'percent' ? '%' : ' руб.'; 
                      return JSON.parse(el.discount).value + type + ' до ' + JSON.parse(el.discount).date_end;
                  } else {return '&mdash;';}
              } else {return '&mdash;';}
           });
            
        let data_discount = '';
        if(el.discount){
            const date_end = JSON.parse(el.discount).date_end;
            const date = new Date();
            const currentDate = date.getFullYear() +'.'+ ('0'+(date.getMonth()+1)).slice(-2) +'.'+ date.getDate().toString().padStart(2, '0');
            if(currentDate < date_end) data_discount = JSON.parse(el.discount).type === 'percent' ? el.price * JSON.parse(el.discount).value / 100 : JSON.parse(el.discount).value;
        }  
            
        $products.append(`
                <tr class="product__item" data-id="${el.type}_${el.id}" data-price=${el.price} data-discountprice=${typeof discount_price() === 'number' ? discount_price() : price()}> 
                    <td>
                        <label>
                            <input class="select" type="checkbox">
                            <span></span>
                        </label>
                    </td>
                    <td>${el.sku}</td>
                    <td>${el.name}</td>
                    <td>${price()}</td>
                    <td><input min="0" step="1" type="number" name="discountPrice" value="${typeof discount_price() === 'number' ? discount_price() : price()}" readonly></td>
                    <td>${discount()}</td>
                </tr> 
                `);
      });
    });
  }

  getSelectedIds() {
    let $products = $(this.selectors.productItem + '.added');
    let ids = [];

    $products.each((idx, el) => {
      ids.push($(el).data('id'));
    });

    return ids;
  }

  getSelectedPrices() {
    let $products = $(this.selectors.productItem + '.added');
    let prices = 0;
    $products.each((idx, el) => {
      prices += parseFloat($(el).data('price')) || 0;
    });
    return prices;
  } 
   
  getSelectedDiscount(){
    let $products = $(this.selectors.productItem + '.added');
    let discount = 0; 
    $products.each((idx, el) => {
      discount = discount + (parseFloat($(el).attr('data-discountprice')) || 0);
    });
    discount -= parseFloat($(this.selectors.productDiscountValue).attr('data-totaldiscount')) || 0;
    return discount < 0 ? 0 : discount;
  }    

  close() {
    let $checkboxes = $(this.selectors.modal).find(this.selectors.productItemSelect);

    if ($checkboxes.length) {
      $checkboxes.each((idx, el) => {
        if ($(el).prop('checked')) $(el).trigger('click');
      });
    }

    $(this.selectors.productDiscountType).checked = false;
    $(this.selectors.productDiscountValue).val('');
    $(this.selectors.productDiscountValue).attr('data-totaldiscount', '');
    $(this.selectors.modalSearch).val('');
    this.calculateTotal();
    $(this.selectors.productsTable).find('tbody').html('');
      
    this.data.modal.close();
  } 

  async create() {
        if (this.getSelectedIds().length === 0) return M.toast({html: 'Выберите товары!'}); 
        if (!confirm('Вы уверены, что хотите создать товарный чек с этими товарами?')) return;
        let requestData = {
          ids: this.getSelectedIds(),
          discount: this.getSelectedPrices() - this.getSelectedDiscount(),
          price: this.getSelectedPrices(), 
        };
        const res = await this.app.fetch('invoice', 'create', requestData);
        if (!res.status) return M.toast({html: res.message || 'Произошла ошибка, повторите попытку'});
        this.load();
        this.close();
        return M.toast({html: 'Товарный чек успешно создан'});
  }
  
}


