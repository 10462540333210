import CategoryHandler from "./../base/categoryHandler";

export default class Groups extends CategoryHandler {
    init() {
        this.data.favorites = true;

        this.ajax = {
            action: 'group'
        };

        this.labels = {
            errorSelectedItem: 'Выберите другую группу',
            resetNotice: 'Вы уверены, что хотите сбросить группу?'
        };

        this.selectors.$modal = $('#modalProductGroup');
        this.selectors.$input = $("[name='data[group]']");

        this.selectors.$addToFavorite = this.selectors.$modal.find('.group_add_to_fav');
        this.selectors.$favoriteList = this.selectors.$modal.find('.groupFavoriteList');
        this.selectors.favoriteItem = '.group_favorite-list__item';
        this.selectors.favoriteDelete = `${this.selectors.favoriteItem} .delete`;
        this.selectors.favoriteItemTpl = 'groupFavorite-list__item';
    }

    getFiltersName(values) {
        values = Array.from(values);

        let result = [];

        let group;

        values.forEach((item) => {
             group = this.getById(item);

            if (!group || !group.text) {
                return;
            }

            result.push({
                name: group.text,
                value: item
            });

        });

        return result;
    }
}
