import Module from './../base/module';




export default class TiuProductsSortPanel extends Module
{
    init()
    {
        this.selectors.tiuSortPanel = document.querySelector('.tiu_sort_panel');

        this.selectors.tiuSortPanelSku = this.selectors.tiuSortPanel.querySelector('.sort_sku');
        this.selectors.tiuSortPanelMark = this.selectors.tiuSortPanel.querySelector('.sort_mark');
        this.selectors.tiuSortPanelModel = this.selectors.tiuSortPanel.querySelector('.sort_model');
        this.selectors.tiuSortPanelPrice = this.selectors.tiuSortPanel.querySelector('.sort_price');
        this.selectors.tiuSortPanelPresence = this.selectors.tiuSortPanel.querySelector('.sort_presence');
        this.selectors.tiuSortPanelStorage = this.selectors.tiuSortPanel.querySelector('.sort_storage');

        this.selectors.$tiuSortPanelSku = $('.tiu_sort_panel .sort_sku');
        this.selectors.$tiuSortPanelMark = $('.tiu_sort_panel .sort_mark');
        this.selectors.$tiuSortPanelModel = $('.tiu_sort_panel .sort_model');
        this.selectors.$tiuSortPanelGeneration = $('.tiu_sort_panel .sort_generation');
        this.selectors.$tiuSortPanelDiscount = $('.tiu_sort_panel .sort_discount');
        this.selectors.$tiuSortPanelPrice = $('.tiu_sort_panel .sort_price');
        this.selectors.$tiuSortPanelPresence = $('.tiu_sort_panel .sort_presence');
        this.selectors.$tiuSortPanelStorage = $('.tiu_sort_panel .sort_storage');
        
        // this.selectors.$tiuSortPanelPriceDiscount = $('.tiu_sort_panel .sort_price_discount');
        this.selectors.$headPanelDropFilters = $('.head-panel-drop_filters');
        this.selectors.$tiuDropFilters = '.drop_filters';
        
    }

    run()
    {
        this.listeners();
        this.setup();
    }


    listeners()
    {
        this.selectors.$headPanelDropFilters.on('click', this.selectors.$tiuDropFilters, () => {
            if(this.selectors.$tiuSortPanelSku.val() !== 'all'){
                this.selectors.$tiuSortPanelSku.val('all').trigger('change');
            }  
            if(this.selectors.$tiuSortPanelMark.val() !== 'all'){
                this.selectors.$tiuSortPanelMark.val('all').trigger('change');
            }
            if(this.selectors.$tiuSortPanelModel.val() !== 'all'){
                this.selectors.$tiuSortPanelModel.val('all').trigger('change');
            }
            if(this.selectors.$tiuSortPanelGeneration.val() !== 'all'){
                this.selectors.$tiuSortPanelGeneration.val('all').trigger('change');
            }
            if(this.selectors.$tiuSortPanelDiscount.val() !== 'all'){
                this.selectors.$tiuSortPanelDiscount.val('all').trigger('change');
            }
            if(this.selectors.$tiuSortPanelPrice.val() !== 'all'){
                this.selectors.$tiuSortPanelPrice.val('all').trigger('change');
            }
            if(this.selectors.$tiuSortPanelPresence.val() !== 'all'){
                this.selectors.$tiuSortPanelPresence.val('all').trigger('change');
            }
            if(this.selectors.$tiuSortPanelStorage.val() !== 'all'){
                this.selectors.$tiuSortPanelStorage.val('all').trigger('change');
            }
            // if(this.selectors.$tiuSortPanelPriceDiscount.val() !== 'all') this.selectors.$tiuSortPanelPriceDiscount.val('all').trigger('change');

            this.app.modules.TiuProduct.fetchProducts();
        });
    }


    setup() {
        this.select2FieldsConfig();
    
        window.addEventListener('load', function(){
            setTimeout(function () {
                this.app.modules.TiuProduct.fetchProducts();
            }, 3000);
        });
        this.sessStorageHundlerOn();
    } 
 
    setFiltersFromSession(){
        let presence = null;
        let price = null;
        let discount = null;

        if(JSON.parse(sessionStorage.getItem('sortPanelSelectedValue'))){
            if(Object.keys(JSON.parse(sessionStorage.getItem('sortPanelSelectedValue'))).includes('presence')){
                presence = JSON.parse(sessionStorage.getItem('sortPanelSelectedValue')).presence;
            }
            if(Object.keys(JSON.parse(sessionStorage.getItem('sortPanelSelectedValue'))).includes('price')){
                price = JSON.parse(sessionStorage.getItem('sortPanelSelectedValue')).price;
            }
            if(Object.keys(JSON.parse(sessionStorage.getItem('sortPanelSelectedValue'))).includes('discount')){
                discount = JSON.parse(sessionStorage.getItem('sortPanelSelectedValue')).discount;
            }
        }

        presence !== null ? this.selectors.$tiuSortPanelPresence.val(presence).trigger('change') : this.selectors.$tiuSortPanelPresence.val('all').trigger('change');
        price !== null ? this.selectors.$tiuSortPanelPrice.val(price).trigger('change') : this.selectors.$tiuSortPanelPrice.val('all').trigger('change');
        discount !== null ? this.selectors.$tiuSortPanelDiscount.val(discount).trigger('change') : this.selectors.$tiuSortPanelDiscount.val('all').trigger('change');
    }

    async getMark(markNameParam = null, markIdParam = null){
        try{
            await this.app.fetch('characteristic', 'getMark').then((data)=>{
                let markName = null;
                this.selectors.$tiuSortPanelMark.children('option:not([value="all"]):not([value="empty"])').remove();
                data.data.marks.forEach((obj) => {
                    if (markNameParam === obj.name) markName = markNameParam;
                    this.selectors.$tiuSortPanelMark.append(`<option value="${obj.name}" data-id="${obj.id}">${obj.name}</option>`);
                });
                markName !== null ? this.selectors.$tiuSortPanelMark.val(markName).trigger('change') : this.selectors.$tiuSortPanelMark.val('all').trigger('change');
            });
        } catch (err){
            console.error(err)
        }
        
    }

    async getModel(modelNameParam = null, modelIdParam = null){
        let id = modelIdParam;
        if(modelIdParam == null){
            if (this.selectors.tiuSortPanelMark.selectedOptions.length) {
                if (this.selectors.tiuSortPanelMark.selectedOptions[0].dataset.hasOwnProperty('id')) id = this.selectors.tiuSortPanelMark.selectedOptions[0].dataset.id;
            }
        }
        try{
            let modelName = null;
            this.selectors.$tiuSortPanelModel.children('option:not([value="all"]):not([value="empty"])').remove();
            if (id !== null){
                await this.app.fetch('characteristic', 'getModel', {id_car_mark: id}).then((data) => {
                    data.data.models.forEach((obj) =>{
                        if (modelNameParam === obj.name) modelName = modelNameParam;
                        this.selectors.$tiuSortPanelModel.append(`<option value="${obj.name}" data-id="${obj.id}">${obj.name}</option>`);
                    });
                }); 
            }
            modelName !== null ? this.selectors.$tiuSortPanelModel.val(modelName).trigger('change') : this.selectors.$tiuSortPanelModel.val('all').trigger('change');
        } catch (err){
            console.error(err)
        }
    }

    async getGeneration(generationNameParam = null, generationIdParam = null){
        let id = generationIdParam;
        if(generationIdParam == null){ 
            if (this.selectors.tiuSortPanelModel.selectedOptions.length){
                if (this.selectors.tiuSortPanelModel.selectedOptions[0].dataset.hasOwnProperty('id')) id = this.selectors.tiuSortPanelModel.selectedOptions[0].dataset.id;
            } 
        }
        try{
            let generationName = null;
            this.selectors.$tiuSortPanelGeneration.children('option:not([value="all"]):not([value="empty"])').remove();
            if (id !== null){
                await this.app.fetch('characteristic', 'getGeneration', {id_car_model: id}).then((data) =>{
                        if (generationNameParam !== null && generationNameParam !== "empty"){
                            this.selectors.$tiuSortPanelGeneration.append(`<option value="${generationNameParam}" data-id="${generationIdParam}">${generationNameParam}</option>`);
                            generationName = generationNameParam;
                        }
                        data.data.generation.forEach((obj) =>{
                            if(obj.year_start == null) obj.year_start = 'н.в';
                            if(obj.year_stop == null) obj.year_stop = 'н.в';
                            const year = obj.year_start || obj.year_stop ? ` (${obj.year_start} - ${obj.year_stop})` : ``;
                            if (generationNameParam === obj.name+year) generationName = generationNameParam;
                            if (generationNameParam === obj.name) generationName = generationNameParam+year;
                            this.selectors.$tiuSortPanelGeneration.append(`<option value="${obj.name}${year}" data-id="${obj.id}">${obj.name}${year}</option>`);
                        });
                });
            }
            generationName !== null ? this.selectors.$tiuSortPanelGeneration.val(generationName).trigger('change') : this.selectors.$tiuSortPanelGeneration.val('all').trigger('change');
        } catch (err){
                console.error(err)
         }
    }

    select2FieldsConfig(){
        this.selectors.$tiuSortPanelMark.select2({
            dropdownAutoWidth: true,
            width: '100px',
            tags: true,
            templateResult: (data) => {
                if (!data.id || data.id === 'all' || data.id === 'empty') return data.text;
                const $html = `<div style="display: flex;align-items: center;"><span style="background-image: url('/data/logos/` + data.element.dataset.id + `.png');width: 30px;height: 30px;margin-right: 5px"></span><span>` + data.text + `</span></div>`;
                if (data.element) return $($html);
            },
            templateSelection: (data) => {
                if (data.id === 'all') return 'Марка';
                return data.text;
            }
        });
        this.selectors.$tiuSortPanelModel.select2({
            dropdownAutoWidth: true,
            width: '100px',
            tags: true,
            templateSelection: (data) => {
                if (data.id === 'all') return 'Модель';
                return data.text;
            }
        });
        this.selectors.$tiuSortPanelGeneration.select2({
            dropdownAutoWidth: true,
            width: '100px',
            tags: true,
            templateSelection: (data) => {
                if (data.id === 'all') return 'Поколение';
                return data.text;
            }
        });
        this.selectors.$tiuSortPanelPrice.select2({
            dropdownAutoWidth: true,
            width: '100px',
            templateSelection: (data) => {
                if (data.id === 'all') return 'Цена';
                return data.text;
            },
            minimumResultsForSearch: -1,
        });
        this.selectors.$tiuSortPanelDiscount.select2({
            dropdownAutoWidth: true,
            width: '100px',
            templateSelection: (data) => {
                if (data.id === 'all') return 'Скидка';
                return data.text;
            },
            minimumResultsForSearch: -1,
        });
        this.selectors.$tiuSortPanelSku.select2({
            dropdownAutoWidth: true,
            width: '100px',
            tags: true,
            templateSelection: (data) => {
                if (data.id === 'all') return 'Артикул';
                return data.text;
            },
        });
        // this.selectors.$tiuSortPanelPriceDiscount.select2({
        //     dropdownAutoWidth: true,
        //     width: '140px', 
        //     templateSelection: (data) => {
        //         if (data.id === 'all') return 'Цена со скидкой';
        //         return data.text;
        //     },
        //     minimumResultsForSearch: -1,
        // });
        this.selectors.$tiuSortPanelPresence.select2({
            dropdownAutoWidth: true,
            width: '100px',
            templateSelection: (data) => {
                if (data.id === 'all') return 'Наличие';
                return data.text;
            },
            minimumResultsForSearch: -1,
        });
        this.selectors.$tiuSortPanelStorage.select2({
            dropdownAutoWidth: true,
            width: '150px',
            tags: true,
            templateSelection: (data) => {
                if (data.id === 'all') return 'Секция хранения';
                return data.text;
            }
        }).on("select2:open", function() {
                $(".select2-search__field").mask("00-00-00-00-00");
        });
    }

    dropFiltersHundler(){
        // const priceDiscountVal = this.selectors.$tiuSortPanelPriceDiscount[0].value;
        let skuVal = this.selectors.$tiuSortPanelSku[0].value;
        let markVal = this.selectors.$tiuSortPanelMark[0].value;
        let markId = this.selectors.$tiuSortPanelMark[0].selectedOptions[0].dataset.id;
        
        let modelVal = this.selectors.$tiuSortPanelModel[0].value;
        let modelId = this.selectors.$tiuSortPanelModel[0].selectedOptions[0].dataset.id;
        
        let generationVal = this.selectors.$tiuSortPanelGeneration[0].value;
        let generationId = this.selectors.$tiuSortPanelGeneration[0].selectedOptions[0].dataset.id;
        
        let presenceVal = this.selectors.$tiuSortPanelPresence[0].value;
        let priceVal = this.selectors.$tiuSortPanelPrice[0].value;
        let discountVal = this.selectors.$tiuSortPanelDiscount[0].value;
        let storageVal = this.selectors.$tiuSortPanelStorage[0].value;

        const sortPanelSelectedValue = {
            mark : {},
            model : {},
            generation : {}
        }
        
        if(this.selectors.$tiuSortPanelSku[0].value !== 'all') sortPanelSelectedValue.sku = skuVal;
        if(this.selectors.$tiuSortPanelMark[0].value !== 'all'){
            sortPanelSelectedValue.mark.id = markId; 
            sortPanelSelectedValue.mark.val = markVal; 
        } 
        if(this.selectors.$tiuSortPanelModel[0].value !== 'all'){
            sortPanelSelectedValue.model.id = modelId;
            sortPanelSelectedValue.model.val = modelVal;
        }
        if(this.selectors.$tiuSortPanelGeneration[0].value !== 'all'){
            sortPanelSelectedValue.generation.id = generationId;
            sortPanelSelectedValue.generation.val = generationVal;
        } 
        if(this.selectors.$tiuSortPanelStorage[0].value !== 'all') sortPanelSelectedValue.storage = storageVal;
        if(this.selectors.$tiuSortPanelPresence[0].value !== 'all') sortPanelSelectedValue.presence = presenceVal;
        if(this.selectors.$tiuSortPanelPrice[0].value !== 'all') sortPanelSelectedValue.price = priceVal;
        if(this.selectors.$tiuSortPanelDiscount[0].value !== 'all') sortPanelSelectedValue.discount = discountVal;
        
        if(sortPanelSelectedValue) sessionStorage.setItem('sortPanelSelectedValue', JSON.stringify(sortPanelSelectedValue));

        if(skuVal !== 'all' || markVal !== 'all' || modelVal !== 'all' || generationVal !== 'all' || discountVal !== 'all' || presenceVal !== 'all' || priceVal !== 'all' || storageVal !== 'all'){
            this.selectors.$headPanelDropFilters.fadeIn('fast');
        } else {
            this.selectors.$headPanelDropFilters.fadeOut('fast');
        }
    }

    sessStorageHundlerOn(){
            let markVal = null;
            let markId = null;
            let modelVal = null;
            let modelId = null;
            let generationVal = null;
            let generationId = null;

            if (JSON.parse(sessionStorage.getItem('sortPanelSelectedValue'))){
                if (Object.keys(JSON.parse(sessionStorage.getItem('sortPanelSelectedValue'))).includes('mark'))
                {
                    markVal = JSON.parse(sessionStorage.getItem('sortPanelSelectedValue')).mark.val;
                    markId = JSON.parse(sessionStorage.getItem('sortPanelSelectedValue')).mark.id;
                }
                if (Object.keys(JSON.parse(sessionStorage.getItem('sortPanelSelectedValue'))).includes('model'))
                {
                    modelVal = JSON.parse(sessionStorage.getItem('sortPanelSelectedValue')).model.val;
                    modelId = JSON.parse(sessionStorage.getItem('sortPanelSelectedValue')).model.id;
                }
                if (Object.keys(JSON.parse(sessionStorage.getItem('sortPanelSelectedValue'))).includes('generation'))
                {
                    generationVal = JSON.parse(sessionStorage.getItem('sortPanelSelectedValue')).generation.val;
                    generationId = JSON.parse(sessionStorage.getItem('sortPanelSelectedValue')).generation.id;
                }
            }
            
            this.getMark(markVal); 
            this.getModel(modelVal, markId);
            this.getGeneration(generationVal, modelId);
            
            setTimeout(this.eventsHundlerOn.bind(this), 5000);
    }

    eventsHundlerOn(){
        this.selectors.$tiuSortPanelMark.on('change', () => {
            this.getModel();
            this.getGeneration();
            this.dropFiltersHundler();
        });

        this.selectors.$tiuSortPanelModel.on('change', () => {
            this.dropFiltersHundler();
            this.getGeneration();
        });

        this.selectors.$tiuSortPanelGeneration.on('change', () => {
            this.dropFiltersHundler();
            this.app.modules.TiuProduct.fetchProducts();
        });

        this.selectors.$tiuSortPanelSku.on('change', () => {
            this.dropFiltersHundler();
            this.app.modules.TiuProduct.fetchProducts();
        });
        
        this.selectors.$tiuSortPanelPrice.on('change', () => {
            this.dropFiltersHundler();
            this.app.modules.TiuProduct.fetchProducts();
        });

        this.selectors.$tiuSortPanelDiscount.on('change', () => {
            this.dropFiltersHundler();
            this.app.modules.TiuProduct.fetchProducts();
        });

        this.selectors.$tiuSortPanelPresence.on('change', () =>{
            this.dropFiltersHundler();
            this.app.modules.TiuProduct.fetchProducts();
        });

        this.selectors.$tiuSortPanelStorage.on('change', () => {
            this.dropFiltersHundler();
            this.app.modules.TiuProduct.fetchProducts();
        });
    }
}