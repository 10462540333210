import Module from './../base/module';
import {debounce} from 'throttle-debounce';


export default class AutodonorsFilter extends Module {

    run() {
        this.listeners();
        this.setup();
    }
    
    init() {
        this.selectors.Screen = document.querySelector('#autodonors-screen');
        this.selectors.Filter = this.selectors.Screen.querySelector('.autodonors_sort_panel');
        this.selectors.$Filter = $('.autodonors_sort_panel');
        this.selectors.$FilterSelects = $('.autodonors_sort_panel select');
 
        this.selectors.Name = this.selectors.Filter.querySelector('#autodonors-filter-name');
        this.selectors.sortMark = this.selectors.Filter.querySelector('.sort_autodonor_mark');
        this.selectors.sortModel = this.selectors.Filter.querySelector('.sort_autodonor_model');
        this.selectors.sortGeneration = this.selectors.Filter.querySelector('.sort_autodonor_generation');
        this.selectors.sortSerie = this.selectors.Filter.querySelector('.sort_autodonor_serie');
        this.selectors.sortStatus = this.selectors.Filter.querySelector('.sort_autodonor_status');
        this.selectors.sortSteerwheel = this.selectors.Filter.querySelector('.sort_autodonor_steerwheel');
        this.selectors.sortDrivetype = this.selectors.Filter.querySelector('.sort_autodonor_drivetype');
        this.selectors.sortColor = this.selectors.Filter.querySelector('.sort_autodonor_color');
        this.selectors.sortPrice = this.selectors.Filter.querySelector('.sort_autodonor_price');
        this.selectors.sortVin = this.selectors.Filter.querySelector('.sort_autodonor_vin');
        this.selectors.sortMileage = this.selectors.Filter.querySelector('.sort_autodonor_mileage');

        this.selectors.$Name = $('#autodonors-filter-name');
        this.selectors.$sortMark = $('.autodonors_sort_panel .sort_autodonor_mark');
        this.selectors.$sortModel = $('.autodonors_sort_panel .sort_autodonor_model');
        this.selectors.$sortGeneration = $('.autodonors_sort_panel .sort_autodonor_generation');
        this.selectors.$sortSerie = $('.autodonors_sort_panel .sort_autodonor_serie');
        this.selectors.$sortStatus = $('.autodonors_sort_panel .sort_autodonor_status');
        this.selectors.$sortSteerwheel = $('.autodonors_sort_panel .sort_autodonor_steerwheel');
        this.selectors.$sortDrivetype = $('.autodonors_sort_panel .sort_autodonor_drivetype');
        this.selectors.$sortColor = $('.autodonors_sort_panel .sort_autodonor_color');
        this.selectors.$sortPrice = $('.autodonors_sort_panel .sort_autodonor_price');
        this.selectors.$sortVin = $('.autodonors_sort_panel .sort_autodonor_vin');
        this.selectors.$sortMileage = $('.autodonors_sort_panel .sort_autodonor_mileage');
        
        this.selectors.$headPanelDropFilters = $('#autodonors-screen .head-panel-drop_autodonor_filters');
        this.selectors.$dropFilters = $('#autodonors-screen .head-panel-drop_autodonor_filters .drop_autodonor_filters');
    }

    listeners(){
        this.selectors.Name.addEventListener('input', debounce(350, ()=>{
            this.dropFiltersHundler();
            this.app.modules.AutodonorsList.fetchAutodonors();
        }));
        
        this.selectors.$FilterSelects.on('change', ()=>{ 
            this.dropFiltersHundler();
            this.app.modules.AutodonorsList.fetchAutodonors();
        });
        
        this.selectors.$dropFilters.on('click', () => {
            this.selectors.$Name.val('');
            this.selectors.$FilterSelects.val('all').trigger('change');
            this.dropFiltersHundler();
            this.app.modules.AutodonorsList.fetchAutodonors();
        });
    }
    
    setup() {
        this.select2FieldsConfig();
        this.sessStorageHundlerOn();
    }

    sessStorageHundlerOn(){
        let markVal = null;
        let markId = null;
        let modelVal = null;
        let modelId = null;
        let generationVal = null;
        let generationId = null;
        let serieVal = null;
        let serieId = null;
        
        // if (JSON.parse(sessionStorage.getItem('sortPanelSelectedValue'))){
        //     if (Object.keys(JSON.parse(sessionStorage.getItem('sortPanelSelectedValue'))).includes('mark'))
        //     {
        //         markVal = JSON.parse(sessionStorage.getItem('sortPanelSelectedValue')).mark.val;
        //         markId = JSON.parse(sessionStorage.getItem('sortPanelSelectedValue')).mark.id;
        //     }
        //     if (Object.keys(JSON.parse(sessionStorage.getItem('sortPanelSelectedValue'))).includes('model'))
        //     {
        //         modelVal = JSON.parse(sessionStorage.getItem('sortPanelSelectedValue')).model.val;
        //         modelId = JSON.parse(sessionStorage.getItem('sortPanelSelectedValue')).model.id;
        //     }
        //     if (Object.keys(JSON.parse(sessionStorage.getItem('sortPanelSelectedValue'))).includes('generation'))
        //     {
        //         generationVal = JSON.parse(sessionStorage.getItem('sortPanelSelectedValue')).generation.val;
        //         generationId = JSON.parse(sessionStorage.getItem('sortPanelSelectedValue')).generation.id;
        //     }
        // }

        this.getMark(markVal);
        this.getModel(modelVal, markId);
        this.getGeneration(generationVal, modelId);
        this.getSerie(serieVal, generationId);
        
        setTimeout(this.eventsHundlerOn.bind(this), 5000);
    }
    
    eventsHundlerOn(){
        this.selectors.$sortMark.on('change', () => {
            this.getModel();
            this.getGeneration();
            this.getSerie();
            this.dropFiltersHundler();
        });
        this.selectors.$sortModel.on('change', () => {
            this.getGeneration();
            this.getSerie();
            this.dropFiltersHundler();
        });
        this.selectors.$sortGeneration.on('change', () => {
            this.getSerie();
            this.dropFiltersHundler();
        });
        this.selectors.$sortSerie.on('change', () => {
            this.dropFiltersHundler();
        });
    }

    eventsHundlerOff() {
        this.selectors.$sortMark.off();
        this.selectors.$sortModel.off();
        this.selectors.$sortGeneration.off();
        this.selectors.$sortSerie.off();
    }
    
    dropFiltersHundler(){
        let markVal = this.selectors.$sortMark[0].value;
        let markId = this.selectors.$sortMark[0].selectedOptions[0].dataset.id;

        let modelVal = this.selectors.$sortModel[0].value;
        let modelId = this.selectors.$sortModel[0].selectedOptions[0].dataset.id;

        let generationVal = this.selectors.$sortGeneration[0].value;
        let generationId = this.selectors.$sortGeneration[0].selectedOptions[0].dataset.id;        
        
        let serieVal = this.selectors.$sortSerie[0].value;
        let serieId = this.selectors.$sortSerie[0].selectedOptions[0].dataset.id;
        
        let statusVal = this.selectors.$sortStatus[0].value;
        let steerwheelVal = this.selectors.$sortSteerwheel[0].value;
        let priceVal = this.selectors.$sortPrice[0].value;
        let drivetypeVal = this.selectors.$sortDrivetype[0].value;
        let colorVal = this.selectors.$sortColor[0].value;
        let vinVal = this.selectors.$sortVin[0].value;
        let mileageVal = this.selectors.$sortMileage[0].value;
        let nameVal = this.selectors.Name.value;

        const sortPanelSelectedValue = {
            mark : {},
            model : {},
            generation : {},
            serie : {}
        }
        
        if(this.selectors.$sortMark[0].value !== 'all'){
            sortPanelSelectedValue.mark.id = markId;
            sortPanelSelectedValue.mark.val = markVal;
        }
        if(this.selectors.$sortModel[0].value !== 'all'){
            sortPanelSelectedValue.model.id = modelId;
            sortPanelSelectedValue.model.val = modelVal;
        }
        if(this.selectors.$sortGeneration[0].value !== 'all'){
            sortPanelSelectedValue.generation.id = generationId;
            sortPanelSelectedValue.generation.val = generationVal;
        }
        if(this.selectors.$sortSerie[0].value !== 'all'){
            sortPanelSelectedValue.serie.id = serieId;
            sortPanelSelectedValue.serie.val = serieVal;
        }
        
        if(this.selectors.$sortStatus[0].value !== 'all') sortPanelSelectedValue.status = statusVal;
        if(this.selectors.$sortSteerwheel[0].value !== 'all') sortPanelSelectedValue.steerwheel = steerwheelVal;
        if(this.selectors.$sortPrice[0].value !== 'all') sortPanelSelectedValue.price = priceVal;
        if(this.selectors.$sortDrivetype[0].value !== 'all') sortPanelSelectedValue.drivetype = drivetypeVal;
        if(this.selectors.$sortColor[0].value !== 'all') sortPanelSelectedValue.color = colorVal;
        if(this.selectors.$sortVin[0].value !== 'all') sortPanelSelectedValue.vin = vinVal;
        if(this.selectors.$sortMileage[0].value !== 'all') sortPanelSelectedValue.mileage = mileageVal;
        if(this.selectors.Name.value !== '') sortPanelSelectedValue.name = nameVal;
        
        // if(sortPanelSelectedValue) sessionStorage.setItem('sortPanelSelectedValue', JSON.stringify(sortPanelSelectedValue));

        if(markVal !== 'all' || modelVal !== 'all' || generationVal !== 'all' || serieVal !== 'all' || statusVal !== 'all' || steerwheelVal !== 'all' || priceVal !== 'all' || drivetypeVal !== 'all' || colorVal !== 'all' || vinVal !== 'all' || mileageVal !== 'all' || nameVal){
            this.selectors.$headPanelDropFilters.fadeIn('fast');
        } else {
            this.selectors.$headPanelDropFilters.fadeOut('fast');
        }
    }
 
    async getMark(markNameParam = null, markIdParam = null){
        try{
            await this.app.fetch('characteristic', 'getMark').then((data)=>{
                let markName = null;
                this.selectors.$sortMark.children('option:not([value="all"]):not([value="empty"])').remove();
                data.data.marks.forEach((obj) => {
                    if (markNameParam === obj.name) markName = markNameParam;
                    this.selectors.$sortMark.append(`<option value="${obj.name}" data-id="${obj.id}">${obj.name}</option>`);
                });
                markName !== null ? this.selectors.$sortMark.val(markName).trigger('change') : this.selectors.$sortMark.val('all').trigger('change');
            });
        } catch (err){
            console.error(err)
        }

    }

    async getModel(modelNameParam = null, modelIdParam = null){
        let id = modelIdParam;
        if(modelIdParam == null){
            if (this.selectors.sortMark.selectedOptions.length) {
                if (this.selectors.sortMark.selectedOptions[0].dataset.hasOwnProperty('id')) id = this.selectors.sortMark.selectedOptions[0].dataset.id;
            }
        }
        try{
            let modelName = null;
            this.selectors.$sortModel.children('option:not([value="all"]):not([value="empty"])').remove();
            if (id !== null){
                await this.app.fetch('characteristic', 'getModel', {id_car_mark: id}).then((data) => {
                    data.data.models.forEach((obj) =>{
                        if (modelNameParam === obj.name) modelName = modelNameParam;
                        this.selectors.$sortModel.append(`<option value="${obj.name}" data-id="${obj.id}">${obj.name}</option>`);
                    });
                });
            }
            modelName !== null ? this.selectors.$sortModel.val(modelName).trigger('change') : this.selectors.$sortModel.val('all').trigger('change');
        } catch (err){
            console.error(err)
        }
    }

    async getGeneration(generationtNameParam = null, generationIdParam = null){
        let id = generationIdParam;
        if(generationIdParam == null){
            if (this.selectors.sortModel.selectedOptions.length){
                if (this.selectors.sortModel.selectedOptions[0].dataset.hasOwnProperty('id')) id = this.selectors.sortModel.selectedOptions[0].dataset.id;
            }
        }
        try{
            let generationName = null;
            this.selectors.$sortGeneration.children('option:not([value="all"]):not([value="empty"])').remove();
            if (id !== null){
                await this.app.fetch('characteristic', 'getGeneration', {id_car_model: id}).then((data) =>{
                    if (generationtNameParam !== null && generationtNameParam !== "empty"){
                        this.selectors.$sortGeneration.append(`<option value="${generationtNameParam}" data-id="${generationIdParam}">${generationtNameParam}</option>`);
                        generationName = generationtNameParam;
                    }
                    data.data.generation.forEach((obj) =>{
                        if(obj.year_start == null) obj.year_start = 'н.в';
                        if(obj.year_stop == null) obj.year_stop = 'н.в';
                        const year = obj.year_start || obj.year_stop ? ` (${obj.year_start} - ${obj.year_stop})` : ``;
                        if (generationtNameParam === obj.name+year) generationName = generationtNameParam;
                        if (generationtNameParam === obj.name) generationName = generationtNameParam+year;
                        this.selectors.$sortGeneration.append(`<option value="${obj.name}${year}" data-id="${obj.id}">${obj.name}${year}</option>`);
                    });
                });
            }
            generationName !== null ? this.selectors.$sortGeneration.val(generationName).trigger('change') : this.selectors.$sortGeneration.val('all').trigger('change');
        } catch (err){
            console.error(err)
        }
    }
    
    async getSerie(serieNameParam = null, serieIdParam = null){
        let id = serieIdParam;
        if(serieIdParam == null){
            if (this.selectors.sortGeneration.selectedOptions.length){
                if (this.selectors.sortGeneration.selectedOptions[0].dataset.hasOwnProperty('id')) id = this.selectors.sortGeneration.selectedOptions[0].dataset.id;
            }
        }
        try{
            let serieName = null;
            this.selectors.$sortSerie.children('option:not([value="all"]):not([value="empty"])').remove();
            if (id !== null){
                await this.app.fetch('characteristic', 'getSerie', {id_car_generation: id}).then((data) =>{
                    if (serieNameParam !== null && serieNameParam !== "empty"){
                        this.selectors.$sortSerie.append(`<option value="${serieNameParam}" data-id="${serieIdParam}">${serieNameParam}</option>`);
                        serieName = serieNameParam;
                    }
                    data.data.serie.forEach((obj) =>{
                        let name = obj.name[0].toUpperCase() + obj.name.slice(1);
                        if(obj.notice) name = name + ' ' + '(' + obj.notice + ')';
                        if(serieNameParam === name) serieName = serieNameParam;
                        this.selectors.$sortSerie.append(`<option value="${name}" data-id="${obj.id}">${name}</option>`);
                    });
                });
            }
            serieName !== null ? this.selectors.$sortSerie.val(serieName).trigger('change') : this.selectors.$sortSerie.val('all').trigger('change');
        } catch (err){
            console.error(err)
        }
    }
    
    getName() {
        const value = this.selectors.Name.value.trim().replaceAll(/\s+/g, ' ');
        return value !== '' ? value : null; 
    }

    getValues() {
        return {
            name: this.getName(),
            mark: this.selectors.$sortMark[0].value !== 'all' ? this.selectors.$sortMark[0].value : null,
            model: this.selectors.$sortModel[0].value !== 'all' ? this.selectors.$sortModel[0].value : null,
            generation: this.selectors.$sortGeneration[0].value !== 'all' ? this.selectors.$sortGeneration[0].value : null,
            serie: this.selectors.$sortSerie[0].value !== 'all' ? this.selectors.$sortSerie[0].value : null,
            status: this.selectors.$sortStatus[0].value !== 'all' ? this.selectors.$sortStatus[0].value : null,
            steerwheel: this.selectors.$sortSteerwheel[0].value !== 'all' ? this.selectors.$sortSteerwheel[0].value : null,
            drivetype: this.selectors.$sortDrivetype[0].value !== 'all' ? this.selectors.$sortDrivetype[0].value : null,
            color: this.selectors.$sortColor[0].value !== 'all' ? this.selectors.$sortColor[0].value : null,
            vin: this.selectors.$sortVin[0].value !== 'all' ? this.selectors.$sortVin[0].value : null,
            mileage: this.selectors.$sortMileage[0].value !== 'all' ? this.selectors.$sortMileage[0].value : null,
            price: this.selectors.$sortPrice[0].value !== 'all' ? this.selectors.$sortPrice[0].value : null,
        };
    }

    getOrderByValues() {
        return { 
            price: this.selectors.$sortPrice[0].value !== 'all' ? this.selectors.$sortPrice[0].value : null,
            mileage: this.selectors.$sortMileage[0].value !== 'all' ? this.selectors.$sortMileage[0].value : null,
        };
    }
    
    select2FieldsConfig(){
        this.selectors.$sortMark.select2({
            dropdownAutoWidth: true,
            width: '80px',
            tags: true,
            templateResult: (data) => {
                if (!data.id || data.id === 'all' || data.id === 'empty') return data.text;
                const $html = `<div style="display: flex;align-items: center;"><span style="background-image: url('/data/logos/` + data.element.dataset.id + `.png');width: 30px;height: 30px;margin-right: 5px"></span><span>` + data.text + `</span></div>`;
                if (data.element) return $($html);
            },  
            templateSelection: (data) => {
                if (data.id === 'all') return 'Марка';
                return data.text;
            }
        });
        this.selectors.$sortModel.select2({
            dropdownAutoWidth: true,
            width: '90px',
            tags: true,
            templateSelection: (data) => {
                if (data.id === 'all') return 'Модель';
                return data.text;
            }
        });
        this.selectors.$sortGeneration.select2({
            dropdownAutoWidth: true,
            width: '110px',
            tags: true,
            templateSelection: (data) => {
                if (data.id === 'all') return 'Поколение';
                return data.text;
            }
        }); 
        this.selectors.$sortSerie.select2({
            dropdownAutoWidth: true,
            width: '80px',
            tags: true,
            templateSelection: (data) => {
                if (data.id === 'all') return 'Кузов';
                return data.text;
            }
        });
        this.selectors.$sortStatus.select2({
            dropdownAutoWidth: true, 
            width: '80px',
            templateSelection: (data) => {
                if (data.id === 'all') return 'Статус';
                return data.text;
            },
            minimumResultsForSearch: -1,
        });
        this.selectors.$sortSteerwheel.select2({
            dropdownAutoWidth: true,
            width: '180px',
            templateSelection: (data) => {
                if (data.id === 'all') return 'Расположение руля';
                return data.text; 
            },
            minimumResultsForSearch: -1,
        });
        this.selectors.$sortDrivetype.select2({
            dropdownAutoWidth: true,
            width: '125px',
            templateSelection: (data) => {
                if (data.id === 'all') return 'Тип привода';
                return data.text;
            },
            minimumResultsForSearch: -1,
        });
        this.selectors.$sortColor.select2({
            dropdownAutoWidth: true,
            width: '70px',
            tags: true,
            templateSelection: (data) => {
                if (data.id === 'all') return 'Цвет';
                return data.text;
            }
        });
        this.selectors.$sortPrice.select2({
            dropdownAutoWidth: true,
            width: '110px',
            tags: true,
            templateSelection: (data) => {
                if (data.id === 'all') return 'Стоимость';
                return data.text;
            }
        });
        this.selectors.$sortVin.select2({
            dropdownAutoWidth: true,
            width: '100px',
            tags: true,
            templateSelection: (data) => {
                if (data.id === 'all') return 'Код VIN';
                return data.text;
            }
        });
        this.selectors.$sortMileage.select2({
            dropdownAutoWidth: true,
            width: '85px',
            tags: true,
            templateSelection: (data) => {
                if (data.id === 'all') return 'Пробег';
                return data.text;
            }
        });
    }
    
}