import Module from './base/module';


export default class Barcode extends Module{
  init() {
    this.selectors.generateBtn = '.product__generate-barcode';
    this.selectors.$generateBtn = $(this.selectors.generateBtn);
    this.selectors.$modal = $('#barcode-list');
    this.selectors.$list = this.selectors.$modal.find('div').eq(0);
    this.selectors.tpl = 'barcode-list__item';

    this.labels = {
      confirmGenerate: 'Вы уверены, что хотите сгенерировать баркоды?',

      errors: {
        emptyProducts: 'Нет ни одного товара с ценой',
      },
    };
  }

  run() {
    this.listeners();
  }

  listeners() {
    $(document.body).on('click', this.selectors.generateBtn, async () => {
      if (confirm(this.labels.confirmGenerate)) {
            await this.generate();
            window.print();
      } 
    }); 
    window.onafterprint = () => {
        this.set_barcode_status();
        this.selectors.$list.html('');
        // this.selectors.$list.find('img').off();
    };

  }

  async set_barcode_status(){
    const ids = this.app.modules.ProductFilter.getValues()['ids'];
    if(ids !==null){
        ids.forEach(async (id)=>{
          await this.app.fetch('product', 'edit_barcode', {id: id, barcode_status: 'printed'})
          this.app.modules.Product.resetList();
        })
    } 
    if(ids === null){
      await this.app.fetch('product', 'edit_barcode', {id: null, barcode_status: 'printed'})
      this.app.modules.Product.resetList();
    }
  }
  
  async generate() {
    let res = await this.app.fetch('product', 'getAll', {
      // fields: ['id', 'sku', 'name', 'price', 'group'],
      fields: ['id', 'sku', 'name', 'group'],
      limit: 9999999,
      // models: ['images'],
      models: null,
      filters: this.app.modules.ProductFilter.getValues(),
      idsStatus: true
    });

    // const products = res?.data?.products?.filter(item => Number(item.price) > 0);
    const products = res?.data?.products;
    
    if (!products?.length) {
      M.toast({html: this.labels.errors.emptyProducts});
      return;
    } 

    this.selectors.$list.html(''); 

    products.map(product => {
      product.name = product.name.replace(/([о|o][e|е][m|м])(\s+)([a-zA-Z0-9а-яА-ЯёЁ])/imu, (match, a, b, c) => { //Поиск по ОЕМ-шаблону и выделение жирным
        return `<b>${a}${b}${c}</b>`;
      }); 
      // product.thumbSmall = this.app.getConfig('thumbsUrl') + product.images?.[0]?.thumbs?.small;

      let $tpl = $(this.app.getTemplate(this.selectors.tpl, product));

      $tpl.find('.code').barcode(product.sku, 'code39', {barWidth: 2, barHeight: 50, showHRI: false});

      this.selectors.$list.append($tpl);
    });
    
    return true;
  }

  async waitLoad() {
    const Imgs = this.selectors.$list[0].querySelectorAll('img');
    let count = Imgs.length;

    const dec = (El) => {
      count--;
      if (count <= 0) {
        window.print();
      }
      El.removeEventListener('load', dec);
    };

    Imgs.forEach(Img => {
      Img.addEventListener('load', () => dec(Img));
      Img.addEventListener('error', () => dec(Img));
    });
  }
}
