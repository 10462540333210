import Module from '../base/module';


export default class TiuProductEdit extends Module {
    init() {
        this.selectors.TiuProductsList = document.querySelector('#tiu_products-list');
        this.selectors.TiuProductModal = document.querySelector('#modalTiuProductEdit');
        let $modal = $('#modalTiuProductEdit');
        
        this.selectors.descriptionWrapper = $('.tiu_product__description-wrapper');
        this.selectors.description = $('.tiu_product__description-wrapper .description');

        this.selectors.btnSave = this.selectors.TiuProductModal.querySelector('#save_tiu_product');
        this.selectors.btnCloseModal = this.selectors.TiuProductModal.querySelector('.modal-close-confirm');

        this.selectors.$keywords = $modal.find('.keywords');
        
        this.selectors.description.trumbowyg({
            lang: 'ru',
            btnsDef: {
                template: {
                    fn: () => {
                        this.selectors.description.trumbowyg('html', this.data.descriptionTemplate);
                    },
                    ico: 'blockquote',
                    title: 'Шаблон',
                },
            },
            btns: [
                ['template'],
                ['undo', 'redo'],
                ['p', 'h1', 'h2'],
                ['strong', 'em'],
                ['link'],
                ['justifyLeft', 'justifyCenter', 'justifyRight'],
                ['unorderedList', 'orderedList'],
                ['horizontalRule'],
                ['removeformat'],
                ['viewHTML'],
                ['fullscreen'],
            ],
            plugins: {
                upload: {
                    serverPath: 'https://api.imgur.com/3/image',
                    fileFieldName: 'image',
                    headers: {
                        'Authorization': 'Client-ID 314186324ed48c6',
                    },
                    urlPropertyName: 'data.link',
                },
            },
            autogrow: true,
            autogrowOnEnter: true,
        });
        
        this.initLabels();
 
        this.data = {
            products: [],
            descriptionTemplate: '<p>Запчасть:&nbsp;</p><p>Марка:&nbsp;</p><p>Модель:&nbsp;</p><p>Цвет:&nbsp;</p><p>Состояние: б/у</p><p>Оригинальный номер:&nbsp;</p><p>Год выпуска:&nbsp;</p>',
        }; 
    }
 
    run() {
        this.listeners(); 
    }

    initLabels() {
        this.TiuProductslabels = {
            closeConfirm: 'Вы уверены, что хотите закрыть это окно?',
            saveConfirm: 'Вы уверены, что хотите сохранить товар?',
            
            errors: {
                invalidName: 'Введите название товара, минимум 5 символов.',
                invalidDescription: 'Введите описание товара, минимум 5 символов',
                addError: 'При сохранении товара произошла ошибка.',
            },
        };
    }

    listeners(){
        this.selectors.TiuProductsList.addEventListener('click',(e) =>
        {
            if (!e.target.closest('#tiu_products-list')) return;
            let TiuProductId = this.getTiuProductId(e);
            if(TiuProductId && e.target.closest('.tiu_product-list__item__edit-btn')){
                const form = this.selectors.TiuProductModal.querySelector('form');
                this.selectors.Modal = M.Modal.init(this.selectors.TiuProductModal, {
                    onOpenEnd: () => {
                        this.animateDescription(); 
                        this.selectors.TiuProductModal.querySelector('#tiu_product-form__name').focus();
                        this.selectors.TiuProductModal.querySelector('label[for="tiu_product-form__price"]').classList.add('active');
                        this.selectors.TiuProductModal.querySelector('label[for="tiu_product-form__sku"]').classList.add('active');
                        this.fillTiuForm(TiuProductId);
                    },     
                    onCloseEnd: () => {
                        form.reset();   
                        this.selectors.description.trumbowyg('empty');
                        this.selectors.TiuProductModal.querySelector('.tiu_product__gallery__items').innerHTML = "";
                        this.app.modules.Gallery.CleanTiuImagesArray();
                        this.app.modules.TiuCharacteristics.reset();
                        this.app.modules.Groups.reset();
                        this.app.modules.TiuCategories.reset();
                        this.app.modules.AvitoCategories.reset();
                        this.app.modules.Autodonors.reset();
                        this.resetKeywords();
                    }, 
                }); 
                this.selectors.$keywords.chips({
                    placeholder: 'Ключевые слова',
                    secondaryPlaceholder: 'добавить еще',
                });
                this.selectors.MKeywords = M.Chips.getInstance(this.selectors.$keywords);
            }
        }); 


        this.selectors.btnCloseModal.addEventListener('click', () => {
            if (confirm(this.TiuProductslabels.closeConfirm)) {
                this.selectors.Modal.close();
            } 
        });
         
        this.selectors.btnSave.addEventListener('click', () => {
            if (confirm(this.TiuProductslabels.saveConfirm)) {
                this.app.modules.Gallery.saveTiuProductImages();
                this.saveTiuForm();
                this.selectors.Modal.close();
            }
         });
    } 
    
    
     escapeHtmlEntities(text) { 
        return [text.replace(/[\uD800-\uDBFF][\uDC00-\uDFFF] | [\u00A0-\u0400\u0402-\u040F\u0450\u0452-\u2115\u2117-\uFFFF]/g, function(c) {
            var charValue;
            if (c.length == 2) {
                var  high = c.charCodeAt(0) - 0xD800;
                var  low  = c.charCodeAt(1) - 0xDC00;
                charValue = (high * 0x400) + low + 0x10000;
            }
            else {
                charValue = c.charCodeAt(0);
            }
            return '&' + (escapeHtmlEntities.entityTable[c.charCodeAt(0)] || '#'+charValue) + ';';}) ];
    }

     async fetchProducts() {
         this.app.modules.TiuProduct.resetList();
         await this.app.modules.TiuProduct.fetchList();
         this.app.modules.TiuProduct.fillList(); 
    }   
    
    getTiuData() {
        let TiuProductData = [];
          
        Array.from(this.selectors.TiuProductModal.querySelectorAll('input')).map((El) => {
            if( El.name == '' || El.value == '' ) return;
            let tiu_input_names = El.name.match(/(?<=\[).+?(?=\])/g);
            TiuProductData[tiu_input_names] = El.value; 
        }); 
        
        let TiuProductPresence = this.selectors.TiuProductModal.querySelector('#tiu_product-form__available');
        let TiuProductCurrency = this.selectors.TiuProductModal.querySelector('#tiu_product-form__currency');
        
        TiuProductPresence.value == "+" ? TiuProductData['presence'] = 'available' : TiuProductData['presence'] = 'not_available';
        if (TiuProductCurrency.value == "RUB") TiuProductData['currency'] = 'RUB';
        
        TiuProductData['description'] = this.selectors.description.trumbowyg('html');

        TiuProductData["warehouse"] = this.app.modules.TiuCharacteristics.selectors.$warehouse.closest('.select-wrapper').find('input').val();
        TiuProductData["storage"] = this.app.modules.TiuCharacteristics.selectors.$storage.val();
 
        let keywordsArray = [];
        Object.entries(this.getKeywords()).forEach(([key, value]) => {
            keywordsArray.push(value.tag);
        });
        TiuProductData['keywords'] = keywordsArray.join();
        
        return TiuProductData;
    } 
    
    async saveTiuForm() {  
        let productData = JSON.stringify(Object.assign({}, this.getTiuData()));
     
        await this.app.modules.TiuCharacteristics.saveAll(this.getTiuData()['id']);
        
        let response = await this.app.fetch('TiuProduct', 'update', {tiuproduct: productData});
        if (response?.status) {
            M.toast({html: 'Товар успешно обновлен'});
            this.fetchProducts(); 
        } else {
            M.toast({html: 'Произошла ошибка при обновлении товара'});
        } 
    }
    
    async fillTiuForm(id) {
            let product = await this.fetchTiuOne(id);
             
            const excludeFields = [
                'external_id',
                'description',
                'selling_type',
                'discount',
                'prices',
                'main_image',
                'images',
                'small',
                'thumbs',
                'status',
                'url',
                'model',
                'vendor',
                'last_update',
                'autodonor_id',
            ]; 
            Object.entries(product).forEach(([key, value]) => {
                if (excludeFields.includes(key)) {
                    return; 
                }
                let input = this.selectors.TiuProductModal.querySelector(`[name="data[${key}]"]`);
                let tiuProductFormAvailable = this.selectors.TiuProductModal.querySelector('#tiu_product-form__available');
                
                if(key == 'presence'){
                      value == 'available' ? tiuProductFormAvailable.value = '+' :  tiuProductFormAvailable.value = '-';
                      M.FormSelect.init(tiuProductFormAvailable);
                }
                if(input !== null) input.value = value;
            });
            
            if (product?.photos?.length && product?.photos[0] !== null) {
                product.photos.forEach((photos, key) => {
                    this.app.modules.Gallery.showTiuProductImage(key, photos);
                }); 
            }
            
            if (product?.characteristics?.length) {
                product.characteristics = JSON.parse(product.characteristics);
                Object.entries(product.characteristics).forEach(([key, value]) => {
                    this.app.modules.TiuCharacteristics.add({
                        name: key,
                        dimension: '',
                        value: value,
                        // parentId: el.parent_id,
                    }); 
                }); 
            }
            
            if (product.hasOwnProperty('storage') && product.storage !==null) {
                this.app.modules.TiuCharacteristics.add({
                    name: 'Секция хранения',
                    value: product.storage,
                });
            }
            
            if (product.hasOwnProperty('warehouse') && product.warehouse !==null) {
                this.app.modules.TiuCharacteristics.add({
                    name: 'Склад хранения',
                    value: product.warehouse,
                });
            }
        
            if (product.hasOwnProperty('avito_category')) {
                this.app.modules.AvitoCategories.set(product.avito_category);
            }

            if (product.hasOwnProperty('autodonor_id')) {
                this.app.modules.Autodonors.setAutodonor(product.autodonor_id, product.autodonors_number);
            }
            
            if (product.hasOwnProperty('category')) {
                this.app.modules.TiuCategories.set(product.category);
            }
        
            if (product.hasOwnProperty('group')) {
                this.app.modules.Groups.set(product.group);
            }
        
            if (product?.keywords?.length) {
                let keywords = product.keywords.split(',');
                $.each(keywords, (i, v) => {
                    let keyword = v.trim();
                    this.setKeyword(keyword);
                });
            }
            
            if (product.hasOwnProperty('description')) {
                this.selectors.description.trumbowyg('html', product.description);
            }
            
    }

    getKeywords() {
        return this.selectors.MKeywords.chipsData;
    }
    
    resetKeywords() {
        let l = this.getKeywords().length;

        for (let i = 0; i <= l; i++) {
            this.selectors.MKeywords.deleteChip(0);
        }
    }
    
    setKeyword(keyword) {
        return this.selectors.MKeywords.addChip({tag: keyword});
    }
     
    async fetchTiuOne(id, fields = null, models = null) {
        let response = await this.app.fetch('TiuProduct', 'get', {productId: id, fields: fields, models: models});
        if (this.app.responseValidate(response, ['product'], 'Произошла ошибка при запросе товара')) {
            return response.data.product;
        }
        return false;
    }
    
    getTiuProductId(e) {
        let el = e.target.closest('.tiu_products-list__item');
        if (!el) return null;
        let id = el.dataset['id'];
        return id ?
            parseInt(id) :
            null;
    }
    
    
    getFormField(name) {
        return this.selectors.TiuProductModal.querySelector(`[name='data[${name}]']`);
    } 
    
    
    animateDescription() {
        let $tbw = this.selectors.description.closest('.trumbowyg');

        this.selectors.description.on('tbwopenfullscreen', (e) => {
            let $pls = $('.trumbowyg-fullscreen-placeholder');

            let plsTop, plsLeft, plsHeight, plsWidth;

            plsTop = $pls.offset().top;
            plsLeft = $pls.offset().left;
            plsHeight = $pls.innerHeight();
            plsWidth = $pls.innerWidth();

            $tbw.removeClass('trumbowyg-fullscreen');

            $tbw.css({
                position: 'fixed',
                'z-index': 99999,
                'background-color': '#fff',
                top: plsTop,
                left: plsLeft,
                height: plsHeight,
                width: plsWidth,
            });

            $tbw.stop().animate({
                top: 0,
                left: 0,
                height: '100%',
                width: '100%',
            }, 350, 'easeInOutExpo', () => {
                this.selectors.TiuProductModal.css({overflow: 'hidden'});
                $tbw.addClass('trumbowyg-fullscreen');
                $tbw.removeAttr('style');
            });
        });

        this.selectors.description.on('tbwclosefullscreen', (e) => {
            let $pls = this.selectors.descriptionWrapper;

            let plsTop, plsLeft, plsHeight, plsWidth;

            plsTop = $pls.offset().top;
            plsLeft = $pls.offset().left;
            plsHeight = $pls.innerHeight();
            plsWidth = $pls.innerWidth();

            $tbw.addClass('trumbowyg-fullscreen');

            $pls.css({
                height: plsHeight,
                width: plsWidth,
            });

            $tbw.css({
                position: 'fixed',
                'z-index': 99999,
                'background-color': '#fff',
                top: 0,
                left: 0,
                height: '100%',
                width: '100%',
            });

            $tbw.stop().animate({
                top: plsTop,
                left: plsLeft,
                height: plsHeight,
                width: plsWidth,
            }, 350, 'easeInOutExpo', () => {
                $tbw.removeClass('trumbowyg-fullscreen');
                $tbw.removeAttr('style');
                $pls.removeAttr('style');
                this.selectors.TiuProductModal.css({overflow: 'auto'});
            });
        });
    }
}
