import Module from '../base/module';
import Modal from "../base/modal";


export default class TiuProductUtils extends Module{
  init() {
    this.selectors.screen = document.querySelector('#tiu_products-screen');
    // this.selectors.toolbar = this.selectors.screen.querySelector('.tiu_products__toolbar');
    this.selectors.toolbar = this.selectors.screen.querySelector('.head-panel'); 
    this.selectors.updatePriceModal = document.querySelector('#modalUpdatePrice');
    this.selectors.editProductsModal = document.querySelector('#modalEditProducts');
    this.selectors.$editProductsModal = $('#modalEditProducts');
    this.selectors.createGroupModal = document.querySelector('#modalNewGroup');
    this.selectors.addProductsToCategoryModal = document.querySelector('#modalAddProductsToCategory');
    this.selectors.GroupList = document.querySelector('#tiu_products-groups');

    this.selectors.$condition = this.selectors.$editProductsModal.find('.ep_characteristic-item__default__condition');
    this.selectors.$warehouse = this.selectors.$editProductsModal.find('.ep_characteristic-item__default__warehouse');
    this.selectors.$storage = this.selectors.$editProductsModal.find('.ep_characteristic-item__default__section');
    this.selectors.$presence = this.selectors.$editProductsModal.find('.ep_characteristic-item__default__presence');
    this.selectors.$autodonor = this.selectors.$editProductsModal.find('.ep_characteristic-item__default__autodonor');

    this.selectors.$selectMark = this.selectors.$editProductsModal.find('.ep_characteristic-item__default__mark');
    this.selectors.$selectModel = this.selectors.$editProductsModal.find('.ep_characteristic-item__default__model');
    this.selectors.$selectGeneration = this.selectors.$editProductsModal.find('.ep_characteristic-item__default__generation');
    this.selectors.$selectSerie = this.selectors.$editProductsModal.find('.ep_characteristic-item__default__serie');
    
      this.selectors.ModalGroupEdit = null;
    this.data.dateToInstance = null;

      this.data = {
          discount_date_to: {
              minDate: new Date(),
              format: 'yyyy.mm.dd',
              autoClose: true,
              showClearBtn: true,
              firstDay: 1,
              i18n: {
                  cancel: 'Отмена',
                  clear: 'Очистить',
                  ok: 'Ок',
                  months: [
                      'Январь',
                      'Февраль',
                      'Март',
                      'Апрель',
                      'Май',
                      'Июнь',
                      'Июль',
                      'Август',
                      'Сентябрь',
                      'Октябрь',
                      'Ноябрь',
                      'Декабрь'],
                  monthsShort: ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн', 'Июл', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек'],
                  weekdays: ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'],
                  weekdaysShort: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
                  weekdaysAbbrev: ['В', 'П', 'В', 'С', 'Ч', 'П', 'С'],
              },
          },
      };
  } 

  run() {
    this.#initUpdatePrice();
    this.#initCreateGroup();
    this.#initEditProducts();
    this.#initAddProductsToCategoryModal();
    this.initDatetime();
  }
  
  initDatetime() {
        const dateTo = this.selectors.updatePriceModal.querySelector('input[name="discount_date_to"]');
        const dateToInstance = M.Datepicker.init(dateTo, this.data.discount_date_to);
        this.data.dateToInstance = dateToInstance;
        document.querySelector('body').appendChild(this.data.dateToInstance.modalEl);
        dateTo.addEventListener('change', e => {
            dateToInstance.options.maxDate = e.target.value ? new Date(dateToInstance.date) : null;
        });
    }

   #initUpdatePrice() {
    const isValidate = (v) => /^-?\d+(\.\d+)?%?$/.test(v);

    const openBtn = this.selectors.toolbar.querySelector('.update-price');
    const updateBtn = this.selectors.updatePriceModal.querySelector('.update');
    const form = this.selectors.updatePriceModal.querySelector('form');
    const input = form.querySelector('input[name="value"]');

    const Modal = M.Modal.init(this.selectors.updatePriceModal, {
      onOpenEnd: () => {
          input.focus();
          let checkbox = this.selectors.updatePriceModal.querySelector('.switch input[name="discount_on"]');
          checkbox.checked ? discount_off.setAttribute("disabled", "") : discount_off.removeAttribute("disabled");
          checkbox.checked ? discount_off.checked = false : discount_off.checked;
      }, 
      onCloseEnd: () => form.reset(),
    });

    openBtn.addEventListener('click', (e) => {
      const Count = this.selectors.updatePriceModal.querySelector('.product_count');
      const checkedAll = sessionStorage.getItem('checkedAll') === 'true';

      const productCount = checkedAll ?
        this.app.modules.TiuProduct.data.total :
        this.app.modules.TiuProduct.getCheckedIds().length;

      if (!checkedAll && productCount <= 0) {
        e.preventDefault();
        M.toast({html: 'Выберите товары для редактирования цены/скидки'});
        return;
      }
      
      Count.textContent = `${productCount} ${this.app.decl(productCount, ['товар', 'товара', 'товаров'])}`;

      Modal.open();
    });
     
    let checkbox = this.selectors.updatePriceModal.querySelector('.switch input[name="discount_on"]');
    let discount_date_to = this.selectors.updatePriceModal.querySelector('input[name="discount_date_to"]');
    let discount_off = this.selectors.updatePriceModal.querySelector('input[name="discount_off"]');
    checkbox.addEventListener('change', e => {
        e.target.checked ? discount_date_to.removeAttribute("disabled") : discount_date_to.setAttribute("disabled", "");
        e.target.checked ? discount_off.setAttribute("disabled", "") : discount_off.removeAttribute("disabled");
        e.target.checked ? discount_off.checked = false : discount_off.checked;
    }); 
     
    updateBtn.addEventListener('click', async () => {
      if(checkbox.checked === false && !discount_date_to.value && input.value) if (!confirm('Вы уверены, что хотите обновить цены?')) return;
      if (!confirm('Убедитесь чтобы выбранные товары были в наличии, иначе товары, которые не в наличии не обновятся')) return;
      if(discount_off.checked) if (!confirm('Вы уверены, что хотите отключить скидку?')) return;
      
      if (!isValidate(input.value) && !discount_off.checked) {
        return M.toast({html: 'Не удалось обновить цены, проверьте правильность заполненных полей'});
      }
      
      const inputDate = checkbox.checked && discount_date_to.value ? discount_date_to.value : null;
      const checkedAll = sessionStorage.getItem('checkedAll') === 'true';
      const checkedIds = this.app.modules.TiuProduct.getCheckedIds();
      const filters = checkedAll ? this.app.modules.TiuProductFilter.getValues() : null;
      
      const res = await this.app.fetch( 
        'TiuProduct',
        'batchUpdatePrice',
        {ids: checkedAll ? 'all' : checkedIds, value: input.value, discount_status: discount_off.checked, inputDate, filters}
      ); 

      if (res.status) {
        M.toast({html: 'Цены/скидки успешно обновлены'});
        Modal.close();
        this.app.modules.TiuProduct.fetchProducts();
      } else { 
        M.toast({html: res.message || 'Произошла ошибка'});
      }
    });

    input.addEventListener('input', (e) => {
      const el = e.target;
      const value = e.target.value.trim().replace(',', '.');
      if (!isValidate(value)) return el.classList.add('invalid');
      el.classList.remove('invalid');
    });
  }
  
   #initEditProducts(){
         const openBtn = this.selectors.toolbar.querySelector('.edit-products');
         const updateBtn = this.selectors.editProductsModal.querySelector('.update');
         const Count = this.selectors.editProductsModal.querySelector('.product_count');
         
         this.reset();
         this.getMark();
         this.eventsHundlerOn();
       this.selectors.ModalEditProducts = M.Modal.init(this.selectors.editProductsModal, {
             onOpenEnd: () => {  
                   Modal.calculateContainerHeight(this.selectors.$editProductsModal);
                   this.select2FieldsConfig();
                   this.selectors.$storage.mask('00-00-00-00-00');
             },
             onCloseEnd: () => {
                   this.reset();
             }
         }); 
      
         openBtn.addEventListener('click', (e) => {
                   const checkedAll = sessionStorage.getItem('checkedAll') === 'true';
                   const productCount = checkedAll ?
                       this.app.modules.TiuProduct.data.total :
                       this.app.modules.TiuProduct.getCheckedIds().length;
             
                   if (!checkedAll && productCount <= 0) {
                     e.preventDefault();
                     M.toast({html: 'Выберите товары для редактирования'});
                     return;
                   }
                   Count.textContent = `${productCount} ${this.app.decl(productCount, ['товар', 'товара', 'товаров'])}`;
                   this.selectors.ModalEditProducts.open();
         });   

         updateBtn.addEventListener('click', async () => {
                   const checkedAll = sessionStorage.getItem('checkedAll') === 'true';
                   const checkedIds = this.app.modules.TiuProduct.getCheckedIds();
                   const res = await this.app.fetch('TiuProduct', 'batchUpdate', {
                       batchUpdateProductsIds: checkedAll ? 'all' : checkedIds,
                       batchUpdateFields: this.getAll(),
                       batchUpdateFilters: this.app.modules.TiuProductFilter.getValues(),
                   });
                   
                   if (res.status) {
                       M.toast({html: res.message});
                       this.selectors.ModalEditProducts.close();
                       this.app.modules.TiuProduct.fetchProducts();
                   } else {
                       M.toast({html: res.message || 'Произошла ошибка'});
                   }
         }); 
   }
   
   #initCreateGroup() {
    const form = this.selectors.createGroupModal.querySelector('form');
    const nameInput = this.selectors.createGroupModal.querySelector('input[name="name"]');
    const createBtn = this.selectors.createGroupModal.querySelector('.create');
    
    const Modal = M.Modal.init(this.selectors.createGroupModal, {
      onOpenEnd: () => nameInput.focus(),
      onCloseEnd: () => form.reset(),
    });

    const handler = async (e) => {
      e.preventDefault();
      if (!form.checkValidity()) return;
      const parentId = this.selectors.GroupList.querySelector('.selected[data-id]')?.dataset?.id ?? null;
      const res = await this.app.fetch('Group', 'create', {name: nameInput.value, parent: parentId});
      if (res.status) {
        M.toast({html: res.message});
        Modal.close();
        this.app.modules.TiuProductGroups.fetchList(false);
      } else {
        M.toast({html: res.message || 'Произошла ошибка'});
      }
    };

    form.addEventListener('submit', handler);
    createBtn.addEventListener('click', handler);
  }

   #initAddProductsToCategoryModal() { 
    const openBtn = this.selectors.toolbar.querySelector('.add-to-group');
    const form = this.selectors.addProductsToCategoryModal.querySelector('form');
    this.selectors.ModalGroupEdit = M.Modal.init(this.selectors.addProductsToCategoryModal, {
      onOpenEnd: () => {
        if (this.app.modules.TiuProductGroupsEdit.selectors.List.innerHTML.trim() === '') this.app.modules.TiuProductGroupsEdit.initGroups()
      }
    });  
  
    openBtn.addEventListener('click', (e) => {
      const checkedAll = sessionStorage.getItem('checkedAll') === 'true';
 
      const productCount = checkedAll ?
          this.app.modules.TiuProduct.data.total :
          this.app.modules.TiuProduct.getCheckedIds().length;
 
      if (!checkedAll && productCount <= 0) {
        e.preventDefault();
        M.toast({html: 'Выберите товары для перемещения в категорию'});
        return;
      }
      this.selectors.ModalGroupEdit.open();
    });
  }

    async getMark(){
        const response = await this.app.fetch('characteristic', 'getMark');
        this.selectors.$selectMark.find('option').remove();
        this.selectors.$selectMark.append(`<option value="null">Выберите марку</option>`);
        response.data.marks.forEach((obj) => {
            this.selectors.$selectMark.append(`<option value="${obj.name}" data-id="${obj.id}">${obj.name}</option>`);
        });
        this.selectors.$selectMark.val('').trigger('change');
    }

    async getModel(){
        const element = this.selectors.editProductsModal.querySelector('.ep_characteristic-item__default__mark');
        let id = null;
        if(element.selectedOptions.length){
            if(element.selectedOptions[0].dataset.hasOwnProperty('id')) id = element.selectedOptions[0].dataset.id;
        }
        try {
            if (id !== null){
                const response = await this.app.fetch('characteristic', 'getModel', {id_car_mark: id});
                this.selectors.$selectModel.find('option').remove();
                this.selectors.$selectModel.append(`<option value="null">Выберите модель</option>`);
                response.data.models.forEach((obj) => {
                    this.selectors.$selectModel.append(`<option value="${obj.name}" data-id="${obj.id}">${obj.name}</option>`);
                });
                this.selectors.$selectModel.val('').trigger('change');
            } else {
                this.selectors.$selectModel.find('option').remove();
            }
        } catch (err) {
            console.log(err);
        }
    }

    async getGeneration(){
        const element = this.selectors.editProductsModal.querySelector('.ep_characteristic-item__default__model');
        let id = null;
        if(element.selectedOptions.length){
            if(element.selectedOptions[0].dataset.hasOwnProperty('id')) id = element.selectedOptions[0].dataset.id;
        }
        try {
            if (id !== null){
                const response = await this.app.fetch('characteristic', 'getGeneration', {id_car_model: id});
                this.selectors.$selectGeneration.find('option').remove();
                this.selectors.$selectGeneration.append(`<option value="null">Выберите поколение</option>`);
                response.data.generation.forEach((obj) => {
                    if(obj.year_start == null) obj.year_start = 'н.в';
                    if(obj.year_stop == null) obj.year_stop = 'н.в';
                    const year = obj.year_start || obj.year_stop ? ` (${obj.year_start} - ${obj.year_stop})` : ``;
                    this.selectors.$selectGeneration.append(`<option value="${obj.name}${year}" data-id="${obj.id}">${obj.name}${year}</option>`);
                });
                this.selectors.$selectGeneration.val('').trigger('change');
            } else {
                this.selectors.$selectGeneration.find('option').remove();
            }
        } catch (err) {
            console.log(err);
        }
    }

    async getSerie(){
        const element = this.selectors.editProductsModal.querySelector('.ep_characteristic-item__default__generation');
        let id = null;
        if(element.selectedOptions.length){
            if(element.selectedOptions[0].dataset.hasOwnProperty('id'))  id = element.selectedOptions[0].dataset.id;
        }
        try {
            if(id !== null){
                const response = await this.app.fetch('characteristic', 'getSerie', {id_car_generation: id});
                this.selectors.$selectSerie.find('option').remove();
                this.selectors.$selectSerie.append(`<option value="null">Выберите кузов</option>`);
                response.data.serie.forEach((obj) => {
                    let name = obj.name[0].toUpperCase() + obj.name.slice(1);
                    if(obj.notice) name = name + ' ' + '(' + obj.notice + ')';
                    this.selectors.$selectSerie.append(`<option value="${name}" data-id="${obj.id}">${name}</option>`);
                });
                this.selectors.$selectSerie.val('').trigger('change');
            } else {
                this.selectors.$selectSerie.find('option').remove();
            }
        } catch (err) {
            console.log(err);
        }
    }
    
    eventsHundlerOn(){
        this.selectors.$selectMark.on('change', () => {
            this.getModel();
            this.getGeneration();
            this.getSerie();
        });
        this.selectors.$selectModel.on('change', () => {
            this.getGeneration();
            this.getSerie();
        });
        this.selectors.$selectGeneration.on('change', () => {
            this.getSerie();
        });
    }

    eventsHundlerOff(){
        this.selectors.$selectMark.off();
        this.selectors.$selectModel.off();
        this.selectors.$selectGeneration.off();
        this.selectors.$selectSerie.off();
    }

    select2FieldsConfig(){
        this.selectors.$selectMark.select2({
            width: '100%',
            dropdownParent: '#modalEditProducts',
            theme: 'material',
            templateResult: (data) => {
                if (!data.id || data.id === 'all' || data.id === 'empty') return data.text;
                const $html = `<div style="display: flex;align-items: center;"><span style="background-image: url('/data/logos/` + data.element.dataset.id + `.png');width: 30px;height: 30px;margin-right: 5px"></span><span>` + data.text + `</span></div>`;
                if (data.element) return $($html);
            },
            placeholder: 'Выберите марку',
        });
        this.selectors.$selectModel.select2({
            width: '100%',
            dropdownParent: '#modalEditProducts',
            theme: 'material',
            placeholder: 'Выберите модель',
        });
        this.selectors.$selectGeneration.select2({
            width: '100%',
            dropdownParent: '#modalEditProducts',
            theme: 'material',
            placeholder: 'Выберите поколение',
        });
        this.selectors.$selectSerie.select2({
            width: '100%',
            dropdownParent: '#modalEditProducts',
            theme: 'material',
            placeholder: 'Выберите кузов',
        });

        this.selectors.$selectModel.next().find('.select2-selection__arrow').addClass("material-icons").html("arrow_drop_down");
        this.selectors.$selectMark.next().find('.select2-selection__arrow').addClass("material-icons").html("arrow_drop_down");
        this.selectors.$selectGeneration.next().find('.select2-selection__arrow').addClass("material-icons").html("arrow_drop_down");
        this.selectors.$selectSerie.next().find('.select2-selection__arrow').addClass("material-icons").html("arrow_drop_down");
    }
  
  
    reset() {
        this.selectors.$condition.formSelect('destroy');
        this.selectors.$condition.find(`option:selected`).prop('selected', false);
        this.selectors.$condition.find(`option`).eq(0).prop('selected', true);
        this.selectors.$condition.formSelect();

        this.selectors.$presence.formSelect('destroy');
        this.selectors.$presence.find(`option:selected`).prop('selected', false);
        this.selectors.$presence.find(`option`).eq(0).prop('selected', true);
        this.selectors.$presence.formSelect();
 
        this.selectors.$storage.val('');   
         
        this.selectors.$warehouse.formSelect('destroy');
        this.selectors.$warehouse.find(`option:selected`).prop('selected', false);
        this.selectors.$warehouse.find(`option`).eq(0).prop('selected', true);
        this.selectors.$warehouse.formSelect();
 
        this.app.modules.Autodonors.reset();

        this.eventsHundlerOff();
        this.selectors.$selectMark.val('').trigger('change');
        this.selectors.$selectModel.children('option').remove();
        this.selectors.$selectGeneration.children('option').remove();
        this.selectors.$selectSerie.children('option').remove();
        this.eventsHundlerOn();
    }

    getAll() {
        let condition_val = null;
        let warehouse_val = null;
        let storage_val = null;
        let presence_val = null;
        let autodonor_val = null;

        let mark_val = null;
        let model_val = null;
        let generation_val = null;
        let serie_val = null;
        
        if(this.selectors.$condition.length) condition_val = this.selectors.$condition.val() !== "null" ? this.selectors.$condition.val() : null;
        if(this.selectors.$warehouse.length) warehouse_val = this.selectors.$warehouse.val() !== "null" ? this.selectors.$warehouse.val() : null;
        if(this.selectors.$storage.length) storage_val = this.selectors.$storage.val() !== "null" ? this.selectors.$storage.val() : null;
        if(this.selectors.$presence.length) presence_val = this.selectors.$presence.val() !== "null" ? this.selectors.$presence.val() : null;
        if(this.selectors.$autodonor.length) autodonor_val = this.selectors.$autodonor.val() !== "null" ? this.selectors.$autodonor.val() : null;

        if(this.selectors.$selectMark.select2('data').length) mark_val = this.selectors.$selectMark.select2('data')[0].id !=="null" ? this.selectors.$selectMark.select2('data')[0].id : null;
        if(this.selectors.$selectModel.select2('data').length) model_val = this.selectors.$selectModel.select2('data')[0].id !=="null" ? this.selectors.$selectModel.select2('data')[0].id : null;
        if(this.selectors.$selectGeneration.select2('data').length) generation_val = this.selectors.$selectGeneration.select2('data')[0].id !=="null" ? this.selectors.$selectGeneration.select2('data')[0].text : null;
        if(this.selectors.$selectSerie.select2('data').length) serie_val = this.selectors.$selectSerie.select2('data')[0].id !=="null" ? this.selectors.$selectSerie.select2('data')[0].id : null;

        return [
            {
                name: 'Состояние',
                dimension: '',
                value: condition_val,
            },
            {
                name: 'Склад',
                dimension: '', 
                value: warehouse_val,
            }, 
            {
                name: 'Секция хранения',
                dimension: '',
                value: storage_val, 
            },
            {
                name: 'Наличие',
                dimension: '',
                value: presence_val,
            },
            {
                name: 'Автодонор',
                dimension: '',
                value: autodonor_val,
            },
            {
                name: 'Марка',
                dimension: '',
                value: mark_val,
            },
            {
                name: 'Модель',
                dimension: '',
                value: model_val,
                parent_id: this.selectors.$selectMark.select2('data').length && model_val ? this.selectors.$selectMark.select2('data')[0].element['dataset']['id'] : null,
            },
            {
                name: 'Поколение',
                dimension: '',
                value: generation_val,
                parent_id: this.selectors.$selectModel.select2('data').length && generation_val ? this.selectors.$selectModel.select2('data')[0].element['dataset']['id'] : null,
            },
            {
                name: 'Кузов',
                dimension: '',
                value: serie_val,
                parent_id: this.selectors.$selectGeneration.select2('data').length && serie_val ? this.selectors.$selectGeneration.select2('data')[0].element['dataset']['id'] : null,
            }
        ]; 
    }
}  
