import Module from './../base/module';
import {debounce} from 'throttle-debounce';
import Modal from "../base/modal";


export default class AutodonorEdit extends Module{
    run() {
        this.setup();
        this.listeners();
        // this.initCheckAll();
    }
 
    setup() {
        this.app.modules.AutodonorCharacteristics.getMark(); 
        this.selectors.$modal.modal({
            dismissible: false,
            onOpenEnd: () => {
                this.selectors.$modal.find('#autodonor-form__name').focus();
                this.app.modules.AutodonorCharacteristics.select2FieldsConfig();
                this.app.modules.AutodonorCharacteristics.eventsHundlerOn();
            },  
        })   
    }
 

    init() {
            this.initSelectors();
            this.initLabels();
    
            this.data = {
                autodonors: [],
                total: null,
                isLoad: false,
                paginationOffset: 0,
                doneTypingInterval: 500,
            };
        }

    initSelectors() {
        let modal = '#modalAutodonor';
        let $modal = $(modal);

        this.selectors.Screen = document.querySelector('#autodonors-screen');
        
        // this.selectors.uploadImport = $('.product__upload-import');
         this.selectors.$list = $('#autodonors-list');
        this.selectors.list = this.selectors.Screen.querySelector('#autodonors-list');
        this.selectors.tplListItem = 'autodonor-list__item';
        this.selectors.listItem = '.autodonors-list__item';

        this.selectors.listItemEditBtn = '.autodonor-list__item__edit-btn';
        this.selectors.listItemDeleteBtn = '.autodonor-list__item__delete-btn';

        this.selectors.modal = modal;
        this.selectors.$modal = $modal;
        this.selectors.Modal = document.querySelector(this.selectors.modal);
        this.selectors.$btnSave = $modal.find('#save_autodonor');
        this.selectors.$btnCloseModal = $modal.find('.modal-close-confirm');
          
        this.selectors.autodonors_list_total = this.selectors.Screen.querySelector('#autodonors-list_total');
        
        // this.selectors.CheckAll = this.selectors.Screen.querySelector('.check-all');
        // this.selectors.$headPanel = $('.head-panel-autodonors_screen');
        // this.selectors.clearSelected = this.selectors.Screen.querySelector('.clearSelected');
        // this.selectors.productDelete = this.selectors.Screen.querySelector('.autodonor-delete');
        // this.selectors.countSelected = this.selectors.Screen.querySelector('.countSelected');
        // this.selectors.productsListTotalWrap = this.selectors.Screen.querySelector('.products-list_total-wrap');
    }

    initLabels() {
        this.labels = {
            closeConfirm: 'Вы уверены, что хотите закрыть это окно?',
            saveConfirm: 'Вы уверены, что хотите сохранить автодонор?',
            deleteConfirm: 'Вы уверены, что хотите удалить выбранные автодоноры?',

            defaultLabelGroup: 'Выбрать группу',
            defaultLabelCategory: 'Выбрать категорию',
            defaultLabelAvitoCategory: 'Выбрать категорию Авито',

            errors: {
                invalidName: 'Введите название товара, минимум 5 символов.',
                addError: 'При сохранении товара произошла ошибка.',
                invalidConditionChar: 'Вы должны указать состояние детали',
                invalidModelChar: 'Вы должны указать модель детали',
            },
        };
    }

    listeners() 
    {
        //редактирование автодонора
        $(this.selectors.$list).on('click', (e) => {
            if (!e.target.closest('#autodonors-list')) return;
            let id = $(e.target).closest(this.selectors.listItem).data('id');
            if(id && e.target.closest(this.selectors.listItemEditBtn)){
                this.fillForm(id);
            }
        }); 
        
        //закрытие модалки
        this.selectors.$btnCloseModal.on('click', (e) => {
            e.preventDefault();
            if (confirm(this.labels.closeConfirm)) {
                this.resetForm();
                this.close();
            }
        });

        //сохранение модалки
        this.selectors.$btnSave.on('click', async (e) => {
            e.preventDefault();
            if (!this.validateForm()) return;
            if (confirm(this.labels.saveConfirm)) {
                let autodonorId = await this.save();
                this.app.modules.Gallery.saveAutodonorImages(autodonorId);
                this.resetForm();
                this.resetList();
                this.close();
            }
        });

        this.getFormField('name').on('keyup', (e) => {
            let $e = $(e.target);

            if ($e.val().length >= 5) {
                $e.removeClass('invalid');
            }
        });
        
        $(document.body).on('validation-success', (e) => {
            $('.materialboxed').materialbox();
        });
    }
    
    #getAllItems = () => Array.from(this.selectors.Screen.querySelectorAll(this.selectors.listItem));

    #getEventId(e) {
        const el = e?.target?.closest('tr');
        if (!el) return null;
        const id = el.dataset['id'];
        return id ?
            parseInt(id) :
            null;
    }
    
    async save() {
        let data = JSON.stringify(Object.assign({}, this.getAutodonorData()));
        let response = await this.app.fetch('Autodonor', 'update', {autodonor: data});
        if (response?.status) {
            M.toast({html: response.message});
            await this.app.modules.AutodonorsList.fetchAutodonors();
            return response.data.autodonorId;
        } else {
            M.toast({html: response.message});
            return false;
        }
    }
    
    async fetchOne(id, fields = null, models = null) {
        let response = await this.app.fetch('autodonor', 'get', {autodonorId: id, fields: fields, models: models});

        if (this.app.responseValidate(response, ['autodonor'], 'Произошла ошибка при запросе товара')) {
            return response.data.autodonor;
        }

        return false;
    }

    getAutodonorData() {
        let Data = [];

        Array.from(this.selectors.Modal.querySelectorAll('input')).map((El) => {
            if( El.name == '' || El.value == '' ) return;
            let autodonor_input_names = El.name.match(/(?<=\[).+?(?=\])/g);
            Data[autodonor_input_names] = El.value;
        });
        Array.from(this.selectors.Modal.querySelectorAll('textarea')).map((El) => {
            if( El.name == '' || El.value == '' ) return;
            let autodonor_textarea_names = El.name.match(/(?<=\[).+?(?=\])/g);
            Data[autodonor_textarea_names] = El.value;
        });
        
        Data["status"] = this.selectors.$modal.find('#autodonor-form__status').val();
        Data["steerwheel"] = this.selectors.$modal.find('#autodonor-form__steerwheel').val();
        Data["drivetype"] = this.selectors.$modal.find('#autodonor-form__drivetype').val();
        
        Data["chars"] = this.app.modules.AutodonorCharacteristics.getAll();
        
        return Data; 
    }
    
    async fillForm(id) {
        let autodonor = await this.fetchOne(id, null, ['photos', 'smaller']);

        $.each(autodonor, (i, v) => {
            if (['photos'].indexOf(i) >= 0) {
                return;
            }
            this.selectors.$modal.find(`form [name="data[${i}]"]`).eq(0).val(v);
        });
        
        if (autodonor.photos !== null && autodonor.photos.length) {
            autodonor.photos.forEach((images, index) => {
                this.app.modules.Gallery.showAutodonorImage(index, images);
            });
        }
        this.app.modules.AutodonorCharacteristics.fillChars(autodonor);
        this.open();
        this.reinitMaterialize();
    }
    
    #lockScreen = () => this.selectors.Screen.classList.add('disabled');

    #unlockScreen = () => this.selectors.Screen.classList.remove('disabled');

    async cancelableCallback(callback) {
        let canceled = false;

        const proceed = async () => {
            !canceled && await callback();
            this.#unlockScreen();
        };

        this.#lockScreen();

        const toast = M.toast({
            html: '<span>Отменить изменение</span><button class="btn-flat toast-action">Отменить</button>',
            displayLength: 10000,
            completeCallback: proceed,
        });

        toast.el.querySelector('button').addEventListener('click', () => {
            canceled = true;
            proceed();
            toast.dismiss();
        });
    }
    
    getFormField(name) {
        return this.selectors.$modal.find(`[name='data[${name}]']`);
    }

    resetForm() {
        this.selectors.$modal.find('form')[0].reset();
        this.selectors.$modal.find('input:hidden').val('');
        this.app.modules.AutodonorCharacteristics.reset();
        this.selectors.$modal.find('.autodonor__gallery__items').html("");
    }    

    validateForm() {
        if (!(this.getFormField('number').val().length >= 5)) {
            this.getFormField('number').addClass('invalid');
            this.getFormField('number').focus();
            M.toast({html: this.labels.errors.invalidName});
            return false;
        }
        return true;
    }

    open() {
        this.selectors.$modal.modal('open');
    }

    close() {
        this.selectors.$modal.modal('close');
    }
    
    async resetList() {
        // this.selectors.$headPanel.fadeOut('fast');
        // this.uncheckAll();

        await this.app.modules.AutodonorsList.fetchAutodonors();
    }
    
    reinitMaterialize() {

        M.AutoInit();

        M.updateTextFields();

        $('select').each(function() {
            let instance = M.FormSelect.getInstance(this);

            if (undefined !== instance) {
                instance.destroy();
            }

            M.FormSelect.init(this);
        });

        $(this.selectors.listItem + ' .materialboxed').materialbox();
    }
}
