import Module from "./../base/module";
import Modal from "./../base/modal";

export default class TiuCharacteristics extends Module {
    init() {
        let $modal = $('#modaltiuProductChars');

        this.selectors.$modal = $modal;
        this.selectors.$modalContent = $modal.find('.modal-content');
        this.selectors.$charList = $modal.find('.characteristics-list');
        this.selectors.$addBtn = $modal.find('.add_characteristic');
        this.selectors.$resetBtn = $modal.find('.reset_characteristics');
 
        this.selectors.charItem = '.characteristic-item';

        this.selectors.inputName = '.char_name';
        this.selectors.inputDimention = '.char_dimension';
        this.selectors.inputValue = '.char_value';

        this.selectors.deleteBtn = '.delete'; 

        this.selectors.tplItemName = 'tiu_product-chars__item';
        
        // this.selectors.modal = document.querySelector('#modaltiuProductChars');
        // this.selectors.modalContent =  this.selectors.modal.querySelector('.modal-content');
        // this.selectors.charList = this.selectors.modal.querySelector('.characteristics-list');
           
        this.labels = {};

        this.labels.errors = {
            cantDeleteById: 'Не удалось удалить характеристику по id, id не найден'
        };

        this.selectors.$condition = $modal.find('.tiu_characteristic-item__default__condition');
        this.selectors.$warehouse = $modal.find('.tiu_characteristic-item__default__warehouse');
        this.selectors.$storage = $modal.find('.tiu_characteristic-item__default__section');
        
        // this.selectors.$selectMark = $('.tiu_characteristic-item__default__mark');
        // this.selectors.$selectModel = $('.tiu_characteristic-item__default__model');
        // this.selectors.$selectGeneration = $('.tiu_characteristic-item__default__generation');
        // this.selectors.$selectSerie = $('.tiu_characteristic-item__default__serie');
    }

    open() {
        this.selectors.$modal.modal('open');
    }

    run() {
        this.listeners();
        this.setup();
    }

    listeners() {
        //this.eventsHundlerOn();
        
        this.selectors.$charList.on('click', this.selectors.deleteBtn, (e) => {
            let id = $(e.target).closest(this.selectors.deleteBtn).data('id');

            this.deleteById(id);
        });

        this.selectors.$addBtn.on('click', (e) => {
            this.add();

            this.selectors.$modalContent.clearQueue();

            this.selectors.$modalContent.animate({
                scrollTop: this.selectors.$modalContent.prop("scrollHeight")
            }, 350);
        });

        this.selectors.$resetBtn.on('click', (e) => {
            this.reset();
        });

        this.selectors.$storage.mask('00-00-00-00-00'); 
    }
 
    setup() {
        //this.getMark();
        this.selectors.$modal.modal({
            onOpenEnd: () => {  
                Modal.calculateContainerHeight(this.selectors.$modal);
                //this.eventsHundlerOn();
                this.selectors.$storage.parent().find('label[for="tiu_characteristic-item__default__section"]').addClass('active');  
            }   
        });
    }

    // eventsHundlerOn(){
    //     this.selectors.$selectMark.on('change', () => {
    //         this.getModel();
    //         this.getGeneration();
    //         this.getSerie();
    //     });
    //     this.selectors.$selectModel.on('change', () => {
    //         this.getGeneration();
    //         this.getSerie();
    //     });
    //     this.selectors.$selectGeneration.on('change', () => {
    //         this.getSerie();
    //     });
    // }
    //
    // eventsHundlerOff(){
    //     this.selectors.$selectMark.off();
    //     this.selectors.$selectModel.off();
    //     this.selectors.$selectGeneration.off();
    //     this.selectors.$selectSerie.off();
    //     this.selectors.$selectModification.off();
    // }
    //
    // async getMark(markNameParam = null){
    //     const response = await this.app.fetch('characteristic', 'getMark');
    //     let markName = null;
    //     this.selectors.modal.querySelector('.tiu_characteristic-item__default__mark').innerHTML = '';
    //     response.data.marks.forEach((obj) => {
    //         if (markNameParam === obj.name) markName = markNameParam;
    //         this.selectors.$selectMark.append(`<option value="${obj.name}" data-id="${obj.id_car_mark}">${obj.name}</option>`);
    //     });
    //     if (markName !== null) this.selectors.$selectMark.val(markName).trigger('change');
    //     this.selectors.$selectMark.select2({
    //         tags: true,
    //         width: '100%',
    //         dropdownParent: '#modaltiuProductChars',
    //         theme: 'material',
    //         placeholder: 'Выберите марку'
    //     });
    //     this.selectors.$selectMark.next().find('.select2-selection__arrow').addClass("material-icons").html("arrow_drop_down");
    // }
    //
    // async getModel(modelNameParam = null, parentId = null){
    //     const element = this.selectors.modal.querySelector('.tiu_characteristic-item__default__mark');
    //     let id = parentId;
    //     if(parentId == null){
    //         if(element.selectedOptions.length){
    //             if(element.selectedOptions[0].dataset.hasOwnProperty('id'))  id = element.selectedOptions[0].dataset.id;
    //         }
    //     }
    //     let modelName = null;
    //     try {
    //         if (id !== null){
    //             const response = await this.app.fetch('characteristic', 'getModel', {id_car_mark: id});
    //             this.selectors.modal.querySelector('.tiu_characteristic-item__default__model').innerHTML = '';
    //             response.data.models.forEach((obj) =>
    //             {
    //                 if (modelNameParam === obj.name) modelName = modelNameParam;
    //                 this.selectors.$selectModel.append(`<option value="${obj.name}" data-id="${obj.id_car_model}">${obj.name}</option>`);
    //             });
    //             if (modelName !== null) this.selectors.$selectModel.val(modelName).trigger('change');
    //         }
    //     } catch (err) {
    //         console.log(err);
    //     }
    //     this.selectors.$selectModel.select2({
    //         tags: true,
    //         width: '100%',
    //         dropdownParent: '#modaltiuProductChars',
    //         theme: 'material',
    //         placeholder: 'Выберите модель'
    //     });
    //     this.selectors.$selectModel.next().find('.select2-selection__arrow').addClass("material-icons").html("arrow_drop_down");
    // }
    //
    // async getGeneration(GenerationNameParam = null, parentId = null){
    //     const element = this.selectors.modal.querySelector('.tiu_characteristic-item__default__model');
    //     let id = parentId;
    //     if(parentId == null){
    //         if(element.selectedOptions.length){
    //             if(element.selectedOptions[0].dataset.hasOwnProperty('id'))  id = element.selectedOptions[0].dataset.id;
    //         }
    //     }
    //     let GenerationName = null;
    //     try {
    //         if (id !== null){
    //             const response = await this.app.fetch('characteristic', 'getGeneration', {id_car_model: id});
    //             this.selectors.modal.querySelector('.tiu_characteristic-item__default__generation').innerHTML = '';
    //             response.data.generation.forEach((obj) =>
    //             {
    //                 if (GenerationNameParam === obj.name) GenerationName = GenerationNameParam;
    //                 this.selectors.$selectGeneration.append(`<option value="${obj.name}" data-id="${obj.id_car_generation}">${obj.name}</option>`);
    //             });
    //             if (GenerationName !== null) this.selectors.$selectGeneration.val(GenerationName).trigger('change');
    //         }
    //     } catch (err) {
    //         console.log(err);
    //     }
    //     this.selectors.$selectGeneration.select2({
    //         tags: true,
    //         width: '100%',
    //         dropdownParent: '#modaltiuProductChars',
    //         theme: 'material',
    //         placeholder: 'Выберите поколение'
    //     });
    //     this.selectors.$selectGeneration.next().find('.select2-selection__arrow').addClass("material-icons").html("arrow_drop_down");
    // }
    //
    // async getSerie(SerieNameParam = null, parentId = null){
    //     const element = this.selectors.modal.querySelector('.tiu_characteristic-item__default__generation');
    //     let id = parentId;
    //     if(parentId == null){
    //         if(element.selectedOptions.length){
    //             if(element.selectedOptions[0].dataset.hasOwnProperty('id'))  id = element.selectedOptions[0].dataset.id;
    //         }
    //     }
    //     let SerieName = null;
    //     try {
    //         if(id !== null){
    //             const response = await this.app.fetch('characteristic', 'getSerie', {id_car_generation: id});
    //             this.selectors.modal.querySelector('.tiu_characteristic-item__default__serie').innerHTML = '';
    //             response.data.serie.forEach((obj) => {
    //                 if(SerieNameParam === obj.name) SerieName = SerieNameParam;
    //                 this.selectors.$selectSerie.append(`<option value="${obj.name}" data-id="${obj.id_car_serie}">${obj.name}</option>`);
    //             });
    //             if(SerieName !== null) this.selectors.$selectSerie.val(SerieName).trigger('change');
    //         }
    //     } catch (err) {
    //         console.log(err);
    //     }
    //     this.selectors.$selectSerie.select2({
    //         tags: true,
    //         width: '100%',
    //         dropdownParent: '#modaltiuProductChars',
    //         theme: 'material',
    //         placeholder: 'Выберите серию'
    //     });
    //     this.selectors.$selectSerie.next().find('.select2-selection__arrow').addClass("material-icons").html("arrow_drop_down");
    // }

    async fetch(productId, fields) {
        let response = await this.app.fetch('TiuProduct', 'get', {productId: productId, fields: fields});

        if (this.app.responseValidate(response, ['characteristics'], 'Не удалось получить характеристики')) {
            return response.data.characteristics;
        }
    }

    async saveAll(productId) {
        let response = await this.app.fetch('TiuProduct', 'saveCharacteristics', {
            productId: productId,
            characteristics: this.getAll()
        });
        if (this.app.responseValidate(response), [], 'Не удалось сохранить характеристики') return true;
        return false;
    }

    add(data = {}) {
        this.cleanup();
        // this.eventsHundlerOff();

        if (data.name === 'Состояние') { 
            this.selectors.$condition.formSelect('destroy');
            this.selectors.$condition.find(`option:selected`).prop('selected', false);
            this.selectors.$condition.find(`option[value="${data.value}"]`).prop('selected', true);
            this.selectors.$condition.formSelect();
            return;
        }
        if (data.name === 'Склад хранения') {
            this.selectors.$warehouse.formSelect('destroy');
            this.selectors.$warehouse.find(`option:selected`).prop('selected', false);
            this.selectors.$warehouse.find(`option[value="${data.value}"]`).prop('selected', true);
            this.selectors.$warehouse.formSelect();
            return;
        } 
 
        if (data.name === 'Секция хранения') {
            this.selectors.$storage.val(data.value);
            return; 
        }
        
        let id = parseInt(this.selectors.$charList.children().eq(-1).data('id')) + 1 || 1;

        let defaults = {
            id: id,
            name: '',
            dimension: '',
            value: ''
        };

        let $item = $(this.app.getTemplate(this.selectors.tplItemName, {...defaults, ...data}));

        this.deleteByName(data.name);

        this.selectors.$charList.append($item);

        // if(!this.selectors.$charList.find('.characteristic-item').find('.cust_title').length) {
        //     this.selectors.$charList.find('.characteristic-item').prepend("<div class='col s12 cust_title'><h6>Кастомные характеристики</h6></div>");
        // }
 
        M.updateTextFields();
    }

    cleanup() {
        this.selectors.$charList.children(':not(.characteristic-item__default)').each((i, e) => {
            let id = $(e).data('id');
            let $name = $(e).find(this.selectors.inputName);
            let $value = $(e).find(this.selectors.inputValue);

            if (!$name.val().trim().length || !$value.val().trim().length) {
                this.deleteById(id);
            }
        });
    }

    deleteById(id) {
        let item = this.selectors.$charList.find(this.selectors.charItem + `[data-id="${id}"]`);

        if (item.length) {
            item.remove();
        } else {
            console.error(this.labels.errors.cantDeleteById);
        }
    }

    deleteByName(name) {
        if (!name) {
            return;
        }

        name = name.trim();
        let item = this.selectors.$charList
            .find(this.selectors.charItem + ` input.char_name`)
            .filter(function () {
                return this.value == name
            })
            .closest(this.selectors.charItem);

        if (item.length) {
            this.deleteById(item.data('id'));
        }
    }

    reset() {
        this.selectors.$charList.children(':not(.characteristic-item__default)').remove();
        
        this.selectors.$condition.formSelect('destroy');
        this.selectors.$condition.find(`option:selected`).prop('selected', false);
        this.selectors.$condition.find(`option`).eq(0).prop('selected', true);
        this.selectors.$condition.formSelect();
        
        this.selectors.$storage.val('');
        
        this.selectors.$warehouse.formSelect('destroy');
        this.selectors.$warehouse.find(`option:selected`).prop('selected', false);
        this.selectors.$warehouse.find(`option`).eq(0).prop('selected', true);
        this.selectors.$warehouse.formSelect(); 
        
        // this.eventsHundlerOff();
        // this.selectors.$selectMark.val('').trigger('change');
        // this.eventsHundlerOn();
        //
        // this.selectors.$selectModel.children('option').remove();
        // this.selectors.$selectGeneration.children('option').remove();
        // this.selectors.$selectSerie.children('option').remove();
    }

    getAll() {
        let data = [
            {
                name: 'Состояние',
                dimension: '',
                value: $('.tiu_characteristic-item__default__condition').closest('.select-wrapper').find('input').val(),
                // parent_id: null,
            }  
            // {
            //     name: 'Марка',
            //     dimension: '',
            //     value: $('.tiu_characteristic-item__default__mark').select2('data').length ? $('.tiu_characteristic-item__default__mark').select2('data')[0].text : null,
            //     parent_id: null,
            // },
            // {
            //     name: 'Модель',
            //     dimension: '',
            //     value: $('.tiu_characteristic-item__default__model').select2('data').length ? $('.tiu_characteristic-item__default__model').select2('data')[0].text : null,
            //     parent_id: $('.tiu_characteristic-item__default__mark').select2('data').length ? $('.tiu_characteristic-item__default__mark').select2('data')[0].element['dataset']['id'] : null,
            // },
            // {
            //     name: 'Поколение',
            //     dimension: '',
            //     value: $('.tiu_characteristic-item__default__generation').select2('data').length ? $('.tiu_characteristic-item__default__generation').select2('data')[0].text : null,
            //     parent_id: $('.tiu_characteristic-item__default__model').select2('data').length ? $('.tiu_characteristic-item__default__model').select2('data')[0].element['dataset']['id'] : null,
            // },
            // {
            //     name: 'Кузов',
            //     dimension: '',
            //     value: $('.tiu_characteristic-item__default__serie').select2('data').length ? $('.tiu_characteristic-item__default__serie').select2('data')[0].text : null,
            //     parent_id: $('.tiu_characteristic-item__default__generation').select2('data').length ? $('.tiu_characteristic-item__default__generation').select2('data')[0].element['dataset']['id'] : null,
            // },
        ];

        let $charList = this.selectors.$charList;

        $charList.children().each((i, e) => {

            let itemData = {
                name: $(e).find(this.selectors.inputName).val(),
                dimension: $(e).find(this.selectors.inputDimention).val(),
                value: $(e).find(this.selectors.inputValue).val(),
            };

            if (undefined === itemData.name || !itemData.name.length
                || undefined === itemData.value || !itemData.value.length) {
                return;
            }

            data.push(itemData);
        });

        return data;
    }
}
