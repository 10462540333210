import Module from './base/module';


export default class Report extends Module{
  init() {
    this.data = {};
    this.data.page = 1;
    this.exports = null;
    this.initSelectors();
  }

  initSelectors() {
    this.selectors.screen = '#reports-screen';
    this.selectors.list = `${this.selectors.screen} .report-list`;
    this.selectors.tplListItem = `report-list__item`;
    this.selectors.pagination = '#reports-pagination';
    this.selectors.prevPage = `${this.selectors.pagination} .prev`;
    this.selectors.nextPage = `${this.selectors.pagination} .next`;
  }

  async run() {
    this.listeners();
    await this.loadExports();
    this.loadReports();
  }

  listeners() {
    $(this.selectors.prevPage).on('click', e => {
      if ($(this.selectors.prevPage).hasClass('disabled')) return;
      this.loadReports(this.data.page - 1 <= 0 ? 1 : this.data.page - 1);
    });
    $(this.selectors.nextPage).on('click', e => {
      if ($(this.selectors.nextPage).hasClass('disabled')) return;
      this.loadReports(this.data.page + 1);
    });
  }

  async loadExports() {
    let response = await this.app.fetch('export', 'getAll');
    if (!response.status) return M.toast({html: 'Не удалось получить список выгрузок'});

    this.exports = response.data.items;
  }

  handlePagination(page, pages) {
    const $prev = $(this.selectors.prevPage);
    const $next = $(this.selectors.nextPage);

    $prev.add($next).removeClass('disabled');

    if (page <= 1) $prev.addClass('disabled');
    if (page >= pages) $next.addClass('disabled');
  }

  async loadReports(page = 1) {
    let response = await this.app.fetch('report', 'getAll', {page: page});
    if (!response.status) return M.toast({html: 'Не удалось получить список отчетов по выгрузкам'});

    this.handlePagination(response.data.page, response.data.pages);
    this.data.page = page;

    let $list = $(this.selectors.list).find('tbody');

    $list.html('');

    response.data.items.forEach(el => {
      el.exportName = this.exports.filter(exportEl => exportEl.id === el.export_id).shift().name;
      if ($(`${this.selectors.listItem}[data-id="${el.id}"]`).length) return;
      let item = this.app.getTemplate(this.selectors.tplListItem, el);
      $list.append(item);
    });
  }
}
