import CategoryHandler from "./../base/categoryHandler";

export default class TiuCategories extends CategoryHandler {
    init() {
        
        
        
        this.ajax = {
            action: 'tiuCategories'
        };

        this.labels = {
            errorSelectedItem: 'Выберите другую категорию',
            resetNotice: 'Вы уверены, что хотите сбросить категорию?'
        };

        this.selectors.$modal = $('#modalProductTiuCat');
        this.selectors.$input = $("[name='data[category]']");
        
    }
}
