import Module from "./base/module";
import {debounce} from "throttle-debounce";

export default class Search extends Module {
    init() {
        this.selectors.$modal = $('#modalProductsSearch');
        this.selectors.$form = $('#products-search__form');
        this.selectors.$query = $('#product-search__query');
        this.selectors.tpl = 'products-search__item';
        this.selectors.$result = $('#product-search__result tbody')
        this.selectors.$vendors = $('#product-search__vendors');
        this.selectors.$vendorItems = this.selectors.$vendors.find('.vendor');
        this.selectors.$model = $('#product-search__model');
        this.selectors.$pagi = $('#product-search__pagination');
        this.selectors.$total = document.querySelector('#search_page_products-list_total');

        this.labels = {};

        this.autoComplete = null;
        this.page = 1; 
    }

    run() {
        this.listeners();
        this.loadVendors();
    }

    listeners() {
        this.selectors.$form.on('input', debounce(350, this.load.bind(this, true)))
        this.selectors.$form.on('submit', evt => evt.preventDefault())
        this.selectors.$vendors.on('click', '.vendor', evt => {
            this.selectors.$query.val(evt.target.closest('.vendor').textContent.trim());
            M.updateTextFields();
            this.load(true);
        })
        this.selectors.$model.on('keypress', debounce(350, this.loadModelAutocomplete.bind(this)));
        this.selectors.$pagi.on('click', 'li', evt => {
            let nextPage = this.page;
            const btn = evt.target.closest('li');
            if (btn.classList.contains('active') || btn.classList.contains('disabled')) return;
            if (btn.classList.contains('prev')) nextPage--;
            else if (btn.classList.contains('next')) nextPage++;
            else nextPage = parseInt(btn.textContent)
            this.page = nextPage;
            this.load();
        })
    }
     
    #setTotal(num) { 
        this.selectors.$total.textContent = null === num || isNaN(num) ? '' : `${num}`;
    }  
    
    load(resetPages = false) {
        if (!!resetPages) this.page = 1;
        let query = this.selectors.$query.val();
        // let model = this.selectors.$model.val();
        let model = null;

        // if (query.length <= 1 && model.length <= 1) return;
        if (query.length <= 1) query = null;
        // if (model.length <= 1) model = null;
        
        this.app.doAjax('product', 'search', {'query': query, model: model, page: this.page}, data => {
            
            // console.log(data.data.items)
            
            this.selectors.$result.html('');
            if (data.data.items.length) {
                data.data.items.map(item => {
                    item.available = item.available ? 'В наличии' : 'Не в наличии';
                    // item.model = item.model || '&mdash;';
                    item.storage = item.storage || '&mdash;';
                    item.image = item.image || '/data/zaglushka_50_50.png';
                    // item.vendor = item.vendor || '&mdash;';
                    const $tpl = $(this.app.getTemplate(this.selectors.tpl, item));
                    if (!item.url) $tpl.find('.link').remove();
                    this.selectors.$result.append($tpl);
                })  
            }
            this.#setTotal(data.data.total);  
            this.renderPages(data.data.pages);
        });
 

    }

    renderPages(pages) {
        const edges = 3;
        const around = 5;
        const max = 15;

        if (pages <= 1) return this.selectors.$pagi.html('');

        const renderPage = (c, l = 'waves-effect', a = '', r = false) => {
            this.selectors.$pagi.append(`<li class="${l}"><a class="${a}" href="#!">${c}</a></li>`);
        }

        const renderNumberedPages = pages => {
            let prev = Math.min(...pages);
            pages.forEach(page => {
                if (page - prev > 1) renderPage('...', 'disabled')
                if (page === this.page) renderPage(page, 'active');
                else renderPage(page);
                prev = page;
            })
        }

        this.selectors.$pagi.html('')
        //Prev
        if (this.page > 1) renderPage('<i class="material-icons">chevron_left</i>', 'prev')

        //Numbered
        let numberedPages = [];

        if (pages <= max) for (let i = 1; i <= pages; i++) numberedPages.push(i);
        if (pages > max) {
            //Edges start
            for (let i = 1; i <= edges; i++) numberedPages.push(i);

            //around
            for (let i = this.page - around - 1; i < this.page; i++) numberedPages.push(i)
            numberedPages.push(this.page)
            for (let i = this.page + 1; i < this.page + around + 1; i++) numberedPages.push(i)

            //Edges end
            for (let i = pages - edges; i < pages; i++) numberedPages.push(i);
        }

        numberedPages = numberedPages.filter((v, i, s) => s.indexOf(v) === i && v >= 1 && v <= pages)
        renderNumberedPages(numberedPages)

        //Next
        if (this.page < pages) renderPage('<i class="material-icons">chevron_right</i>', 'next')
    }
 
    loadVendors() {
        this.app.doAjax('product', 'getAllVendors', {limit: 19}, data => {
            if (data?.data?.items) {  
                this.selectors.$vendors.html('');
                data.data.items.map(i => {
                    this.selectors.$vendors.append(`<span class="vendor">${i}</span>`);
                })
            }
        })
    }

    loadModelAutocomplete() {
        const q = this.selectors.$model.val().trim();
        this.app.doAjax('product', 'getAllModels', {q: q}, response => {
            this.selectors.$model.autocomplete('close');
            if (!response.status) return;
            let data = {};
            response.data.items.forEach(item => data[item] = null);
            this.selectors.$model.autocomplete({
                data          : data,
                limit         : 20,
                onAutocomplete: function (text) {}
            });
            this.selectors.$model.autocomplete('open');
        })
    }
}
