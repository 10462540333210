import Module from './module.js';


export default class App{
  constructor() {
    this.needDebug = true; 

    this.preloader = '<div class="preloader-bg"><div class="preloader-wrapper active"><div class="spinner-layer spinner-red-only"><div class="circle-clipper left"> <div class="circle"></div></div><div class="gap-patch"><div class="circle"></div></div><div class="circle-clipper right"><div class="circle"></div></div></div></div></div>';  
    
    this.data = { 
      config: {
        datetime: {
          maxDate: new Date(),
          format: 'dd.mm.yyyy',
          autoClose: true,
          showClearBtn: true,
          firstDay: 1,
          i18n: {
            cancel: 'Отмена',
            clear: 'Очистить',
            ok: 'Ок',
            months: [
              'Январь',
              'Февраль',
              'Март',
              'Апрель',
              'Май',
              'Июнь',
              'Июль',
              'Август',
              'Сентябрь',
              'Октябрь',
              'Ноябрь',
              'Декабрь'],
            monthsShort: ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн', 'Июл', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек'],
            weekdays: ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'],
            weekdaysShort: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
            weekdaysAbbrev: ['В', 'П', 'В', 'С', 'Ч', 'П', 'С'],
          },
        },
      },
    };

    this.preloaderFadeIn = (selector) => {
      selector.append(this.preloader);
    }
    this.preloaderFadeOut = () => {
      $('.preloader-bg').fadeOut('fast');
    }
    
    
    this.modules = {};

    this.labels = {
      errors: {
        errorEmptyModule: 'Ошибка при создании модуля, модуль пуст.',
      },
    };

    this.doAjax = async function(action, method, data, callable) {

      let params = [...arguments];

      params.splice(0, 4);

      let _callable = (d) => {

        if (undefined !== d.message && null !== d.message && d.message.length) {
          M.toast({html: d.message});
        }

        if (undefined !== d.data.debug) {
          console.warn(d.data.debug);
        }

        if (undefined !== d.data.error_code && 1 === d.data.error_code) {
          $(document.body).trigger('validation-failed');

          return;
        }

        if (typeof callable === 'function') {
          callable(d, ...params);
        }
      };

      let _data;

      let ajaxSettings = {
        url: '/',
        type: 'POST',
        success: _callable,
        dataType: 'json',
      };

      if (data instanceof FormData) {
        data.append('action', action);
        data.append('method', method);

        _data = data;

        ajaxSettings.processData = false;
        ajaxSettings.contentType = false;

      } else {
        _data = {
          action: action,
          method: method,
          data: data,
        };
      }

      ajaxSettings.data = _data;

      $.ajax(ajaxSettings);
    }; //TODO перейти на fetch
  }

  
  
  
  addModule(module) {
    if (undefined === module || null === module) {
      throw new Error(this.labels.errors.emptyModule);
    }
    if (!(module instanceof Module)) {
      throw new Error(`Метод ${module.constructor.name} должен реализовать класс Module`);
    }

    let moduleName = module.constructor.name;

    this.modules[moduleName] = module;
  };

  async fetch(action, method, data = {}) {
    let headers = { 
      'Accept': 'application/json',
    }; 

    let _data;
    
    if (data instanceof FormData) {
      _data = data;
    } else {
      _data = new FormData();

      _data.append('data', JSON.stringify(data));
    }

    _data.append('action', action);
    _data.append('method', method);

    let responsePromise = await fetch('/', {
      method: 'POST',
      headers: headers,
      body: _data,
    });

    let response = await responsePromise.json();

    if (!response.status) {
      let msg = response.message || 'Произошла ошибка.';

      M.toast({html: response.message});

      if (response.data.hasOwnProperty('error_code') && 1 === response.data.error_code) {
        $(document.body).trigger('validation-failed');
      }

      console.warn(response);
      throw new Error(msg);
    }

    return response;
  }

  responseValidate(response, fields, defaultMsg) {
    let msg = response.message || defaultMsg;

    if (!response.status) {
      M.toast({html: msg});
      throw new Error(msg);
    }

    for (let n in fields) {
      if (!fields.hasOwnProperty(n)) {
        continue;
      }
      if (!response.data.hasOwnProperty(fields[n])) {
        M.toast({html: msg});
        throw new Error(msg);
      }
    }

    return true;
  }

  init() {
    this.baseListeners();
    this.setup();
    this.runModules();
    this.pageReloadSaveTab(); 
  }   
   
  
  pageReloadSaveTab(){
      document.querySelectorAll('#main-tabs .tab a').forEach((el) =>{
          el.addEventListener("click", () => {
                sessionStorage.setItem('currentUrl', el.href);
                sessionStorage.setItem('currentTabPage', el.id);
          });  
      });
      let currentUrl = sessionStorage.getItem('currentUrl') != null ? sessionStorage.getItem('currentUrl') : null;
      if (currentUrl != null) window.location.href = currentUrl;
  } 
   
  
  baseListeners() {
    M.AutoInit();
    M.Dropdown.init(document.querySelector('#main-dropdown-trigger'), {
      constrainWidth: false,
    });
  }

  runModules() {
    for (let moduleName in this.modules) {

      if (!this.modules.hasOwnProperty(moduleName)) {
        return;
      }

      let module = this.modules[moduleName];

      module.init();

      module.run();
    }
  }

  async setup() {
    $.trumbowyg.svgPath = '/img/trumbowyg.svg';

    let result = await this.fetch('app', 'getConfig');

    this.data.config = result.data;
  }

  getConfig(key) {
    if (this.data.config.hasOwnProperty(key)) {
      return this.data.config[key];
    }

    throw new Error(`Не удалось найти запись конфига ${key}`);
  }

  getTemplate(name, data) {
    let template = document.getElementById('tpl.' + name);

    if (null === template) {
      new Error(`Не найден шаблон "#tpl.${name}"`);
    }

    template = template.innerHTML;

    for (let property in data) {
      if (data.hasOwnProperty(property)) {
        let search = new RegExp('{' + property + '}', 'g');
        template = template.replace(search, data[property]);
      }
    }

    return template;
  };

  async copyImagesFromProduction(clean = false) {
    let images = await this.fetch('app', 'copyImagesFromProduction', {'clean': clean});
  }
  
  decl = (n, t) => t[(n %= 100, 20 > n && n > 4) ? 2 :[2,0,1,1,1,2][ (n %= 10, n < 5) ? n : 5]]
}
