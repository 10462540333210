import Module from "./module";
import Modal from "./modal";

export default class CategoryHandler extends Module {
    run() {
        if (!this.ajax.action || !this.selectors.$modal) {
            throw new Error(`Неправильно составлен метод ${this.constructor.name}`);
        }

        this.data.inited = {
            items: false,
            favorite: false
        };

        this.selectors.itemsTree = `#${this.selectors.$modal.attr('id')} .tree`;
        this.selectors.$itemsTree = $(this.selectors.itemsTree);

        this.selectors.$button = $(`a[href="#${this.selectors.$modal.attr('id')}"]`);
        this.selectors.$resetButton = this.selectors.$modal.find('.reset');

        this.labels.buttonText = this.selectors.$button.html().trim();

        this.labels.resetNotice = this.labels.resetNotice || 'Вы уверены, что хотите сбросить?';
        this.labels.addFavoriteNotice = this.labels.resetNotice || 'Вы уверены, что хотите добавить в избранное?';
        this.labels.deleteFavoriteNotice = this.labels.resetNotice || 'Вы уверены, что хотите удалить из избранного?';

        this.listeners();
    }

    listeners() {
        $(document.body).on(`update${this.constructor.name}Items`, this.getItems.bind(this));

        this.selectors.$resetButton.on('click', (e) => {
            e.preventDefault();

            if (!confirm(this.labels.resetNotice)) {
                return;
            }

            this.reset();
        });

        if (this.needFavorites()) {
            this.selectors.$addToFavorite.on('click', (e) => {
                e.preventDefault();

                let selectedNode = this.selectors.$itemsTree.jstree('get_selected');

                if (undefined === selectedNode[0]) {
                    return;
                }

                let id = selectedNode[0];

                this.addFavorite(id);
            });

            $(document.body).on(`validation-success update${this.constructor.name}Favorites`, (e) => {
                this.app.doAjax(this.ajax.action, 'getFavorites', {}, this.parseFavorites.bind(this));
            });

            $(document.body).on('click', this.selectors.favoriteDelete, (e) => {
                e.preventDefault();
                let $e = $(e.target);
                let favoriteItem = $e.closest(this.selectors.favoriteItem);

                if (!favoriteItem) {
                    return;
                }

                let id = favoriteItem.data('id');

                this.deleteFavorite(id);
            })

            $(document.body).on('click', this.selectors.favoriteItem, (e) => {
                e.preventDefault();
                let $e = $(e.target).closest(this.selectors.favoriteItem);
                let id = $e.data('id');

                if (!id) {
                    return;
                }

                this.set(id);
            });
        }

        this.data.initedInterval = setInterval(() => {
            this.checkInitStatus();
        }, 1000, this);

        $(document.body).on(`${this.constructor.name}Inited`, (e) => {
            this.initModal();
        });
    }

    checkInitStatus() {
        if (!this.needFavorites() || (this.data.inited.items === true && this.data.inited.favorite === true)) {
            $(document.body).trigger(`${this.constructor.name}Inited`);
            clearTimeout(this.data.initedInterval);
        }
    }

    initModal() {
        this.selectors.$modal.modal({
            startingTop: `100%`,
            endingTop: `${Modal.getEndingTop()}%`,
            onOpenStart: () => {
                this.onOpen()
            },
            onOpenEnd: () => {
                Modal.calculateContainerHeight(this.selectors.$modal);
            },
            onCloseEnd: () => {
                this.onClose()
            }
        });
    }

    async getItems() {
        let rawItems = await this.app.fetch(this.ajax.action, 'getAll');

        let items = rawItems.data.items;

        this.data.items = [];

        for (let i in items) {

            let _item = {};

            if (!items.hasOwnProperty(i)) {
                return;
            }

            _item = items[i];

            _item.parent = _item.parent || '#';

            _item.text = _item.name;

            delete _item.name;

            this.data.items.push(_item);
        }

        this.data.inited.items = true;
    }

    validateSelectedNode(id) {
        if (id) {
            return true;
        } else {
            M.toast({html: this.labels.errorSelectedItem});
        }
    }

    onOpen() {
        this.selectors.$itemsTree.jstree({
            'core': {
                worker: false,
                themes: {'icons': false},
                animation: false,
                data: this.data.items
            }
        });

        this.renderFavorite();

        this.selectors.$itemsTree.on('select_node.jstree', (e, d) => {
            if (this.validateSelectedNode && this.validateSelectedNode(d.node.id)) {
                this.set(d.node.id);
            }
        });
    }

    open() {
        this.selectors.$modal.modal('open');
    }

    onClose() {
        this.selectors.$itemsTree.off();
        this.selectors.$itemsTree.jstree(true).destroy();
        this.destroyFavorite();
    }

    close() {
        this.selectors.$modal.modal('close');
    }

    getById(id) {
        if (!this.data.items || !this.data.items.length) {
            return null;
        }
        return this.data.items.find((i) => {
            if (i.hasOwnProperty('id') && i.id == id) {
                return true;
            }
        });
    }

    set(id) {
        let item = this.getById(id);

        if (undefined === item || !item.hasOwnProperty('id')) {
            return;
        }

        this.selectors.$button.html(`Выбрано: "${item.text}"`);

        this.selectors.$input.val(id);
    }

    reset() {
        this.selectors.$input.val('');
        this.selectors.$button.html(this.labels.buttonText);
    }

    renderFavorite(deffer = false) {
        if (!this.needFavorites()) {
            return;
        }

        if (true === deffer) {
            setTimeout(() => {
                this.renderFavorite();
            }, 500);
            return;
        }

        let items = this.data.favoriteItems;

        let html = '';

        for (let item in items) {
            if (!items.hasOwnProperty(item)) {
                continue;
            }

            html += this.app.getTemplate(this.selectors.favoriteItemTpl, items[item]);
        }

        this.selectors.$favoriteList.html(html);
    }

    destroyFavorite() {
        if (!this.needFavorites()) {
            return;
        }

        this.selectors.$favoriteList.html('');
    }

    needFavorites() {
        return this.data.hasOwnProperty('favorites') &&
            this.data.favorites === true &&
            this.selectors.hasOwnProperty('$addToFavorite') &&
            this.selectors.hasOwnProperty('$favoriteList');
    }

    parseFavorites(data) {
        let favorites = {};

        if (data.data || data.data.items) {
            favorites = data.data.items;
        }

        this.data.favoriteItems = favorites;

        this.data.inited.favorite = true;
    }

    addFavorite(id) {
        if (this.labels)

            this.app.doAjax(this.ajax.action, 'addFavorite', {id: id}, () => {
                $(document.body).trigger(`update${this.constructor.name}Favorites`);

                this.renderFavorite(true);
            });
    }

    deleteFavorite(id) {
        this.app.doAjax(this.ajax.action, 'deleteFavorite', {id: id}, () => {
            $(document.body).trigger(`update${this.constructor.name}Favorites`);

            this.renderFavorite(true);
        });
    }
}