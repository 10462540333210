import Module from '../base/module';
import {debounce} from "throttle-debounce";
import TiuProductGroups from './groups'


export default class TiuProductGroupsEdit extends Module{

    init() {
        this.data = {
            rootId: 2881645,
            selectedIds: [],
            checkedProducts: null,
        };

        this.selectors.Sidebar = document.querySelector('#tiu_products-sidebar');
        this.selectors.GroupList = this.selectors.Sidebar.querySelector('#tiu_products-groups');
        this.selectors.List = document.querySelector('#tiu_products-groups-edit');
        this.selectors.moveButton = document.querySelector('#modalAddProductsToCategory .modal-footer .move');
        this.selectors.deleteButton = this.selectors.Sidebar.querySelector('.delete-group');
        this.selectors.renameGroupName = document.querySelector('#renameGroupName');
        this.selectors.Filter = document.querySelector('#tiu_products-list__filter-edit');
        this.selectors.Name = this.selectors.Filter.querySelector('[name="name"]');
        
        this.getTpl = {
            item: (data) => this.app.getTemplate('tiuGroupsEditListItem', data),
        };

    }
    
    run() { 
         
    }

    initGroups(){
        // this.initRenameGroup();
        this.fetchList();
        this.#initListeners();
    }
    

    #handleClick(e) {
        e.preventDefault();
        const El = e.target.closest('li');
        if (!El || !e.target.closest('.exp')) return;
        El.classList.toggle('opened');
    }

    #toggleClick(e) { 
        e.preventDefault();
        const Checkbox = e.target.offsetParent.firstElementChild.querySelector('input');
        if (!Checkbox) return;
        let cb = this.selectors.List.querySelectorAll('.check'); 
        Array.from(cb).map(el => { 
            if(el.querySelector('input').checked && !Checkbox.checked){
                el.querySelector('input').checked = false;
                el.closest('li[data-id] span').classList.remove('bg_yellow');
            }
        });
        Checkbox.checked = !Checkbox.checked;
        Array.from(cb).map(el => {
            el.querySelector('input').checked ? el.closest('li[data-id] span').classList.add('bg_yellow') : el.closest('li[data-id] span').classList.remove('bg_yellow');
        }) 
    } 
    
    #initListeners() {
        this.selectors.List.addEventListener('click', this.#handleClick.bind(this));
        this.selectors.moveButton.addEventListener('click', async () => this.#handleProductsMove());

        this.selectors.Filter.addEventListener('change', this.#fetchGroups.bind(this));
        this.selectors.Name.addEventListener('input', debounce(350, this.#fetchGroups.bind(this)));
    }

    async #fetchGroups() {
        await this.fetchList();
    } 
     
    getNameById(id) {
        return this.selectors.List.querySelector(`li[data-id="${id}"] > span`)?.textContent.trim().replaceAll(/\s+/g, ' ');
    }

    setCheckedProducts() {
        const checkedAll = sessionStorage.getItem('checkedAll') === 'true';
        const checkedIds = this.app.modules.TiuProduct.getCheckedIds();
         
        this.data.checkedProducts = checkedAll === true ? 'all' : checkedIds;
    }
    
    async #handleProductsMove(){
        this.setCheckedProducts();
        let cb = this.selectors.List.querySelectorAll('.check');
        let targetId = null;
        let targetEl = null;
        Array.from(cb).map(el => {
            if(el.querySelector('input').checked){
                targetEl = el.closest('li[data-id]');
                targetId = Number(targetEl.dataset['id']); 
            } 
        });
        await this.app.modules.TiuProduct.handleMove(this.data.checkedProducts, targetId);
    }

    async handleDelete(targetId) {
        if (!targetId) return M.toast({html: 'Произошла ошибка'});
        const targetName = this.getNameById(targetId);
        if (confirm(`Вы уверены, что хотите удалить группу "${targetName}"`)) {
            const res = await this.app.fetch('Group', 'delete', {id: targetId});
            if (!res.status) return M.toast({html: res.message || 'Произошла ошибка, повторите попытку'});
            this.fetchList();
        }
    }

    #renderParent(data, parent = null) {
      
        const items = data.reduce((r, c) => {
            if (null === parent && c.parent === null || Number(parent) === Number(c.parent)) {
                return [...r, c];
            }   
            return r;  
        }, []); 
        
        if (!items?.length) return '';

        const res = items.map(item => {
            const children = this.#renderParent(data, item.id);

            return this.getTpl.item({
                ...item,
                class: children === '' ? 'class="last"' : '',
                atts: Number(item.id) !== 2881645 ? 'draggable="true"' : '',
                name: Number(item.id) === 2881645 ? 'Корневая категория' : item.name,
                children,
            });
        }).join('');

        if (parent === null) {
            this.selectors.List.innerHTML = '';
            this.selectors.List.insertAdjacentHTML('beforeend', res);
            Array.from(this.selectors.List.children).map(el => {
                el.style.maxHeight = 'auto';
            });
            return;
        }

        return `<ul>${res}</ul>`;
    }

    #renderFilterParent(data, parent = null) {
        const res = data.map(item => {
            const children = this.#renderParent(data, item.id);
            return this.getTpl.item({
                ...item,
                class: children === '' ? 'class="last"' : '',
                atts: Number(item.id) !== 2881645 ? 'draggable="true"' : '',
                name: Number(item.id) === 2881645 ? 'Корневая категория' : item.name,
                children,
            });
        }).join('');
        if (parent === null) {
            this.selectors.List.innerHTML = '';
            this.selectors.List.insertAdjacentHTML('beforeend', res);
            Array.from(this.selectors.List.children).map(el => {
                el.style.maxHeight = 'auto';
            });
            return;
        }
        return `<ul>${res}</ul>`;
    }
    
    async fetchList() {
        this.data.selectedId = null;
        const openedIds = [
            2881645,  
            ...Array.from(this.selectors.List.querySelectorAll('li.opened')).map(i => Number(i.dataset['id']))];
 
        let value = this.selectors.Name.value.trim().replaceAll(/\s+/g, ' ');
        value = value !== '' && value.length > 2 ? value : null;

        this.selectors.List.innerHTML = this.app.preloader; 
        
        const res = await this.app.fetch('Group', 'getAll', {group_name: value});
        if (res.status && res?.data?.items?.length) {

            if(value === null){
                this.#renderParent(res.data.items);
            } else {
                this.#renderFilterParent(res.data.items);
            }

            Array.from(this.selectors.List.querySelectorAll('li'))
                .map(i => openedIds.includes(Number(i.dataset['id'])) && i.classList.add('opened'));

             this.selectors.cb = this.selectors.List.querySelectorAll('.check'); 
             this.selectors.cb.forEach((el) => {el.addEventListener('click', this.#toggleClick.bind(this))}); 
             
        } else {
            M.Toast({html: 'Не удалось получить группы'});
        }
    }
    

    getSelectedIds() {
        return this.data.selectedIds;
    }

    #getGroupId(e) {
        let el = e.target.closest('li');
        if (!el) return null;
        let id = el.dataset['id'];
        return id ?
            parseInt(id) :
            null;
    }

    initRenameGroup() {
        this.selectors.List.addEventListener('click', async (e) => {
            if (!e.target.closest('#tiu_products-groups')) return;
            let groupId = this.#getGroupId(e);
            if (groupId && e.target.closest('.tiuProductsGroups-list__item__edit-btn')) {

                const form = this.selectors.renameGroupName.querySelector('form');
                let nameInput = this.selectors.renameGroupName.querySelector('input[name="name"]');
                const saveBtn = this.selectors.renameGroupName.querySelector('.save');

                let Modal = M.Modal.init(this.selectors.renameGroupName, {
                    onOpenEnd: () => nameInput.focus(),
                    onCloseEnd: () => form.reset(),
                });

                let resGroupName = await this.app.fetch('Group', 'getOne', {id: groupId});
                nameInput.value = resGroupName.data.item.name;

                const renameGroupHandler = async (e) => {
                    e.preventDefault();
                    if (!form.checkValidity()) return;
                    const res = await this.app.fetch('Group', 'rename', {name: nameInput.value, id: groupId});
                    if (res.status) {
                        M.toast({html: res.message});
                        Modal.close();
                        this.app.modules.TiuProductGroups.fetchList(false);
                    } else {
                        M.toast({html: res.message || 'Произошла ошибка'});
                    }
                };
                form.addEventListener('submit', renameGroupHandler);
                saveBtn.addEventListener('click', renameGroupHandler);
            }
        })
    }


}
