import Module from './../base/module';
import {debounce} from 'throttle-debounce';


export default class Product extends Module{
  run() {
    this.setup();
    this.listeners();
    this.initCheckAll();
  } 

  setup() {
    this.selectors.$description.trumbowyg({
      lang: 'ru',
      btnsDef: {
        template: {
          fn: () => {
            this.selectors.$description.trumbowyg('html', this.data.descriptionTemplate);
          },
          ico: 'blockquote',
          title: 'Шаблон',
        },
      },
      btns: [
        ['template'],
        ['undo', 'redo'],
        ['p', 'h1', 'h2'],
        ['strong', 'em'],
        ['link'],
        ['justifyLeft', 'justifyCenter', 'justifyRight'],
        ['unorderedList', 'orderedList'],
        ['horizontalRule'],
        ['removeformat'],
        ['viewHTML'],
        ['fullscreen'],
      ],
      plugins: {
        upload: {
          serverPath: 'https://api.imgur.com/3/image',
          fileFieldName: 'image',
          headers: {
            'Authorization': 'Client-ID 314186324ed48c6',
          },
          urlPropertyName: 'data.link',
        },
      },
      autogrow: true,
      autogrowOnEnter: true,
    });

    this.selectors.$modal.modal({
      dismissible: false,
      onOpenStart: () => {},
      onOpenEnd: () => {
        this.animateDescription();
        this.selectors.$modal.find('#product_name').focus();
      },
    });
    this.selectors.$keywords.chips({
      placeholder: 'Ключевые слова',
      secondaryPlaceholder: 'добавить еще',
    });
    this.selectors.MKeywords = M.Chips.getInstance(this.selectors.$keywords);
  }

  init() {
    this.initSelectors();
    this.initLabels();

    this.data = {
      products: [],
      groups: [],
      total: null,
      isLoad: false,
      paginationOffset: 0,
      typingTimer: undefined,
      doneTypingInterval: 500,
      descriptionTemplate: '<p>Запчасть:&nbsp;</p><p>Марка:&nbsp;</p><p>Модель:&nbsp;</p><p>Цвет:&nbsp;</p><p>Состояние: б/у</p><p>Оригинальный номер:&nbsp;</p><p>Год выпуска:&nbsp;</p>',
    };
  }
 
  initSelectors() {
    let modal = '#modalProduct';
    let $modal = $(modal);

    this.selectors.Screen = document.querySelector('#products-screen');

    this.selectors.generateBarcodes = $('.product__generate-barcode');
    this.selectors.uploadImport = $('.product__upload-import');

    this.selectors.$list = $('#products-screen .product-list');
    this.selectors.list = this.selectors.Screen.querySelector('.product-list');
    this.selectors.tplListItem = 'product-list__item';
    this.selectors.listItem = '.product-list__item';
    this.selectors.listItemPriceField = '.product-list__item__price-field';
    this.selectors.listItemEditBtn = '.product-list__item__edit-btn';
    this.selectors.listItemDeleteBtn = '.product-list__item__delete-btn';

    this.selectors.modal = modal;
    this.selectors.$modal = $modal;
    this.selectors.$btnSave = $('#save_product');
    this.selectors.$btnCloseModal = $modal.find('.modal-close-confirm');

    this.selectors.$previewSimilar = $modal.find('.preview_similar-product');
    this.selectors.$previewSimilarAvitoCategory = $modal.find('.preview_similar-avito-category');
    this.selectors.similarProductItem = '.similar-product__item';
    this.selectors.similarAvitoCategoryItem = '.similar-avito-category__item';

    this.selectors.descriptionWrapper = '.product__description-wrapper';
    this.selectors.$descriptionWrapper = $(this.selectors.descriptionWrapper);
    this.selectors.description = '.description';
    this.selectors.$description = $modal.find('.description');

    this.selectors.$keywords = $modal.find('.keywords');
    
    this.selectors.products_list_total = this.selectors.Screen.querySelector('#products-list_total');

    this.selectors.CheckAll = this.selectors.Screen.querySelector('.check-all');
    this.selectors.$headPanel = $('.head-panel-products_screen');
    this.selectors.clearSelected = this.selectors.Screen.querySelector('.clearSelected');
    this.selectors.productDelete = this.selectors.Screen.querySelector('.product-delete');
    this.selectors.countSelected = this.selectors.Screen.querySelector('.countSelected');
    this.selectors.productsListTotalWrap = this.selectors.Screen.querySelector('.products-list_total-wrap');
  } 

  initLabels() {
    this.labels = {
      closeConfirm: 'Вы уверены, что хотите закрыть это окно?',
      saveConfirm: 'Вы уверены, что хотите сохранить товар?',
      deleteConfirm: 'Вы уверены, что хотите удалить выбранные товары?',

      defaultLabelGroup: 'Выбрать группу',
      defaultLabelCategory: 'Выбрать категорию',
      defaultLabelAvitoCategory: 'Выбрать категорию Авито',

      errors: {
        invalidName: 'Введите название товара, минимум 5 символов.',
        invalidDescription: 'Введите описание товара, минимум 5 символов',
        invalidTiuCat: 'Выберите категорию товара',
        addError: 'При сохранении товара произошла ошибка.',
        invalidConditionChar: 'Вы должны указать состояние детали',
        invalidModelChar: 'Вы должны указать модель детали',
      },
    };
  }

  listeners() {
    
    this.selectors.list.addEventListener('click', () => {
      this.checkedProductsHandler();
    });

    this.selectors.clearSelected.addEventListener('click', () => {
      this.uncheckAll();
      this.checkedProductsHandler();
    });
    
    $(document.body).on('keyup change', this.selectors.listItemPriceField, async (e) => {
      let id = $(e.target).closest(this.selectors.listItem).data('id');
      let price = parseFloat($(e.target).val());

      clearTimeout(this.data.typingTimer);

      if (e.code === 'Enter' || e.code === 'NumpadEnter') {
        if (true === await this.editPrice(id, price)) {
          //await this.fetchAll();
        }
      } else {
        this.data.typingTimer = setTimeout(async () => {
          if (true === await this.editPrice(id, price)) {
            //await this.fetchAll();
          }
        }, this.data.doneTypingInterval);
      }
    });

    $(document.body).on('keydown', this.selectors.listItemPriceField, () => {
      clearTimeout(this.data.typingTimer);
    });

    $(document.body).on('click', this.selectors.listItemDeleteBtn, async (e) => {
      const ids = () =>{
        if(sessionStorage.getItem('checkedAllProducts') === "true"){
          return "all";
        } else {
          return sessionStorage.getItem('allCheckedIdsProducts') ? sessionStorage.getItem('allCheckedIdsProducts').split(',') : null;
        }
      };
      if (confirm(this.labels.deleteConfirm)){
          await this.delete(ids());
      }
    });

    $(document.body).on('click', this.selectors.listItemEditBtn, (e) => {
      let id = $(e.target).closest(this.selectors.listItem).data('id');

      this.fillForm(id);
    });

    $(document.body).on('product-filters-update', async () => {
      this.selectors.$list.find('tbody').html('');
      await this.fillProducts();
      this.reinitMaterialize();
    });

    this.selectors.$btnCloseModal.on('click', () => {
      if (confirm(this.labels.closeConfirm)) {
        this.resetForm();
        this.selectors.$modal.modal('close');
        
      }
    });

    this.selectors.$btnSave.on('click', async () => {
      if (!this.validateForm()) {
        return;
      }

      if (confirm(this.labels.saveConfirm)) {
        let productId = await this.save();
 
        let addDataResponses = await Promise.all([
          await this.app.modules.Characteristics.saveAll(productId),
          await this.app.modules.Gallery.unassignAll(productId),
          await this.app.modules.Gallery.assignAll(productId),
        ]);

        if (addDataResponses.every(elem => elem === true)) {
          M.toast({html: 'Товар успешно добавлен'});
          this.close();
          this.resetForm();
          this.resetList();
        } else {
          M.toast({html: 'Товар добавлен, но произошла ошибка. Добавьте данные вручную.'});
        }
      }
    });

    this.getFormField('name').on('keyup', (e) => {
      let $e = $(e.target);

      if ($e.val().length >= 5) {
        $e.removeClass('invalid');
      }
    });

    this.getFormField('name').on('keyup', (e) => {
      clearTimeout(this.data.typingTimer);

      let title = $(e.target).val();
      
      if (e.code === 'Enter' || e.code === 'NumpadEnter') {
        this.getMeta(title);
        return;
      }

      this.data.typingTimer = setTimeout(() => this.getMeta(title), this.data.doneTypingInterval);
    });

    this.getFormField('name').on('keydown', (e) => {
      clearTimeout(this.data.typingTimer);
    });

    this.selectors.$modal.on('click', this.selectors.similarProductItem, (e) => {
      this.setSimilarData(e, this);
    });

    this.selectors.$modal.on('click', this.selectors.similarAvitoCategoryItem, (e) => {
      this.setAvitoCategory(e);
    });

    $(document.body).on('validation-success', (e) => {
      $('.materialboxed').materialbox();
    });

    window.addEventListener('scroll', debounce(100, async () => {
      if (!this.activeScreen() || this.selectors.Screen.scrollHeight <= window.innerHeight || this.data.isLoad) return;
      if (window.pageYOffset + window.innerHeight >= this.selectors.Screen.scrollHeight - window.innerHeight / 4) {
        await this.fetchAll();
        await this.fillProducts();
      }
    }));
  }
  
  #getAllItems = () => Array.from(this.selectors.Screen.querySelectorAll(this.selectors.listItem));

  checkAll() {
    this.#getAllItems().map(El => {
      El.querySelector('input[type="checkbox"]').checked = true;
    });
    sessionStorage.setItem('checkedAllProducts', true);
    sessionStorage.removeItem('allCheckedIdsProducts');
  }
 
  uncheckAll() {
    this.selectors.CheckAll.checked = false;
    sessionStorage.setItem('checkedAllProducts', false);
    this.#getAllItems().map(El => {
      El.querySelector('input[type="checkbox"]').checked = false;
    });
    sessionStorage.removeItem('allCheckedIdsProducts');
  }

  initChangeCheckbox() {
    let prevChecked = null;

    // Выделение ренджа товаров по шифту
    const checkBatchHandle = (prev, current) => {
      const List = this.selectors.list;
      const ProductList = Array.from(List.querySelector('tbody').children);

      const getIdx = id => {
        const El = List.querySelector(`tr[data-id="${id}"]`);
        return ProductList.indexOf(El);
      };

      const toggleByIdx = (idx) => {
        const ProductEl = ProductList[idx];
        const Checkbox = ProductEl?.querySelector('label.check input');
        if (!Checkbox) return;
        Checkbox.checked = !Checkbox.checked;
      };

      const getRange = (start, end) => {
        const dir = end > start;
        const max = Math.max(start, end) - 1;
        const min = Math.min(start, end) + 1;
        const length = (dir ? end - start : start - end);

        const res = Array.from({length}, (v, k) => dir ? min + k : max - k);
 
        return res
      };

      const prevIdx = getIdx(prev);
      const curIdx = getIdx(current);

      getRange(prevIdx, curIdx).map(toggleByIdx);
    };

    this.selectors.list.addEventListener('click', (e) => {
      if (!e.target.closest('tbody')) return;
      const id = this.#getEventId(e);
      
      // Клик по чекбоксу выделения товара
      if (id && e.target.closest('label.check')) {
        if (e.target.tagName !== 'SPAN') return;
        if (e.shiftKey && prevChecked) checkBatchHandle(prevChecked, id)
        prevChecked = id;
      }
    }, false);
  }

  #getEventId(e) {
    const el = e?.target?.closest('tr');
    if (!el) return null;
    const id = el.dataset['id'];
    return id ?
        parseInt(id) :
        null;
  }
  
  checkedProductsHandler(){
    if(sessionStorage.getItem('checkedAllProducts') === null) sessionStorage.setItem('checkedAllProducts', 'false');
    if(sessionStorage.getItem('checkedAllProducts') === 'true') this.selectors.CheckAll.checked = true;
    this.#getAllItems().map((El) => {
      let prevCheckedIds = [];
      const makeUniq = (arr) => [...new Set(arr)];
      if(sessionStorage.getItem('allCheckedIdsProducts')) prevCheckedIds = sessionStorage.getItem('allCheckedIdsProducts').split(',');
      if(sessionStorage.getItem('checkedAllProducts') !== null && sessionStorage.getItem('checkedAllProducts') === 'false'){
        if(El.querySelector('input[type="checkbox"]').checked === true){
              El.classList.add('bg_yellow');
              prevCheckedIds.push(El.dataset['id']);
              prevCheckedIds = makeUniq(prevCheckedIds);
              sessionStorage.setItem('allCheckedIdsProducts', prevCheckedIds);
        } else {
          El.classList.remove('bg_yellow');
          if(prevCheckedIds){
                prevCheckedIds = makeUniq(prevCheckedIds);
                prevCheckedIds = prevCheckedIds.filter(e => e !== El.dataset['id']);
                sessionStorage.setItem('allCheckedIdsProducts', prevCheckedIds);
          }
        }
      }
      if(sessionStorage.getItem('checkedAllProducts') !== null && sessionStorage.getItem('checkedAllProducts') === 'true'){
            El.querySelector('input[type="checkbox"]').checked = true;
            El.classList.add('bg_yellow');
      }
    });
    if(sessionStorage.getItem('checkedAllProducts') === 'false' && sessionStorage.getItem('allCheckedIdsProducts') !== null){
          this.selectors.countSelected.innerHTML = sessionStorage.getItem('allCheckedIdsProducts').split(',').length + '  тов. выбрано';
    } else { 
          this.selectors.countSelected.innerHTML = this.data.total + '  тов. выбрано';
    }
    if(sessionStorage.getItem('allCheckedIdsProducts') || (sessionStorage.getItem('checkedAllProducts') !== null && sessionStorage.getItem('checkedAllProducts') === 'true')){
          this.selectors.$headPanel.fadeIn('fast');
    } else {
          this.selectors.$headPanel.fadeOut('fast');
    }
  }
  
  initCheckAll() {
    this.selectors.CheckAll.addEventListener('change', (e) => {
      sessionStorage.getItem('checkedAllProducts') !== null && sessionStorage.getItem('checkedAllProducts') === 'true' ? this.uncheckAll() : this.checkAll();
      this.checkedProductsHandler();   
    }); 
  }
  
  activeScreen() {
    return document.querySelector('#products-screen').classList.contains('active');
  }

  async save() {
    let id = this.getFormField('id').val();
    let method = id ? 'edit' : 'add';
    let productData = this.getData();
    
    let response = await this.app.fetch('product', method, productData);

    if (this.app.responseValidate(response, ['id'], 'Не удалось сохранить товар')) {
      if (method === 'add') {
        id = response.data.id;
      }

      return id;
    } else {
      return false;
    }
  }

  #setTotal(num) {
    this.selectors.products_list_total.textContent = null === num || isNaN(num) ? '' : `${num}`;
    this.data.total = isNaN(num) ? null : Number(num);
    // this.selectors.countSelected.innerHTML = null === num || isNaN(num) ? '' : `${num}`;
  } 
  
  async fetchAll() {
    if (this.data.isLoad) return;
    this.data.isLoad = true;
    
    let fields = ['id', 'sku', 'name', 'price', 'group', 'barcode_status'];

    let rawProducts = await this.app.fetch('product', 'getAll', {
      fields: fields,
      offset: this.data.paginationOffset,
      models: ['images'],
      filters: this.app.modules.ProductFilter.getValues(),
    });
 
    this.data.paginationOffset += rawProducts.data.products.length;

    this.data.products = [...this.data.products, ...rawProducts.data.products];
    
    this.#setTotal(this.data.products.length);
     
    this.data.isLoad = false;
  }

  async fetchOne(id, fields = null, models = null) {
    let response = await this.app.fetch('product', 'get', {productId: id, fields: fields, models: models});

    if (this.app.responseValidate(response, ['product'], 'Произошла ошибка при запросе товара')) {
      return response.data.product;
    }

    return false;
  }

  async fillForm(id) {
    let product = await this.fetchOne(id, null, ['images', 'characteristics']);
    
    $.each(product, (i, v) => {
      if (['keywords', 'images', 'characteristics', 'group', 'category', 'avito', 'description'].indexOf(i) >= 0) {
        return;
      }
      this.selectors.$modal.find(`form [name="data[${i}]"]`).eq(0).val(v);
    });
    
    if (product?.images?.length) {
      product.images.forEach(image => {
        this.app.modules.Gallery.add(image);
      }); 
    }

    if (product?.characteristics?.length) {
      product.characteristics.forEach(el => {
        this.app.modules.Characteristics.add({
          name: el.name,
          dimension: el.dimension,
          value: el.value,
          parentId: el.parent_id,
        }); 
      });
    }
 
    if (product?.keywords) {
      let keywords = product.keywords.split(',');

      $.each(keywords, (i, v) => {
        let keyword = v.trim();

        this.setKeyword(keyword);
      });
    }
    
    if (product.hasOwnProperty('description')) {
      this.selectors.$description.trumbowyg('html', product.description);
    }

    if (product.hasOwnProperty('group')) {
      this.app.modules.Groups.set(product.group);
    }
    
    if (product.hasOwnProperty('autodonor_id')) {
      this.app.modules.Autodonors.setAutodonor(product.autodonor_id, product.autodonors_number);
    }

    if (product.hasOwnProperty('category')) {
      this.app.modules.TiuCategories.set(product.category);
    }

    if (product.hasOwnProperty('avito')) {
      this.app.modules.AvitoCategories.set(product.avito);
    }

    if (product.hasOwnProperty('storage')) {
      this.app.modules.Characteristics.add({
        name: 'Секция хранения',
        value: product.storage,
      });
    }
    
    if (product.hasOwnProperty('warehouse')) {
      this.app.modules.Characteristics.add({
        name: 'Склад хранения',
        value: product.warehouse,
      }); 
    }
    
    this.open();

    this.reinitMaterialize();
  }

  async fillProducts() {
    this.data.products.length ? this.selectors.productsListTotalWrap.style.display = 'flex' : this.selectors.productsListTotalWrap.style.display = 'none';
    this.data.products.map(product => {
      if (product.images.length) {
        product.thumbSmall = this.app.getConfig('thumbsUrl') + product.images[0].thumbs.smaller;
        product.thumbMedium = this.app.getConfig('thumbsUrl') + product.images[0].thumbs.medium;
      } else {  
        product.thumbSmall = window.location.protocol + '//' + window.location.host + '/data/zaglushka_70_70.png';
      } 
      
        if(product.barcode_status === "printed"){
          product.barcode_status = "<img src='/data/barcode_placeholder.png' style='width: 70px;'>";
        } else {
          product.barcode_status = "";
        }
        
      if (!this.selectors.$list.find(`${this.selectors.listItem}[data-id="${product.id}"]`).length) {
        this.selectors.$list.find('tbody').append(this.app.getTemplate(this.selectors.tplListItem, product));
      }
    });

    // if (sessionStorage.getItem('checkedAllProducts') === 'true') this.checkAll(); 
    const prevCheckedIds = sessionStorage.getItem('allCheckedIdsProducts') ? sessionStorage.getItem('allCheckedIdsProducts').split(',') : null;

    this.#getAllItems().map((El) => { 
      if(sessionStorage.getItem('checkedAllProducts') !== null && sessionStorage.getItem('checkedAllProducts') === 'false'){
        if(prevCheckedIds !==null){
          if(prevCheckedIds.includes(El.dataset['id'])){
            El.classList.add('bg_yellow');
            El.querySelector('input[type="checkbox"]').checked = true;
          } else {
            El.classList.remove('bg_yellow');
            El.querySelector('input[type="checkbox"]').checked = false;
          }
        }
      }
      if(sessionStorage.getItem('checkedAllProducts') !== null && sessionStorage.getItem('checkedAllProducts') === 'true'){
        El.classList.add('bg_yellow');
        El.querySelector('input[type="checkbox"]').checked = true;
      }
    });

    this.checkedProductsHandler();
  }

  async editPrice(id, price) {
    if (isNaN(price)) {
      price = '';
    }

    let response = await this.app.fetch('product', 'editPrice', {id: id, price: price});

    if (this.app.responseValidate(response, [], 'Не удалось обновить цену у товара')) {
      M.toast({html: response.message});
      return true;
    }
  }

  getData() {
    let formData = new FormData(this.selectors.$modal.find('form')[0]);

    formData.delete('data[images]');

    formData.append('data[description]', this.selectors.$description.trumbowyg('html')); //Описание
    
    let warehouse = this.app.modules.Characteristics.selectors.$warehouse[0].value === "null" ? "" : this.app.modules.Characteristics.selectors.$warehouse[0].value;

    formData.append('data[warehouse]', warehouse);
    formData.append('data[storage]', this.app.modules.Characteristics.selectors.$storage.val()); 
       
    $.each(this.getKeywords(), (i, v) => {
      formData.append(`data[keywords][${i}]`, v.tag);
    }); //Ключевые слова

    return formData;
  }

  async delete(ids) {
    const callback = async () => {
          let response = await this.app.fetch('product', 'delete', {productIds: ids});
          if (response.status) {
              this.resetList();
              M.toast({html: response.message});
              return true;
          } else {
              M.toast({html: response.message});
          }
          return false;
    };
    await this.cancelableCallback(callback);
  }


  #lockScreen = () => this.selectors.Screen.classList.add('disabled');

  #unlockScreen = () => this.selectors.Screen.classList.remove('disabled');

  async cancelableCallback(callback) {
    let canceled = false;

    const proceed = async () => {
      !canceled && await callback();
      this.#unlockScreen();
    };

    this.#lockScreen();

    const toast = M.toast({
      html: '<span>Отменить изменение</span><button class="btn-flat toast-action">Отменить</button>',
      displayLength: 10000,
      completeCallback: proceed,
    });

    toast.el.querySelector('button').addEventListener('click', () => {
      canceled = true;
      proceed();
      toast.dismiss();
    });
  }
  
  
  getFormField(name) {
    return this.selectors.$modal.find(`[name='data[${name}]']`);
  }

  resetForm() {
    this.selectors.$modal.find('form')[0].reset();

    this.selectors.$modal.find('input:hidden').val('');

    this.resetKeywords();

    this.selectors.$description.trumbowyg('empty');

    this.app.modules.Gallery.clear();

    this.app.modules.Characteristics.reset();

    this.app.modules.Groups.reset();
    this.app.modules.TiuCategories.reset();
    this.app.modules.AvitoCategories.reset();
    this.app.modules.Autodonors.reset();

    this.resetMeta();
  }

  validateForm() {
    if (!(this.getFormField('name').val().length >= 5)) {
      this.getFormField('name').addClass('invalid');
      this.getFormField('name').focus();
      M.toast({html: this.labels.errors.invalidName});
      return false;
    }

    if (!(this.selectors.$description.trumbowyg('html').length >= 5)) {
      M.toast({html: this.labels.errors.invalidDescription});
      return false;
    }

    // let chars = this.app.modules.Characteristics.getAll();
    
    // if (!chars[0].value.length || chars[0].value == 'Выберите состояние') {
    //   this.app.modules.Characteristics.open();
    //   setTimeout(() => {
    //     M.toast({html: this.labels.errors.invalidConditionChar});
    //   }, 100);
    //   return false;
    // }
    //
    // if (!chars[1].value.length || chars[1].value == 'Выберите модель') {
    //   this.app.modules.Characteristics.open();
    //   setTimeout(() => {
    //     M.toast({html: this.labels.errors.invalidModelChar});
    //   }, 100);
    //   return false;
    // }

    // if (!this.getFormField('category').val()) {
    //   this.app.modules.TiuCategories.open();
    //
    //   setTimeout(() => {
    //     M.toast({html: this.labels.errors.invalidTiuCat});
    //   }, 100);
    //
    //   return false;
    // }

    return true;
  }
  
  open() {
    this.selectors.$modal.modal('open');
  }

  close() {
    this.selectors.$modal.modal('close');
  }

  getMeta(title) {
    this.app.doAjax('product', 'getMeta', {title: title}, this.processMeta.bind(this));
  }

  processMeta(data) {
    this.data.similar = data.data.similar;
    this.data.similarAvitoCategories = data.data.similarAvitoCategories;
    this.data.oem = data.data.oem;
    this.data.manufacturer = data.data.manufacturer;

    if (data.data.oem) {
      this.setKeyword(data.data.oem);
    }

    if (data.data.hasOwnProperty('manufacturer') && data.data.manufacturer.trim().length) {
      this.app.modules.Characteristics.add({
        name: 'Марка',
        value: data.data.manufacturer,
      });
      
      
      M.toast({html: `Установлена марка «${data.data.manufacturer}»`});
    }

    this.fillSimilar();

    this.fillSimilarAvitoCategories();
  }

  async resetList() {
    this.data.products = [];
    this.data.paginationOffset = 0;
    this.selectors.$list.find('tbody').html('');
    this.selectors.$headPanel.fadeOut('fast');
    this.uncheckAll();
    
    await this.fetchAll();
    await this.fillProducts();
  }

  resetMeta() {
    this.data.similar = undefined;
    this.data.similarAvitoCategories = undefined;
    this.data.oem = undefined;
    this.data.manufacturer = undefined;

    this.resetSimilar();

    this.resetSimilarAvitoCategories();
  }

  fillSimilar() {
    let $html = this.selectors.$previewSimilar;
    $html.html('');
    let items = this.data.similar;
    if(items === undefined || !items.length){  
      $html.html('<p style="padding: 0 .75rem;">По данному запросу ничего не найдено</p>');
      return;
    }
    for (let k in items) { 
      if (!items.hasOwnProperty(k)) {
        return;
      }
      items[k].thumb = items[k].thumbs[0] ? items[k].thumbs[0] : "/data/zaglushka_50_50.png";
      $html.append(this.app.getTemplate('similar_product', items[k]));
    }
  }

  resetSimilar() {
    let $html = this.selectors.$previewSimilar;
    $html.html('');
  }

  fillSimilarAvitoCategories() {
    let $html = this.selectors.$previewSimilarAvitoCategory;
    $html.html('');
    let items = this.data.similarAvitoCategories;
    if(items === undefined || !items.length){
      $html.html('<p style="padding: 0 .75rem;">По данному запросу ничего не найдено</p>');
      return;
    }
    for (let k in items) {
      if (!items.hasOwnProperty(k)) {
        return;
      }
      $html.append(this.app.getTemplate('similar_avito-category', items[k]));
    }
  }

  resetSimilarAvitoCategories() {
    let $html = this.selectors.$previewSimilarAvitoCategory;

    $html.html('');
  }

  setSimilarData(e, _this) {
    let id = $(e.target).closest(this.selectors.similarProductItem).data('id');

    let itemData = _this.data.similar.find((d) => { 
      return parseInt(d.id) === parseInt(id);
    });
    
    this.getFormField('name').val(itemData.name);

    this.selectors.$description.trumbowyg('html', itemData.description);

    let keywords = [];

    if (itemData.hasOwnProperty('keywords')) {
      keywords = itemData.keywords.split(',');

      if (itemData.hasOwnProperty('oem')) {
        keywords.push(itemData.oem);
      }

      for (let k in keywords) {
        if (!keywords.hasOwnProperty(k)) {
          return;
        }

        keywords[k] = keywords[k].trim();
      }

      keywords = [...new Set(keywords)];

      if (keywords.length) {
        _this.resetKeywords();

        for (let k in keywords) {
          if (!keywords.hasOwnProperty(k)) {
            return;
          }

          _this.setKeyword(keywords[k]);
        }
      }
    }

    if (itemData.hasOwnProperty('group')) {
      this.app.modules.Groups.set(itemData.group);
    }

    if (itemData.hasOwnProperty('autodonor_id')) {
      this.app.modules.Autodonors.setAutodonor(itemData.autodonor_id, itemData.autodonors_number);
    }

    if (itemData.hasOwnProperty('category')) {
      this.app.modules.TiuCategories.set(itemData.category);
    }

    if (itemData.hasOwnProperty('avito_category')) {
      this.app.modules.AvitoCategories.set(itemData['avito_category']);
    }
     
    if (itemData.hasOwnProperty('storage')) {
      this.app.modules.Characteristics.add({
        name: 'Секция хранения',
        value: itemData['storage'],
      });  
    }
 
    if (itemData.hasOwnProperty('warehouse')) { 
       if(itemData['warehouse'] !== null){
              this.app.modules.Characteristics.add({
                  name: 'Склад хранения',
                  value: itemData['warehouse'],
              }); 
       }
    } 
       
    if (itemData.hasOwnProperty('characteristics')) {
        if(itemData.characteristics !== null){
            this.app.modules.Characteristics.reset();
            const characteristics = JSON.parse(itemData.characteristics);
            Object.entries(characteristics).map((val, key)=>{
                const name = Object.values(val)[0];
                const value = Object.values(val)[1];
                
                let parentId = null;
         
                if (itemData.hasOwnProperty('characteristics_parent_ids')) {
                      if(itemData.characteristics_parent_ids !== null){
                          const characteristics_parent_ids = JSON.parse(itemData.characteristics_parent_ids);
                          Object.entries(characteristics_parent_ids).map((val, key)=> {
                                const name2 = Object.values(val)[0];
                                const parent_id = Object.values(val)[1];
                                if(name === name2) parentId = parent_id;
                          });
                      }
                }
                this.app.modules.Characteristics.add({
                      name: name,
                      dimension: '',
                      value: value,
                      parentId: parentId,
                });
            }); 
        }
    }
    
  }

  setAvitoCategory(e) {
    let id = $(e.target).closest(this.selectors.similarAvitoCategoryItem).data('id');

    this.app.modules.AvitoCategories.set(id);
  }

  setKeyword(keyword) {
    return this.selectors.MKeywords.addChip({tag: keyword});
  }

  getKeywords() {
    return this.selectors.MKeywords.chipsData;
  }

  resetKeywords() {
    let l = this.getKeywords().length;

    for (let i = 0; i <= l; i++) {
      this.selectors.MKeywords.deleteChip(0);
    }
  }

  reinitMaterialize() {

    M.AutoInit();

    M.updateTextFields();

    $('select').each(function() {
      let instance = M.FormSelect.getInstance(this);

      if (undefined !== instance) {
        instance.destroy();
      }

      M.FormSelect.init(this);
    });

    $(this.selectors.listItem + ' .materialboxed').materialbox();
  }

  animateDescription() {
    let $tbw = this.selectors.$description.closest('.trumbowyg');

    $(this.selectors.$description).on('tbwopenfullscreen', (e) => {
      let $pls = $('.trumbowyg-fullscreen-placeholder');

      let plsTop, plsLeft, plsHeight, plsWidth;

      plsTop = $pls.offset().top;
      plsLeft = $pls.offset().left;
      plsHeight = $pls.innerHeight();
      plsWidth = $pls.innerWidth();

      $tbw.removeClass('trumbowyg-fullscreen');

      $tbw.css({
        position: 'fixed',
        'z-index': 99999,
        'background-color': '#fff',
        top: plsTop,
        left: plsLeft,
        height: plsHeight,
        width: plsWidth,
      });

      $tbw.stop().animate({
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
      }, 350, 'easeInOutExpo', () => {
        this.selectors.$modal.css({overflow: 'hidden'});
        $tbw.addClass('trumbowyg-fullscreen');
        $tbw.removeAttr('style');
      });
    });

    $(this.selectors.$description).on('tbwclosefullscreen', (e) => {
      let $pls = this.selectors.$descriptionWrapper;

      let plsTop, plsLeft, plsHeight, plsWidth;

      plsTop = $pls.offset().top;
      plsLeft = $pls.offset().left;
      plsHeight = $pls.innerHeight();
      plsWidth = $pls.innerWidth();

      $tbw.addClass('trumbowyg-fullscreen');

      $pls.css({
        height: plsHeight,
        width: plsWidth,
      });

      $tbw.css({
        position: 'fixed',
        'z-index': 99999,
        'background-color': '#fff',
        top: 0,
        left: 0,
        height: '100%',
        width: '100%',
      });

      $tbw.stop().animate({
        top: plsTop,
        left: plsLeft,
        height: plsHeight,
        width: plsWidth,
      }, 350, 'easeInOutExpo', () => {
        $tbw.removeClass('trumbowyg-fullscreen');
        $tbw.removeAttr('style');
        $pls.removeAttr('style');
        this.selectors.$modal.css({overflow: 'auto'});
      });
    });
  }
}
