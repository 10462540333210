export default class Modal {
   static calculateContainerHeight($modal) {
        let container, header, footer, maxHeight;

        container = parseInt($modal.css('max-height'));
        container = $(window).innerHeight() / 100 * container;

        header = $modal.find('.modal-header').outerHeight();
        footer = $modal.find('.modal-footer').outerHeight();

        maxHeight = container - header - footer;

        maxHeight = Math.round(maxHeight);

        $modal.find('.modal-content').css('max-height', `${maxHeight}px`);
    }
 
    static getEndingTop() {
        let breakpoints = {
            0: 1,
            600: 5,
            992: 10,
            1200: 20
        };

        let windowWidth = $(window).outerWidth();

        let currentBreakpoint = 0;

        for (let i in breakpoints) {
            if (!breakpoints.hasOwnProperty(i)) {
                continue;
            }

            if (windowWidth >= i) {
                currentBreakpoint = i;
            }
        }

        return breakpoints[currentBreakpoint];
    }
}