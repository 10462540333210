import Module from "./../base/module";
import Modal from "./../base/modal";

export default class Characteristics extends Module {
    init() {
        let $modal = $('#modalProductChars');

        this.selectors.$modal = $modal;
        this.selectors.$modalContent = $modal.find('.modal-content');
        this.selectors.$charList = $modal.find('.characteristics-list');
        this.selectors.$addBtn = $modal.find('.add_characteristic');
        this.selectors.$resetBtn = $modal.find('.reset_characteristics');

        this.selectors.charItem = '.characteristic-item';

        this.selectors.inputName = '.char_name';
        this.selectors.inputDimention = '.char_dimension';
        this.selectors.inputValue = '.char_value';

        this.selectors.deleteBtn = '.delete';

        this.selectors.tplItemName = 'product-chars__item';

        this.selectors.modal = document.querySelector('#modalProductChars');
        this.selectors.modalContent =  this.selectors.modal.querySelector('.modal-content');

        this.selectors.charList = this.selectors.modal.querySelector('.characteristics-list');
        
        this.labels = {};

        this.labels.errors = {
            cantDeleteById: 'Не удалось удалить характеристику по id, id не найден'
        };
        
        this.selectors.$warehouse = $modal.find('.characteristic-item__default__warehouse');
        this.selectors.$storage = $modal.find('.characteristic-item__default__section');
          
        this.selectors.$selectMark = $modal.find('.characteristic-item__default__mark');
        this.selectors.$selectModel = $modal.find('.characteristic-item__default__model');
        this.selectors.$selectGeneration = $modal.find('.characteristic-item__default__generation');
        this.selectors.$selectSerie = $modal.find('.characteristic-item__default__serie');

        this.selectors.selectMark = this.selectors.modal.querySelector('.characteristic-item__default__mark');
        this.selectors.selectModel = this.selectors.modal.querySelector('.characteristic-item__default__model');
        this.selectors.selectGeneration = this.selectors.modal.querySelector('.characteristic-item__default__generation');
        this.selectors.selectSerie = this.selectors.modal.querySelector('.characteristic-item__default__serie');
    }

    open() {
        this.selectors.$modal.modal('open');
    }

    run() {
        this.listeners();
        this.setup();
    }

    listeners() {
        this.selectors.$charList.on('click', this.selectors.deleteBtn, (e) => {
            let id = $(e.target).closest(this.selectors.deleteBtn).data('id');

            this.deleteById(id);
        });

        this.selectors.$addBtn.on('click', (e) => {
            this.add();

            this.selectors.$modalContent.clearQueue();

            this.selectors.$modalContent.animate({
                scrollTop: this.selectors.$modalContent.prop("scrollHeight")
            }, 350);
        });

        this.selectors.$resetBtn.on('click', (e) => {
            this.reset();
        });

        $('#modalProductChars .characteristic-item__default__section').mask('00-00-00-00-00');
    }   
 
    setup()
    {
        this.selectors.$modal.modal({
            onOpenEnd: () => {
                Modal.calculateContainerHeight(this.selectors.$modal);
            } 
        });
    }
    
    async fetch(productId) {
        let response = await this.app.fetch('characteristic', 'getAll', {productId: productId});

        if (this.app.responseValidate(response, ['characteristics'], 'Не удалось получить характеристики')) {
            return response.data.characteristics;
        }
    }
 
    async saveAll(productId) {
        
        let response = await this.app.fetch('characteristic', 'saveAll', {
            productId: productId,
            characteristics: this.getAll()
        });

        if(this.app.responseValidate(response), [], 'Не удалось сохранить характеристики') {
            return true;
        }

        return false;
    }
    
    add(data = {}) {
        this.cleanup();
        
        if (data.name === 'Состояние') {
            let $condition = $('.characteristic-item__default__condition');
            $condition.formSelect('destroy');
            $condition.find(`option:selected`).prop('selected', false);
            $condition.find(`option[value="${data.value}"]`).prop('selected', true);
            $condition.formSelect();
            return;
        }
        
        if (data.name === 'Склад хранения') {
            data.value = data.value === "" ? "null" : data.value;
            let $condition = $('.characteristic-item__default__warehouse');
            $condition.formSelect('destroy');
            $condition.find(`option:selected`).prop('selected', false);
            $condition.find(`option[value="${data.value}"]`).prop('selected', true);
            $condition.formSelect();
            return; 
        }
        
        if (data.name === 'Секция хранения') { 
            $('.characteristic-item__default__section').val(data.value);
            return;
        }
        
        let id = parseInt(this.selectors.$charList.children().eq(-1).data('id')) + 1 || 1;

        let defaults = {
            id: id,
            name: '',
            dimension: '',
            value: ''
        };

        let $item = $(this.app.getTemplate(this.selectors.tplItemName, {...defaults, ...data}));

        this.deleteByName(data.name);

        this.selectors.$charList.append($item);

        if(!this.selectors.$charList.find('.characteristic-item').find('.cust_title').length) {
            this.selectors.$charList.find('.characteristic-item').prepend("<div class='col s12 cust_title'><h6>Кастомные характеристики</h6></div>");
        }
        M.updateTextFields();
    } 

    cleanup() {
        this.selectors.$charList.children(':not(.characteristic-item__default)').each((i, e) => {
            let id = $(e).data('id');
            let $name = $(e).find(this.selectors.inputName);
            let $value = $(e).find(this.selectors.inputValue);
            if (!$name.val().trim().length || !$value.val().trim().length) {
                this.deleteById(id);
            }
        });
    } 

    deleteById(id) {
        let item = this.selectors.$charList.find(this.selectors.charItem + `[data-id="${id}"]`);

        if (item.length) {
            item.remove();
        } else {
            console.error(this.labels.errors.cantDeleteById);
        }
    }

    deleteByName(name) {
        if (!name) {
            return;
        }

        name = name.trim();
        let item = this.selectors.$charList
            .find(this.selectors.charItem + ` input.char_name`)
            .filter(function () {
                return this.value == name
            })
            .closest(this.selectors.charItem);

        if (item.length) {
            this.deleteById(item.data('id'));
        }
    } 

    reset() {
        this.selectors.$charList.children(':not(.characteristic-item__default)').remove();

        let $condition = $('.characteristic-item__default__condition');
        $condition.formSelect('destroy');
        $condition.find(`option:selected`).prop('selected', false);
        $condition.find(`option`).eq(0).prop('selected', true);
        $condition.formSelect();

        this.selectors.$storage.val(''); 
        
        this.selectors.$warehouse.formSelect('destroy');
        this.selectors.$warehouse.find(`option:selected`).prop('selected', false);
        this.selectors.$warehouse.find(`option`).eq(0).prop('selected', true);
        this.selectors.$warehouse.formSelect();
    }
     
    getAll() {
        let condition_val = null;
        if($('.characteristic-item__default__condition').length) condition_val = $('.characteristic-item__default__condition')[0].value !=="null" ? $('.characteristic-item__default__condition')[0].value : null;
        
        let warehouse_val = null;
        if($('.characteristic-item__default__warehouse').length) warehouse_val = $('.characteristic-item__default__warehouse')[0].value !=="null" ? $('.characteristic-item__default__warehouse')[0].value : null;
        
        let data = [
            {
                name: 'Состояние',
                dimension: '',
                value: condition_val,
                parent_id: null,
            },
            {
                name: 'Склад хранения',
                dimension: '',
                value: warehouse_val,
                parent_id: null,
            },
            {
                name: 'Секция хранения',
                dimension: '',
                value: $('.characteristic-item__default__section').val().length ? $('.characteristic-item__default__section').val() : null,
                parent_id: null,
            }
        ];

        let $charList = this.selectors.$charList;

        $charList.children().each((i, e) => {

            let itemData = {
                name: $(e).find(this.selectors.inputName).val(),
                dimension: $(e).find(this.selectors.inputDimention).val(),
                value: $(e).find(this.selectors.inputValue).val(),
            };

            if (undefined === itemData.name || !itemData.name.length
                || undefined === itemData.value || !itemData.value.length) {
                return;
            }

            data.push(itemData);
        });

        return data;
    }
    
}
