import CategoryHandler from "./../base/categoryHandler";

export default class AvitoCategories extends CategoryHandler {
    init() {
        this.data.favorites = true;

        this.ajax = {
            action: 'avitoCategories'
        };

        this.labels = {
            cantSelectedThisCat: 'Выберите другую категорию, т.к. эта категория является конейтнером',
            resetNotice: 'Вы уверены, что хотите сбросить категорию Авито?'
        };

        this.selectors.$modal = $('#modalProductAvitoCat');
        this.selectors.$input = $("[name='data[avito]']");

        this.selectors.$addToFavorite = this.selectors.$modal.find('.avito_add_to_fav');
        this.selectors.$favoriteList = this.selectors.$modal.find('.avitoFavoriteList');
        this.selectors.favoriteItem = '.avito_favorite-list__item';
        this.selectors.favoriteDelete = `${this.selectors.favoriteItem} .delete`;
        this.selectors.favoriteItemTpl = 'avitoFavorite-list__item';
    }

    validateSelectedNode(id) {
        let match = id.match(/[a-zA-Z]/);

        if (match !== null && match.length > 0) {
            M.toast({html: this.labels.cantSelectedThisCat});
            return false;
        }

        return true;
    }
}
