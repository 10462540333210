import Module from './../base/module'

export default class AutodonorCharacteristics extends Module {
    init () {
        this.selectors.modalAu = document.querySelector('#modalAutodonor')
        this.selectors.$modalAu = $('#modalAutodonor')

        this.selectors.$selectMark = this.selectors.$modalAu.find(
          '#autodonor-form__mark')
        this.selectors.$selectModel = this.selectors.$modalAu.find(
          '#autodonor-form__model')
        this.selectors.$selectGeneration = this.selectors.$modalAu.find(
          '#autodonor-form__generation')
        this.selectors.$selectSerie = this.selectors.$modalAu.find(
          '#autodonor-form__serie')

        this.selectors.selectMark = this.selectors.modalAu.querySelector(
          '#autodonor-form__mark')
        this.selectors.selectModel = this.selectors.modalAu.querySelector(
          '#autodonor-form__model')
        this.selectors.selectGeneration = this.selectors.modalAu.querySelector(
          '#autodonor-form__generation')
        this.selectors.selectSerie = this.selectors.modalAu.querySelector(
          '#autodonor-form__serie')
    }

    select2FieldsConfig() { 
        this.selectors.$selectMark.select2({
            width: '100%',
            dropdownParent: '#modalAutodonor',
            theme: 'material',
            templateResult: (data) => {
                if (!data.id || data.id === 'all' || data.id === 'empty') return data.text
                const $html = `<div style="display: flex;align-items: center;"><span style="background-image: url('/data/logos/` +
                    data.element.dataset.id +
                    `.png');width: 30px;height: 30px;margin-right: 5px"></span><span>` +
                    data.text + `</span></div>`
                if (data.element) return $($html)
            },
            placeholder: 'Выберите марку',
        })
        this.selectors.$selectModel.select2({
            width: '100%',
            dropdownParent: '#modalAutodonor',
            theme: 'material',
            placeholder: 'Выберите модель',
        })
        this.selectors.$selectGeneration.select2({
            width: '100%',
            dropdownParent: '#modalAutodonor',
            theme: 'material',
            placeholder: 'Выберите поколение',
        })
        this.selectors.$selectSerie.select2({
            width: '100%',
            dropdownParent: '#modalAutodonor',
            theme: 'material',
            placeholder: 'Выберите кузов',
        })

        this.selectors.$selectModel.next().find('.select2-selection__arrow').addClass('material-icons').html('arrow_drop_down')
        this.selectors.$selectMark.next().find('.select2-selection__arrow').addClass('material-icons').html('arrow_drop_down')
        this.selectors.$selectGeneration.next().find('.select2-selection__arrow').addClass('material-icons').html('arrow_drop_down')
        this.selectors.$selectSerie.next().find('.select2-selection__arrow').addClass('material-icons').html('arrow_drop_down')
    }

    eventsHundlerOn() 
    {
        const id = this.selectors.modalAu.querySelector('input[name=\'data[id]\']').value
        if (id) return // метод выполняется только при создании нового автодонора
        this.markEventOn();
        this.modelEventOn();
        this.generationEventOn();
    }
    
    markEventOn()
    {
        this.selectors.$selectMark.on('change', () => {
            this.getModel()
            this.getGeneration()
            this.getSerie()
        }) 
    }
    
    modelEventOn()
    {
        this.selectors.$selectModel.on('change', () => {
            this.getGeneration()
            this.getSerie()
        })
    }
    
    generationEventOn()
    {
        this.selectors.$selectGeneration.on('change', () => {
            this.getSerie()
        })
    }

    reset() {
        this.selectors.$selectMark.off()
        this.selectors.$selectModel.off()
        this.selectors.$selectGeneration.off()
        this.selectors.$selectSerie.off()

        this.selectors.$selectMark.val('').trigger('change')
        this.selectors.$selectModel.children('option:not([value=""])').remove()
        this.selectors.$selectGeneration.children('option:not([value=""])').remove()
        this.selectors.$selectSerie.children('option:not([value=""])').remove()
    }

    async getMark(markNameParam = null) {
        let markName = null
        await this.app.fetch('characteristic', 'getMark').then((data) => {
            this.selectors.$selectMark.children('option:not([value=""])').remove()
            data.data.marks.forEach((obj) => {
                if (markNameParam === obj.name) markName = markNameParam
                this.selectors.$selectMark.append(
                    `<option value="${obj.name}" data-id="${obj.id}">${obj.name}</option>`)
            })
            markName !== null ? this.selectors.$selectMark.val(markName).trigger('change') : this.selectors.$selectMark.val('').trigger('change')
        })
    }

    async getModel(modelNameParam = null, parentId = null) {
        let id = parentId
        if (parentId == null) {
            if (this.selectors.selectMark.selectedOptions.length) {
                if (this.selectors.selectMark.selectedOptions[0].dataset.hasOwnProperty(
                    'id')) id = this.selectors.selectMark.selectedOptions[0].dataset.id
            }
        }
        let modelName = null
        this.selectors.$selectModel.children('option:not([value=""])').remove()
        if (id !== null) {
            await this.app.fetch('characteristic', 'getModel', {id_car_mark: id}).then((data) => {
                data.data.models.forEach((obj) => {
                    if (modelNameParam === obj.name) modelName = modelNameParam
                    this.selectors.$selectModel.append(
                        `<option value="${obj.name}" data-id="${obj.id}">${obj.name}</option>`)
                })
                modelName !== null ? this.selectors.$selectModel.val(modelName).trigger('change') : this.selectors.$selectModel.val('').trigger('change')
            })
        }
    }

    async getGeneration(GenerationNameParam = null, parentId = null) {
        let id = parentId
        if (parentId == null) {
            if (this.selectors.selectModel.selectedOptions.length) {
                if (this.selectors.selectModel.selectedOptions[0].dataset.hasOwnProperty(
                    'id')) id = this.selectors.selectModel.selectedOptions[0].dataset.id
            }
        }
        let GenerationName = null
        this.selectors.$selectGeneration.children('option:not([value=""])').remove()
        if (id !== null) {
            const response = await this.app.fetch('characteristic', 'getGeneration', {id_car_model: id});
            response.data.generation.forEach((obj) => {
                if (obj.year_start == null) obj.year_start = 'н.в';
                if (obj.year_stop == null) obj.year_stop = 'н.в';
                const year = obj.year_start || obj.year_stop ? ` (${obj.year_start} - ${obj.year_stop})` : ``;
                if (GenerationNameParam === obj.name + year) GenerationName = GenerationNameParam;
                if (GenerationNameParam === obj.name) GenerationName = GenerationNameParam + year;
                this.selectors.$selectGeneration.append(`<option value="${obj.name}${year}" data-id="${obj.id}">${obj.name}${year}</option>`);
            })
            GenerationName !== null ? this.selectors.$selectGeneration.val(GenerationName).trigger('change') : this.selectors.$selectGeneration.val('').trigger('change');
        }
    }

    async getSerie(SerieNameParam = null, parentId = null) {
        let id = parentId
        if (parentId == null) {
            if (this.selectors.selectGeneration.selectedOptions.length) {
                if (this.selectors.selectGeneration.selectedOptions[0].dataset.hasOwnProperty('id')) id = this.selectors.selectGeneration.selectedOptions[0].dataset.id
            }
        }
        let SerieName = null
        this.selectors.$selectSerie.children('option:not([value=""])').remove()
        if (id !== null) {
            const response = await this.app.fetch('characteristic', 'getSerie',
                {id_car_generation: id})
            response.data.serie.forEach((obj) => {
                let name = obj.name[0].toUpperCase() + obj.name.slice(1)
                if (obj.notice) name = name + ' ' + '(' + obj.notice + ')'
                if (SerieNameParam === name) SerieName = SerieNameParam
                this.selectors.$selectSerie.append(
                    `<option value="${name}" data-id="${obj.id}">${name}</option>`)
            })
            SerieName !== null ? this.selectors.$selectSerie.val(SerieName).trigger('change') : this.selectors.$selectSerie.val('').trigger('change')
        }
    }

    getAll() {
        let mark_val = null
        let mark_id = null

        if (this.selectors.$selectMark.select2(
            'data').length) mark_val = this.selectors.$selectMark.select2(
            'data')[0].id !== '' ? this.selectors.$selectMark.select2(
            'data')[0].text : null

        let model_val = null
        let model_id = null

        if (this.selectors.$selectModel.select2(
            'data').length) model_val = this.selectors.$selectModel.select2(
            'data')[0].id !== '' ? this.selectors.$selectModel.select2(
            'data')[0].text : null
        if (this.selectors.$selectMark.select2(
            'data').length) model_id = this.selectors.$selectMark.select2(
            'data')[0].id !== '' ? this.selectors.$selectMark.select2(
            'data')[0].element['dataset']['id'] : null

        let generation_val = null
        let generation_id = null

        if (this.selectors.$selectGeneration.select2(
            'data').length) generation_val = this.selectors.$selectGeneration.select2(
            'data')[0].id !== '' ? this.selectors.$selectGeneration.select2(
            'data')[0].text : null
        if (this.selectors.$selectModel.select2(
            'data').length) generation_id = this.selectors.$selectModel.select2(
            'data')[0].id !== '' ? this.selectors.$selectModel.select2(
            'data')[0].element['dataset']['id'] : null

        let serie_val = null
        let serie_id = null

        if (this.selectors.$selectSerie.select2(
            'data').length) serie_val = this.selectors.$selectSerie.select2(
            'data')[0].id !== '' ? this.selectors.$selectSerie.select2(
            'data')[0].text : null
        if (this.selectors.$selectGeneration.select2(
            'data').length) serie_id = this.selectors.$selectGeneration.select2(
            'data')[0].id !== '' ? this.selectors.$selectGeneration.select2(
            'data')[0].element['dataset']['id'] : null

        let data = []

        if (mark_val) data.push(
            {name: 'Марка', value: mark_val, parent_id: mark_id})
        if (model_val) data.push(
            {name: 'Модель', value: model_val, parent_id: model_id})
        if (generation_val) data.push(
            {name: 'Поколение', value: generation_val, parent_id: generation_id})
        if (serie_val) data.push(
            {name: 'Кузов', value: serie_val, parent_id: serie_id})

        return data
    }
    
    fillChars(autodonor) {
        if (autodonor.mark !== null) {
            this.getMark(JSON.parse(autodonor.mark).value).then(() => {
                this.markEventOn();
                if (autodonor.model == null) this.getModel()
            })
        } else {
            this.markEventOn();
        }

        if (autodonor.model !== null) {
            this.getModel(JSON.parse(autodonor.model).value, JSON.parse(autodonor.model).parent_id).then(() => {
                this.modelEventOn();
                if (autodonor.generation == null) this.getGeneration()
            })
        } else {
            this.modelEventOn();
        }

        if (autodonor.generation !== null) {
            this.getGeneration(JSON.parse(autodonor.generation).value, JSON.parse(autodonor.generation).parent_id).then(() => {
                this.generationEventOn();
                if (autodonor.serie == null) this.getSerie()
            })
        } else {
            this.generationEventOn();
        }

        if (autodonor.serie !== null) this.getSerie(JSON.parse(autodonor.serie).value, JSON.parse(autodonor.serie).parent_id)
    }

    eventsHundlerTrigger() 
    {
        const id = this.selectors.modalAu.querySelector('input[name=\'data[id]\']').value
        if (!id) return // работает только на редактировании автодоноров
        if (this.selectors.selectModel?.selectedOptions[0]?.value === '') {
            this.getModel().then(() => {
                this.getGeneration().then(() => {
                    this.getSerie()
                })
            })
        }
        if (this.selectors.selectGeneration?.selectedOptions[0]?.value === '') {
            this.getGeneration().then(() => {
                this.getSerie()
            })
        }
        if (this.selectors.selectSerie?.selectedOptions[0]?.value === '') {
            this.getSerie()
        }
    }
}