import Module from '../base/module';


export default class TiuProductGroups extends Module{
 
  init() {
    this.data = {
      rootId: 2881645,
      selectedIds: [],
    };
    
    this.selectors.List = document.querySelector('#tiu_products-groups'); 
    this.selectors.Sidebar = document.querySelector('#tiu_products-sidebar');
    this.selectors.GroupList = this.selectors.Sidebar.querySelector('#tiu_products-groups');
    this.selectors.newGroupList = this.selectors.Sidebar.querySelector('#new_tiu_products-groups');
    this.selectors.renameGroupButton = this.selectors.Sidebar.querySelector('.rename-group');
    this.selectors.renameGroupName = document.querySelector('#renameGroupName');
    this.selectors.deleteButton = this.selectors.Sidebar.querySelector('.delete-group');
    this.selectors.modalGroupRenameName = this.selectors.renameGroupName.querySelector('.modalGroupRenameName');
    /* document.querySelectorAll('.tiuProductsGroups-list__item__edit-btn')[0].style.display = 'none';*/
    
    this.getTpl = {
      item: (data) => this.app.getTemplate('tiuProductsGroupsListItem', data),
      new_item: (data) => this.app.getTemplate('newTiuProductsGroupsListItem', data),
    };
    
  }
  
  run() {

  }
  
  initGroups(){
    this.#initListeners();
    this.initRenameGroup();
    this.initDeleteGroup();
    this.fetchList();
  }

  initDeleteGroup()
  {
    this.selectors.deleteButton.addEventListener('click', (e) => {
      e.preventDefault();
      const groupId = this.selectors.GroupList.querySelector('.selected[data-id]').dataset.id;
      this.app.modules.TiuProductGroups.handleDelete(groupId)
    })
  }
  
  #handleClick(e) {
    e.preventDefault();
    const El = e.target.closest('li');
    if (!El || !e.target.closest('.exp')) return;
    El.classList.toggle('opened');
  }

  async #handleSelect(e) {
    e.preventDefault();
    const El = e.target.closest('li');
    if (!El || e.target.closest('.exp')) return;
    const id = Number(El.dataset['id']);
    if (!id) return;
    let action = null;
    if (e.ctrlKey || e.metaKey) {
      action = this.data.selectedIds.includes(id) ? 'remove' : 'append';
    } else {
      action = 'add';
    }
    switch (action) {
      case 'add':
        this.data.selectedIds = [id];
        break;
      case 'append':
        this.data.selectedIds = [...this.data.selectedIds, id];
        break;
      case 'remove':
        this.data.selectedIds = this.data.selectedIds.filter(i => i !== id);
        El.classList.remove('selected');
    }

    Array.from(this.selectors.List.querySelectorAll('li')).map((El) => {
      const _id = Number(El.dataset['id']);
      if (!_id || !this.data.selectedIds.includes(_id)) {
        El.classList.remove('selected');
      } else {
        El.classList.add('selected');
      }
    }); 
    this.app.modules.TiuProduct.fetchProducts();
    $(window).scrollTop(1); 
  } 

  #initListeners() {
    this.selectors.List.addEventListener('click', this.#handleClick.bind(this));
    this.selectors.List.addEventListener('click', this.#handleSelect.bind(this));
  }

  getNameById(id) {
    return this.selectors.List.querySelector(`li[data-id="${id}"] > span`)?.textContent.trim().replaceAll(/\s+/g, ' ');
  }

  async handleMove(sourceId, parentId) {
    sourceId = Number(sourceId);
    parentId = Number(parentId);
    if (sourceId === parentId) return M.toast({html: 'Нельзя перемещать группу в себя же'});
    if (!sourceId || !parentId) return M.toast({html: 'Не удалось переместить группу'});
    if (sourceId === 2881645) return M.toast({html: 'Нельзя перемещать корневую группу'});

    const targetName = this.getNameById(sourceId);
    const parentName = this.getNameById(parentId);

    if (confirm(`Вы уверены, что хотите переместить группу "${targetName}" в группу "${parentName}"`)) {
      const res = await this.app.fetch('Group', 'move', {id: sourceId, parent: parentId});
      if (!res.status) return M.toast({html: res.message || 'Произошла ошибка, повторите попытку'});
      this.fetchList();
    }
  }

  async handleDelete(targetId) {
    if (!targetId) return M.toast({html: 'Произошла ошибка'});
    const targetName = this.getNameById(targetId);
    if (targetId === 2881645) console.log('нельзя')
    if (targetId === 2881645) return M.toast({html: 'Нельзя удалять корневую группу'});
    if (confirm(`Вы уверены, что хотите удалить группу "${targetName}"`)) {
      const res = await this.app.fetch('Group', 'delete', {id: targetId, groupName: targetName});
      if (!res.status) return M.toast({html: res.message || 'Произошла ошибка, повторите попытку'});
      this.fetchList();
      M.toast({html: res.message});
    }
  }

  #renderParent(data, parent = null) {
    const items = data.reduce((r, c) => {
      if (null === parent && c.parent === null || Number(parent) === Number(c.parent)) {
        return [...r, c];
      }

      return r;
    }, []);

    if (!items?.length) return '';

    const res = items.map(item => {
      const children = this.#renderParent(data, item.id);

      return this.getTpl.item({
        ...item,
        class: children === '' ? 'class="last"' : '',
        atts: Number(item.id) !== 2881645 ? 'draggable="true"' : '',
        name: Number(item.id) === 2881645 ? 'Все товары' : item.name,
        children,
      });
    }).join('');

    if (parent === null) {
      this.selectors.List.innerHTML = '';
      this.selectors.List.insertAdjacentHTML('beforeend', res);
      Array.from(this.selectors.List.children).map(el => {
        el.style.maxHeight = 'auto';
      });
      return;
    }

    return `<ul>${res}</ul>`;
  }

  async fetchList() {
    this.data.selectedId = null;
    const openedIds = [
      2881645,
      ...Array.from(this.selectors.List.querySelectorAll('li.opened')).map(i => Number(i.dataset['id']))];
    
    const res = await this.app.fetch('Group', 'getAll');
    if (res.status && res?.data?.items?.length) {
      this.#renderParent(res.data.items);
      Array.from(this.selectors.List.querySelectorAll('li'))
      .map(i => openedIds.includes(Number(i.dataset['id'])) && i.classList.add('opened'));
      // $('.preloader-bg').fadeOut('fast'); 
    } else { 
      M.Toast({html: 'Не удалось получить группы'});
    }
  }

  getSelectedIds() {
    return this.data.selectedIds;
  }

  #getGroupId(e) {
    let el = e.target.closest('li');
    if (!el) return null;
    let id = el.dataset['id'];
    return id ?
        parseInt(id) :
        null;
  }

  initRenameGroup() {
    this.selectors.renameGroupButton.addEventListener('click', async (e) => {
      const groupId = this.getSelectedIds();
      if (!groupId.length) {
            e.preventDefault();
            M.toast({html: 'Выберите группу для редактирования названия'});
            return;
      } else if(groupId[0] === this.data.rootId){
            e.preventDefault(); 
            M.toast({html: 'Название корневой группы редактировать нельзя'});
            return;
      } else { 
            this.selectors.modalGroupRenameName.innerHTML = this.getNameById(groupId[0]);
            const form = this.selectors.renameGroupName.querySelector('form');
            let nameInput = this.selectors.renameGroupName.querySelector('input[name="name"]');
            const saveBtn = this.selectors.renameGroupName.querySelector('.save');
            
            let Modal = M.Modal.init(this.selectors.renameGroupName, {
                onOpenEnd: () => nameInput.focus(),
                onCloseEnd: () => form.reset(),
            });
   
            let resGroupName = await this.app.fetch('Group', 'getOne', {id: groupId[0]});
            nameInput.value = resGroupName.data.item.name;
   
            const renameGroupHandler = async (e) => {
                    e.preventDefault();
                    if (!form.checkValidity()) return;
                    const res = await this.app.fetch('Group', 'rename', {name: nameInput.value, id: groupId[0]});
                    if (res.status) {
                          M.toast({html: res.message});
                          Modal.close();
                          this.app.modules.TiuProductGroups.fetchList(false);
                    } else {
                          M.toast({html: res.message || 'Произошла ошибка'});
                    }
            };
            
            form.addEventListener('submit', renameGroupHandler);
            saveBtn.addEventListener('click', renameGroupHandler);
      }
    });
  }
  
  
}
