import Module from './../base/module';
import {debounce} from 'throttle-debounce';


export default class AutodonorsList extends Module {
    init() {
         this.selectors.ScreenTab = document.querySelector('#autodonors_screen_tab');
         this.selectors.Screen = document.querySelector('#autodonors-screen');
         this.selectors.List = this.selectors.Screen.querySelector('#autodonors-list');
         this.selectors.tplListItem = 'autodonors-list__item';
         this.selectors.listItem = '.autodonors-list__item';
         this.selectors.Total = this.selectors.Screen.querySelector('#autodonors-list_total');
         this.selectors.Gallery = this.selectors.Screen.querySelector('#autodonors-gallery');
         this.selectors.clearSelected = this.selectors.Screen.querySelector('.autodonors-clearSelected');
         this.selectors.autodonorDelete = this.selectors.Screen.querySelector('.autodonors-delete');
         this.selectors.$headPanel = $('#autodonors-screen .head-panel');
         this.selectors.countSelected = this.selectors.Screen.querySelector('.countSelected');
         
         this.data = {
            autodonors: [],
            total: null,
         };

         this.listeners();
    }

    async run() {
        // this.selectors.List.querySelector('tbody').innerHTML = this.app.preloader;
        await this.fetchAutodonors()
        this.checkedAutodonorsHandler();
    }

    async fetchAutodonors() {
        this.resetList();
        await this.fetchList();
        this.fillList();
        this.checkedAutodonorsHandler();
    }

    listeners() {
        
        if (sessionStorage.getItem('currentTabPage') === 'autodonors_screen_tab') {
             this.fetchAutodonors();
         } 

        this.selectors.autodonorDelete.addEventListener('click', () => {
            const checkedAutodonors = sessionStorage.getItem('CheckAllAutodonors') !== null && sessionStorage.getItem('CheckAllAutodonors') === 'true' ? 'all' : this.getCheckedIds();
            this.handleDelete(checkedAutodonors);
        });
        
        this.selectors.clearSelected.addEventListener('click', () => {
            this.uncheckAll();
            this.checkedAutodonorsHandler();
        });

        window.addEventListener('scroll', debounce(100, async () => {
            if (!this.activeScreen() || this.selectors.Screen.scrollHeight <= window.innerHeight || this.data.isLoad) return;
            if (window.scrollY + window.innerHeight >= this.selectors.Screen.scrollHeight - window.innerHeight / 4) {
                await this.fetchList();
                this.fillList();
                this.checkedAutodonorsHandler();
            }
        }));
        
        this.initCheckAll();
        this.initGallery();
        
        this.selectors.List.addEventListener('click', () => {
            this.checkedAutodonorsHandler();
        });
    }

    checkedAutodonorsHandler(){
        if(sessionStorage.getItem('CheckAllAutodonors') === null) sessionStorage.setItem('CheckAllAutodonors', 'false');
        this.#getAllItems().map((El) => {
            let prevCheckedIds = [];
            const makeUniq = (arr) => [...new Set(arr)];
            if(sessionStorage.getItem('allCheckedAutodonorsIds')) prevCheckedIds = sessionStorage.getItem('allCheckedAutodonorsIds').split(',');
            if(sessionStorage.getItem('CheckAllAutodonors') !== null && sessionStorage.getItem('CheckAllAutodonors') === 'false'){
                if(El.querySelector('input[type="checkbox"]').checked === true){
                    El.classList.add('bg_yellow');
                    prevCheckedIds.push(El.dataset['id']);
                    prevCheckedIds = makeUniq(prevCheckedIds);
                    sessionStorage.setItem('allCheckedAutodonorsIds', prevCheckedIds);
                } else {
                    El.classList.remove('bg_yellow');
                    if(prevCheckedIds){
                        prevCheckedIds = makeUniq(prevCheckedIds);
                        prevCheckedIds = prevCheckedIds.filter(e => e !== El.dataset['id']);
                        sessionStorage.setItem('allCheckedAutodonorsIds', prevCheckedIds);
                    }
                }
            }
            if(sessionStorage.getItem('CheckAllAutodonors') !== null && sessionStorage.getItem('CheckAllAutodonors') === 'true'){
                El.querySelector('input[type="checkbox"]').checked = true;
                El.classList.add('bg_yellow');
            }
        });

        if(sessionStorage.getItem('CheckAllAutodonors') !== null && sessionStorage.getItem('CheckAllAutodonors') === 'false'){
            this.selectors.countSelected.innerHTML = sessionStorage.getItem('allCheckedAutodonorsIds') ? sessionStorage.getItem('allCheckedAutodonorsIds').split(',').length + '  тов. выбрано' : '0 тов. выбрано';
        } else {
            this.selectors.countSelected.innerHTML = this.data.total + '  тов. выбрано';
        }
        sessionStorage.getItem('allCheckedAutodonorsIds') || (sessionStorage.getItem('CheckAllAutodonors') !== null && sessionStorage.getItem('CheckAllAutodonors') === 'true') ? this.selectors.$headPanel.fadeIn('fast') : this.selectors.$headPanel.fadeOut('fast');
    }
    
    activeScreen = () => this.selectors.Screen.classList.contains('active');
    
    #lockScreen = () => this.selectors.Screen.classList.add('disabled');
    
    #unlockScreen = () => this.selectors.Screen.classList.remove('disabled');
    
    getAutodonorsById = (id) => this.data.autodonors.filter(({id: _id}) => parseInt(_id) === parseInt(id))?.shift() || null;
    
    resetList() {
        document.querySelector('#autodonors-screen .check-all-autodonors').checked = sessionStorage.getItem('CheckAllAutodonors') !== null && sessionStorage.getItem('CheckAllAutodonors') === 'true';
        this.data.autodonors = [];
        this.uncheckAll();  
        if(this.selectors.List) this.selectors.List.querySelector('tbody').innerHTML = '';
    }

    #setTotal(num) {
        this.data.total = isNaN(num) ? null : Number(num);
        this.selectors.Total.textContent = null === num || isNaN(num) ? '' : `${num}`;
        this.selectors.countSelected.innerHTML = null === num || isNaN(num) ? '' : `${num}`; 
    }

    timeConverter(UNIX_timestamp){
        const a = new Date(UNIX_timestamp * 1000);
        const date = a.toLocaleDateString([], {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
        });
        const time = a.toLocaleTimeString([],{
            hour: "2-digit",
            minute: "2-digit",
        });
        return date+' '+time;
    }

    async fetchList() {
        if (this.data.isLoad) return;
        this.data.isLoad = true;

        let fields = ['id', 'last_update', 'number', 'photos', 'mark', 'model', 'generation', 'serie', 'color', 'status', 'price', 'steerwheel', 'drivetype', 'vin', 'mileage', 'notes', 'desc', 'video'];
        
        try{
            await this.app.fetch('Autodonor', 'getAll', {
                fields: fields,
                orderby: this.app.modules.AutodonorsFilter.getOrderByValues(),
                offset: this.data.autodonors.length,
                filters: this.app.modules.AutodonorsFilter.getValues(),
            })
                .then((data) => {
                    this.#setTotal(data.data.total);
                    
                    const autodonors = data.data.autodonors.map(autodonor => ({
                        ...autodonor,
                        number: autodonor.number ? autodonor.number : '&mdash;',
                        color: autodonor.color ? autodonor.color : '&mdash;',
                        price: autodonor.price ? autodonor.price : '&mdash;',
                        vin: autodonor.vin ? autodonor.vin : '&mdash;',
                        mileage: autodonor.mileage ? autodonor.mileage : '&mdash;',
                        
                        mark: (() => {
                            return autodonor.mark ? JSON.parse(autodonor.mark).value : '&mdash;';
                        })(),
                        model: (() => {
                            return autodonor.model ? JSON.parse(autodonor.model).value : '&mdash;';
                        })(),
                        generation: (() => {
                            return autodonor.generation ? JSON.parse(autodonor.generation).value : '&mdash;';
                        })(),
                        serie: (() => {
                            return autodonor.serie ? JSON.parse(autodonor.serie).value : '&mdash;';
                        })(),
                        status: (() => {
                            if(autodonor.status == 'moscow'){
                                return 'В Москве';
                            }
                            if(autodonor.status == 'japan'){
                                return 'В Японии';
                            }
                            return '&mdash;';
                        })(),
                        steerwheel: (() => {
                            if(autodonor.steerwheel == 'left'){
                                return 'Левый';
                            }
                            if(autodonor.steerwheel == 'right'){ 
                                return 'Правый';
                            }
                            return '&mdash;';
                        })(),
                        drivetype: (() => {
                            if(autodonor.drivetype == 'front-wheel'){
                                return 'Передний';
                            }     
                            if(autodonor.drivetype == 'rear-wheel'){
                                return 'Задний';
                            }
                            if(autodonor.drivetype == 'four-wheel'){
                                return 'Полный';
                            }
                            return '&mdash;';
                        })(),
                        thumb: (() => {
                            if (Array.isArray(autodonor.thumbs) && autodonor.thumbs.length > 0) {
                                return autodonor.thumbs[0];
                            } else {
                                return 'https://' + document.domain + '/data/zaglushka_50_50.png';
                            }
                        })(),
                        last_update: this.timeConverter(autodonor.last_update),
                    }));

                    this.data.autodonors = [...this.data.autodonors, ...autodonors];

                    this.data.isLoad = false;
                });
        } catch (err){
            console.error(err)
        }
    }

    fillList() {
        const TBody = this.selectors.List.querySelector('tbody');
        this.data.autodonors.map(autodonor => {
            if (TBody.querySelector(`tr[data-id="${autodonor.id}"]`)) return;

            TBody.insertAdjacentHTML(
                'beforeend',
                this.app.getTemplate(this.selectors.tplListItem, autodonor),
            );
        });

        if (sessionStorage.getItem('CheckAllAutodonors') === 'true') this.checkAll(); 
        const prevCheckedIds = sessionStorage.getItem('allCheckedAutodonorsIds') ? sessionStorage.getItem('allCheckedAutodonorsIds').split(',') : null;

        this.#getAllItems().map((El) => {
            if(sessionStorage.getItem('CheckAllAutodonors') !== null && sessionStorage.getItem('CheckAllAutodonors') === 'false'){
                if(prevCheckedIds !==null){
                    if(prevCheckedIds.includes(El.dataset['id'])){
                        El.classList.add('bg_yellow');
                        El.querySelector('input[type="checkbox"]').checked = true;
                    } else {
                        El.classList.remove('bg_yellow');
                        El.querySelector('input[type="checkbox"]').checked = false;
                    }
                }
            }
            if(sessionStorage.getItem('CheckAllAutodonors') !== null && sessionStorage.getItem('CheckAllAutodonors') === 'true'){
                El.classList.add('bg_yellow');
                El.querySelector('input[type="checkbox"]').checked = true;
            }
        });
    }
    
    #getAllItems = () => Array.from(document.querySelector('#autodonors-screen #autodonors-list').querySelectorAll(this.selectors.listItem));
    
    #getListItem = id => document.querySelector('#autodonors-screen #autodonors-list').querySelector(`${this.selectors.listItem}[data-id="${id}"]`);
 
    #getEventId(e) {
        const el = e?.target?.closest('tr');
        if (!el) return null;
        const id = el.dataset['id'];
        return id ?
            parseInt(id) :
            null;
    }
    
    setChecked(id) {
        const Item = this.#getListItem(id);
        const Checkbox = Item.querySelector('.image input');
        if (!Item || !Checkbox) return;
        Checkbox.checked = true;
    }

    initChangePresence() {
        let prevChecked = null;

        // Выделение ренджа товаров по шифту
        const checkBatchHandle = (prev, current) => {
            const List = this.selectors.List;
            const AutodonorList = Array.from(List.querySelector('tbody').children);

            const getIdx = id => {
                const El = List.querySelector(`tr[data-id="${id}"]`);
                return AutodonorList.indexOf(El);
            };

            const toggleByIdx = (idx) => {
                const AutodonorEl = AutodonorList[idx];
                const Checkbox = AutodonorEl?.querySelector('label.check input');
                if (!Checkbox) return;
                Checkbox.checked = !Checkbox.checked;
            };

            const getRange = (start, end) => {
                const dir = end > start;
                const max = Math.max(start, end) - 1;
                const min = Math.min(start, end) + 1;
                const length = (dir ? end - start : start - end);

                const res = Array.from({length}, (v, k) => dir ? min + k : max - k);

                return res
            };

            const prevIdx = getIdx(prev);
            const curIdx = getIdx(current);

            getRange(prevIdx, curIdx).map(toggleByIdx);
        };

        this.selectors.List.addEventListener('click', (e) => {
            if (!e.target.closest('tbody')) return;
            const id = this.#getEventId(e);
            
            // Клик по чекбоксу выделения товара
            if (id && e.target.closest('label.check')) {
                if (e.target.tagName !== 'SPAN') return;
                if (e.shiftKey && prevChecked) checkBatchHandle(prevChecked, id)
                prevChecked = id;
            }
        }, false);
    }
    
    
    //собирает только на странице, на которой мы находимся 
    getCheckedIds() {
        return sessionStorage.getItem('allCheckedAutodonorsIds') ? sessionStorage.getItem('allCheckedAutodonorsIds').split(',') : '';

        // return this.#getAllItems().map(El => {
        //     if (!El.querySelector('input[type="checkbox"]').checked) return null;
        //     return parseInt(El.dataset['id']);
        // }).filter(i => null !== i && undefined !== i && !isNaN(i));
    }

    checkAll() {
        this.#getAllItems().map(El => {
            El.querySelector('input[type="checkbox"]').checked = true;
        });
        sessionStorage.setItem('CheckAllAutodonors', true);
        sessionStorage.removeItem('allCheckedAutodonorsIds');
    }

    uncheckAll() {
        document.querySelector('#autodonors-screen .check-all-autodonors').checked = false;
        sessionStorage.setItem('CheckAllAutodonors', false);
        this.#getAllItems().map(El => {
            El.querySelector('input[type="checkbox"]').checked = false;
        });
        sessionStorage.removeItem('allCheckedAutodonorsIds');
    }

    initCheckAll() {
        document.querySelector('#autodonors-screen .check-all-autodonors').addEventListener('change', (e) => {
            sessionStorage.getItem('CheckAllAutodonors') !== null && sessionStorage.getItem('CheckAllAutodonors') === 'true' ? this.uncheckAll() : this.checkAll();
            this.checkedAutodonorsHandler();
        });
    }

    async cancelableCallback(callback) {
        let canceled = false;

        const proceed = async () => {
            !canceled && await callback();
            this.#unlockScreen();
        };

        this.#lockScreen();

        const toast = M.toast({
            html: '<span>Отменить изменение</span><button class="btn-flat toast-action">Отменить</button>',
            displayLength: 10000,
            completeCallback: proceed,
        });

        toast.el.querySelector('button').addEventListener('click', () => {
            canceled = true;
            proceed();
            toast.dismiss();
        });
    }

    async handleDelete(ids) {
        let params = {ids};
        let msg;

        if (ids === 'all') {
            msg = 'Вы уверены, что хотите удалить ВСЕ отфильтрованные автодоноры?';
            params = {...params, filters: this.app.modules.AutodonorsFilter.getValues()};
        } else {
            msg = `Вы уверены, что хотите удалить ${ids.length} ${this.app.decl(ids.length, ['автодонор', 'автодонора', 'автодоноров'])}`;
        }

        let pass = prompt('Введите секретный ключ');
        if (pass !== '701735') return M.toast({html: 'Неверный секретный ключ'});

        const callback = async () => {
            const res = await this.app.fetch('Autodonor', 'delete', params);
            if (res.status) {
                this.fetchAutodonors();
                this.uncheckAll();
                this.checkedAutodonorsHandler();
            } else {
                M.toast({html: res.message || 'Произошла ошибка'});
            }
        };

        (ids === 'all' || ids.length > 0) && confirm(msg) && await this.cancelableCallback(callback);
    }

    #showGallery = (pos = null, images = null) => {
        const el = this.selectors.Gallery;
        const inner = el.querySelector('.inner');
        if (!el || !inner) return;

        if (Array.isArray(images)) {
            inner.innerHTML = '';
            inner.insertAdjacentHTML(
                'beforeend',
                images.map((image, index) => `<a><img loading="lazy" src="${image.thumb}" data-index="${index}" width="150" height="150"></a>`).join('')
            ); 
        }

        if (null !== pos && typeof pos === 'string') el.style.top = pos;

        el.classList.add('show');

        let GalleryImages = this.app.modules.Gallery.prepareTiuImagesForGallery(images);

        this.selectors.Gallery.querySelectorAll('.inner img').forEach((e) => {
            e.addEventListener('click', () => this.app.modules.Gallery.open(GalleryImages, e.getAttribute('data-index')));
        })
        
        
    };

    #hideGallery(destroy = false) {
        const el = this.selectors.Gallery;
        const inner = el.querySelector('.inner');

        el.classList.remove('show');

        if (destroy) {
            inner.innerHTML = '';
            el.style.top = null;
        }
    }

    initGallery() {
        const Gallery = this.selectors.Gallery;
        const GalleryInner = Gallery.querySelector('.inner');
        let currentAutodonorId;

        const onImage = (e) => e.target.closest('td.image') && e.target.closest('img');
        const getAutodonor = (e) => {
            const el = e.target.closest('.autodonors-list__item');
            if (!el) return null;
            return this.getAutodonorsById(el.dataset['id']);
        };

        const handleOver = (e) => {
            const autodonor = getAutodonor(e);
            if (!onImage(e) || !autodonor) return;

            currentAutodonorId = autodonor.id;
            const autodonorEl = this.selectors.List.querySelector(`.autodonors-list__item[data-id="${autodonor.id}"]`);
            if (!autodonorEl) return;
            
            if (!autodonor.thumb || autodonor.thumb?.length <= 0) return;
            const images = autodonor.small.map((thumb, index) => ({src: autodonor.photos[index], thumb}))
            this.#showGallery(`${ autodonorEl.offsetTop}px`, images);
        };

        this.selectors.List.addEventListener('mouseover', handleOver);
        this.selectors.List.addEventListener('mouseout', () => this.#hideGallery());
        this.selectors.Gallery.addEventListener('mouseenter', () => this.#showGallery());
        this.selectors.Gallery.addEventListener('mouseleave', () => this.#hideGallery());
    }
    
}
