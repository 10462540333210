import Module from "./../base/module";
import {debounce} from "throttle-debounce";

export default class Autodonors extends Module {
    init() {
        this.initSelectors();
    }

    initSelectors() {
        this.selectors.screen = '#modalProduct';
        this.selectors._screen = document.querySelector(this.selectors.screen);
        
        this.selectors.list = '.autodonor-list';
        this.selectors.tplListItem = 'autodonor-list__item';
        this.selectors.listItem = '.autodonor-list__item';
        
        this.selectors.modal = '#modalProductAutodonor';
        this.selectors.$modal = $('#modalProductAutodonor');
        this.selectors.createBtn = '#add_autodonor_to_product';
        this.selectors.resetBtn = '.autodonor_reset';
        this.selectors.autodonorsTable = `${this.selectors.modal} .autodonorsTable`;
        this.selectors.autodonorItem = `${this.selectors.autodonorsTable} .autodonor__item`;
        this.selectors.autodonorItemSelect = '.select';

        this.selectors.$input = $("[name='data[autodonor_id]']");

        this.selectors.$button = $(`a[href="#${this.selectors.$modal.attr('id')}"]`);
        this.selectors.$button1 = $(`a[href="#${this.selectors.$modal.attr('id')}"]`).not(`#modalEditProducts a[href="#${this.selectors.$modal.attr('id')}"]`);
        this.selectors.$button2 = $(`#modalEditProducts a[href="#${this.selectors.$modal.attr('id')}"]`);
        
        this.selectors.modalSearch = `#autodonorSearch`;
        this.selectors.modal_close = `${this.selectors.modal} .modal-close`;
        
        this.selectors.buttonText = `Выбрать автодонора`;
        this.selectors.buttonText1 = `Выбрать автодонора`;
        this.selectors.buttonIcon = `<i class="material-icons in-text">directions_car</i>`;
    }

    run() {
        this.data.modal = M.Modal.init($(this.selectors.modal))[0];
        this.listeners();
    }

    
    listeners() {
        $(this.selectors.createBtn).on('click', this.add.bind(this));
        $(this.selectors.resetBtn).on('click', ()=>{
            this.reset();
            this.close();
        });
        $(this.selectors.modal_close).on('click', this.close.bind(this));
        
        $(this.selectors.modalSearch).on('keydown', debounce(500, () => this.search()).bind(this));
        
        $(this.selectors.autodonorsTable).on('click', this.selectors.autodonorItemSelect, e => {
            this.checkHandler(e);
        });
    }
    
    
    reset(){
        this.clear();
        this.selectors.$input.val('');
        this.selectors.$button1.html(this.selectors.buttonText + ' ' + this.selectors.buttonIcon);
        this.selectors.$button2.html(this.selectors.buttonText1 + ' ' + this.selectors.buttonIcon);
    }

    async setAutodonor(id = null, name = null) {
        const text = $(this.selectors.autodonorItem) ? $(this.selectors.autodonorItem + '.added').find('.number').text() : null;
        if(!name && !text && !id) return;
        
        const autodonor = await this.app.modules.AutodonorEdit.fetchOne(id, null, ['photos']);
        
        $(this.selectors.autodonorsTable).find('tbody').html('');
        this.selectors.$input.val('');
        
        console.log(autodonor.photos[0])
        
        $(this.selectors.autodonorsTable).find('tbody').prepend(`
                <tr class="autodonor__item added bg_yellow" data-id="${autodonor.id}"> 
                    <td>
                        <label>
                            <input class="select" type="checkbox">
                            <span></span>
                        </label>
                    </td>
                    <td><img loading="lazy" src="${(() => {
                        if (Array.isArray(autodonor.photos) && autodonor.photos.length > 0) {
                            return autodonor.photos[0].thumb; 
                        } else {
                            return 'https://' + document.domain + '/data/zaglushka_50_50.png';
                        }
                    })()}" width="50" height="50"></td>
                    <td class="number">${autodonor.number}</td>
                    <td>${autodonor.mark ? JSON.parse(autodonor.mark).value : '&mdash;'}</td>
                    <td>${autodonor.model ? JSON.parse(autodonor.model).value : '&mdash;'}</td>
                    <td>${autodonor.generation ? JSON.parse(autodonor.generation).value : '&mdash;'}</td>
                    <td>${autodonor.serie ? JSON.parse(autodonor.serie).value : '&mdash;'}</td>
                    <td>
                        ${(() => {
                            if(autodonor.drivetype === 'front-wheel') return 'Передний';
                            if(autodonor.drivetype === 'rear-wheel') return 'Задний';
                            if(autodonor.drivetype === 'four-wheel') return 'Полный';
                            return '&mdash;';
                        })()}
                    </td>
                    <td>
                         ${(() => {
                            if(autodonor.steerwheel === 'left') return 'Левый';
                            if(autodonor.steerwheel === 'right') return 'Правый';
                            return '&mdash;';
                        })()}
                    </td>
                </tr>  
        `);

        $(this.selectors.autodonorsTable).find('tbody').find(`tr[data-id="${autodonor.id}"]`).find(this.selectors.autodonorItemSelect).prop('checked', true);
        
        this.selectors.$button.html(`Выбрано: "${name ? name : text}"`);
        this.selectors.$input.val(id);
    }
    
    addAutodonor(id = null, name = null){
        const text = $(this.selectors.autodonorItem) ? $(this.selectors.autodonorItem + '.added').find('.number').text() : null;
        if(!name && !text && !id) return;
        this.selectors.$button.html(`Выбрано: "${name ? name : text}"`);
        this.selectors.$input.val(id);
    }

    close() {
        this.data.modal.close();
    }

    clear() {
        let $checkboxes = $(this.selectors.modal).find(this.selectors.autodonorItemSelect);
        if ($checkboxes.length) {
            $checkboxes.each((idx, el) => {
                if ($(el).prop('checked')) $(el).trigger('click');
            });
        }
        $(this.selectors.modalSearch).val('');
        $(this.selectors.autodonorsTable).find('tbody').html('');
    }
    
    async add() {
        if (this.getSelectedId().length === 0 || this.getSelectedId()== null) return M.toast({html: 'Выберите автодонор!'});
        this.addAutodonor(this.getSelectedId());
        this.close();
    }
    
    getSelectedId() {
        return parseInt($(this.selectors.autodonorItem + '.added').data('id')) || null;
    }

    checkHandler(e) {
        let $checkboxes = $(this.selectors.modal).find(this.selectors.autodonorItemSelect);
        if ($checkboxes.length) {
            $checkboxes.each((idx, el) => {
                if ($(el).prop('checked') && $(el)[0] !== $(e.target)[0]){
                    $(el).prop('checked',false);
                    $(el).closest('tr').removeClass('bg_yellow');
                    $(el).closest('tr').removeClass('added');
                }
            });
        }
        if ($(e.target).prop('checked')){
            $(e.target).closest('tr').addClass('added');
            $(e.target).closest('tr').addClass('bg_yellow');
        } else {
            $(e.target).closest('tr').removeClass('added');
            $(e.target).closest('tr').removeClass('bg_yellow');
        }
    }

    
    search() {
        let q = $(this.selectors.modalSearch).val().trim();

        let $autodonors = $(this.selectors.autodonorsTable).find('tbody');

        $autodonors.find('tr').each((idx, el) => {
            let $el = $(el);
            if (!$el.find(this.selectors.autodonorItemSelect).prop('checked')) $el.remove();
        });

        if (q.length <= 1) return;

        let fields = ['id', 'last_update', 'number', 'photos', 'mark', 'model', 'generation', 'serie', 'color', 'status', 'price', 'steerwheel', 'drivetype', 'vin', 'mileage', 'notes', 'desc', 'video']; 
        
        this.app.doAjax('Autodonor', 'search', {fields: fields, filters: {name:q}}, data => {
            if (!data.status) return;
            
            data.data.autodonors.forEach(el => {

                if ($autodonors.find(`tr[data-id="${el.id}"]`).length) return;
                
                $autodonors.append(`
                <tr class="autodonor__item" data-id="${el.id}"> 
                    <td>
                        <label>
                            <input class="select" type="checkbox">
                            <span></span>
                        </label>
                    </td>
                    <td><img loading="lazy" src="${(() => {
                            if (Array.isArray(el.thumbs) && el.thumbs.length > 0) {
                                return el.thumbs[0];
                            } else {
                                return 'https://' + document.domain + '/data/zaglushka_50_50.png';
                            }
                        })()}" width="50" height="50"></td>
                    <td class="number">${el.number}</td>
                    <td>${el.mark ? JSON.parse(el.mark).value : '&mdash;'}</td>
                    <td>${el.model ? JSON.parse(el.model).value : '&mdash;'}</td>
                    <td>${el.generation ? JSON.parse(el.generation).value : '&mdash;'}</td>
                    <td>${el.serie ? JSON.parse(el.serie).value : '&mdash;'}</td>                
                     <td>
                        ${(() => {
                            if(el.drivetype === 'front-wheel') return 'Передний';
                            if(el.drivetype === 'rear-wheel') return 'Задний';
                            if(el.drivetype === 'four-wheel') return 'Полный';
                            return '&mdash;';
                         })()}
                    </td>
                    <td>
                        ${(() => {
                            if(el.steerwheel === 'left') return 'Левый';
                            if(el.steerwheel === 'right') return 'Правый';
                            return '&mdash;';
                        })()}
                    </td>
                </tr>  
                `);
            });
        });
    }
    
}
