import Module from './base/module';
import App from "./base/app";


export default class Auth extends Module{

  init() {
    this.selectors.$modal = $('#modalLogin');
  }

  run() { 
        this.setup();
        this.listeners();
        this.ping();
  }  

  setup() {
    this.selectors.$modal.modal({
      dismissible: false,
    });
  }
 
  listeners() {
    $(document.body).on('validation-failed', (e) => {
      $(document.body).removeClass('logged');
      this.selectors.$modal.modal('open');
    });

    $(document.body).on('validation-success', async (e) => {
      $(document.body).addClass('logged');
      this.selectors.$modal.modal('close');
      
      await this.app.modules.Product.fetchAll();
 
      this.app.modules.Product.fillProducts();
      this.app.modules.Groups.getItems();
      this.app.modules.ProductFilter.initFilters();
      this.app.modules.AvitoCategories.getItems();
      this.app.modules.TiuCategories.getItems();
      
      
    });

    this.selectors.$modal.find('form').on('submit', (e) => {
      e.preventDefault();
      this.login(e); 
    });
    
    if (undefined !== Cookies.get('hash') /*&& sessionStorage.getItem('currentTabPage') !== 'tiu_products_screen_tab'*/){
      // document.querySelector('body').insertAdjacentHTML('afterbegin', this.app.mainloader);
      // document.addEventListener("DOMContentLoaded", () => { 
      //     $('.preloader-bg').fadeOut('fast');
      // });  
    }
  } 

  async ping() {
    if (undefined === Cookies.get('hash')) {
      $(document.body).trigger('validation-failed');
      return;
    }

    const res = await this.app.fetch('auth', 'ping', {});
    if (true === res.status) {
      $(document.body).trigger('validation-success');
    } else {
      $(document.body).trigger('validation-failed');
    }
    
  }
 
  async login(e) {
    let $e = $(e.target);

    let data = {
      login: $e.find('input#login').val(),
      password: $e.find('input#password').val(),
      remember: $e.find('input#remember').prop('checked'),
    };

    const res = await this.app.fetch('auth', 'login', data);
    if (true === res.status) {
      Cookies.set('hash', res.data.hash, {expires: res.data.ttl / (60 * 60 * 24), path: ''});
    } 
    if (res.status) window.location.reload();
      
  }

  logout() {
    if (!confirm('Вы уверены, что хотите выйти?')) return;
    Cookies.remove('hash', {path: ''});
    M.toast({html: 'Вы успешно вышли, страница обновится через 2 секунды'});
    setTimeout(window.location.reload.bind(window.location), 2000);
  } 
}
