import 'regenerator-runtime/runtime';

import 'materialize-css'; 
  

import App from './app/base/app';
import Auth from './app/auth';
import Search from './app/search';
import Gallery from './app/gallery';

import Product from './app/product/product';
import Groups from './app/product/groups';
import TiuCategories from './app/product/tiuCategories';
import AvitoCategories from './app/product/avitoCategories';
import Autodonors from './app/product/autodonors';
import Characteristics from './app/product/characteristics';
import ProductFilter from './app/product/productFilter'; 
    
import Barcode from './app/barcode';

import Import from './app/import';
import Invoice from './app/invoice';

import Report from './app/report';
import Settings from './app/settings';

import TiuProduct from './app/tiuProduct/list';
import TiuProductFilter from './app/tiuProduct/filter';
import TiuProductGroups from './app/tiuProduct/groups';
import TiuProductGroupsEdit from './app/tiuProduct/groupsEdit';
import TiuProductEdit from './app/tiuProduct/productEdit';
import TiuProductModal from './app/tiuProduct/modal';
import TiuProductUtils from './app/tiuProduct/utils';
// import TiuProductDrag from './app/tiuProduct/drag';
import TiuCharacteristics from './app/tiuProduct/tiuCharacteristics';
import TiuProductsSortPanel from './app/tiuProduct/productsSortPanel';

import AutodonorsFilter from './app/autodonor/filter';
import AutodonorsList from './app/autodonor/list';
import AutodonorCharacteristics from './app/autodonor/characteristics';
import AutodonorEdit from './app/autodonor/edit';



(function($, scope) {
  'use strict'; 

  //M.toast({html: 'На сайте производятся работы'}); 
  
  scope.app = new App();
    
  scope.app.addModule(new Auth(scope.app));
  scope.app.addModule(new Product(scope.app));

  scope.app.addModule(new Characteristics(scope.app));
  
  scope.app.addModule(new Gallery(scope.app));

  scope.app.addModule(new Groups(scope.app));
  scope.app.addModule(new TiuCategories(scope.app));
  scope.app.addModule(new AvitoCategories(scope.app));
  scope.app.addModule(new Autodonors(scope.app));

  scope.app.addModule(new ProductFilter(scope.app));

  scope.app.addModule(new Barcode(scope.app));

  scope.app.addModule(new Import(scope.app));

  scope.app.addModule(new Invoice(scope.app));

  scope.app.addModule(new Report(scope.app));
  scope.app.addModule(new Settings(scope.app));
  scope.app.addModule(new Search(scope.app));
  scope.app.addModule(new TiuProductFilter(scope.app));
  scope.app.addModule(new TiuProductGroups(scope.app));
  scope.app.addModule(new TiuProductGroupsEdit(scope.app));
  scope.app.addModule(new TiuProduct(scope.app));
  scope.app.addModule(new TiuProductModal(scope.app));
  scope.app.addModule(new TiuProductEdit(scope.app));
  scope.app.addModule(new TiuProductUtils(scope.app));
  // scope.app.addModule(new TiuProductDrag(scope.app));
  scope.app.addModule(new TiuCharacteristics(scope.app));
  scope.app.addModule(new TiuProductsSortPanel(scope.app));

  scope.app.addModule(new AutodonorsFilter(scope.app));
  scope.app.addModule(new AutodonorsList(scope.app));
  scope.app.addModule(new AutodonorCharacteristics(scope.app));
  scope.app.addModule(new AutodonorEdit(scope.app));
  
  
  scope.app.init();

})(jQuery, window); 
