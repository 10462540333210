import Module from './base/module';


export default class Import extends Module{
  init() {

    this.selectors.$publishBtn = $('.product__publish');
    this.selectors.Screen = document.querySelector('#products-screen');
    
    this.labels = {
      confirmPublish: 'Вы уверены, что хотите опубликовать выбранные товары?',
    };
  }
 
  run() {
    this.listeners();
  } 

  listeners() {
    this.selectors.$publishBtn.on('click', () => { 
        if (confirm(this.labels.confirmPublish)) {
              this.publish();
        }
    });
  }

  async publish() {
    const refresh = async () => {
      this.app.modules.Product.resetList();
      this.app.modules.TiuProduct.fetchProducts();
    };
    const callback = async () =>{
        let res = await this.app.fetch('product', 'publish', {filters: this.app.modules.ProductFilter.getValues()});
        if (res?.status){
            refresh();  
            M.toast({html: `Опубликовано ${res.data.publishedCount} ${this.app.decl(res.data.publishedCount, ['товар', 'товара', 'товаров'])}`});
        } else {
            M.toast({html: 'При публикации произошла ошибка'});
        }
    };
    await this.cancelableCallback(callback);
  }
  
  #lockScreen = () => this.selectors.Screen.classList.add('disabled');

  #unlockScreen = () => this.selectors.Screen.classList.remove('disabled');

  async cancelableCallback(callback) {
    let canceled = false;

    const proceed = async () => {
      !canceled && await callback();
      this.#unlockScreen();
    };

    this.#lockScreen();

    const toast = M.toast({
      html: '<span>Отменить изменение</span><button class="btn-flat toast-action">Отменить</button>',
      displayLength: 10000,
      completeCallback: proceed,
    });

    toast.el.querySelector('button').addEventListener('click', () => {
      canceled = true;
      proceed();
      toast.dismiss();
    });
  }
  
}

