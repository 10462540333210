export default class Module {
    constructor(app) {
        this.app  = app;
        this.data = {};
        this.selectors = {};

        if (!this.init || 'function' !== typeof this.init) {
            throw new Error(`У объекта ${this.constructor.name} нету метода init`);
        }

        if (!this.run || 'function' !== typeof this.run) {
            throw new Error(`У объекта ${this.constructor.name} нету метода run`);
        }
    }

    init() {
    }

    run() {
    }

    listeners() {

    }

    debug(msg) {
        if (!this.app.needDebug) {
            return;
        }

        return console.warn(msg);
    }
}