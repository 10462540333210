import Module from "./base/module";

export default class Settings extends Module {

    run()
    {
        this.setup();
        this.listeners();
    }
    
    init() 
    {
        this.initSelectors();
        this.initLabels();
    }

    setup()
    {
        this.getMark();
        M.Modal.init(this.selectors.Modal, {
            // onOpenStart: await this.load.bind(this)
            onOpenStart: () =>{
                this.select2FieldsConfig();
                this.eventsHundlerOn();
                // this.load.bind(this);
            }
        });
    }
    
    initSelectors() 
    {
        this.selectors.Modal = document.querySelector('#settings-modal');
        this.selectors.$Modal = $('#settings-modal');
        this.selectors.$List = this.selectors.$Modal.find('.settingsTable').find('tbody');
        this.selectors.List = this.selectors.Modal.querySelector('.settingsTable tbody');
        this.selectors.$ListName = this.selectors.$Modal.find('.settingsTable').find('.selected_char_name');
        this.selectors.Form = this.selectors.Modal.querySelector('form');
        this.selectors.$Form = this.selectors.$Modal.find('form');
        
        this.selectors.FormName = this.selectors.Form.querySelector('#settings-name');
        this.selectors.FormYearStart = this.selectors.Form.querySelector('#settings-year-from');
        this.selectors.FormYearEnd = this.selectors.Form.querySelector('#settings-year-to');
        this.selectors.FormNotice = this.selectors.Form.querySelector('#settings-notice');
        
        this.selectors.FormPropertyType = this.selectors.Form.querySelector('input[name=\'property\']');
        this.selectors.FormParentPropId = this.selectors.Form.querySelector('input[name=\'parent_prop_id\']');
        this.selectors.FormPropertyName = this.selectors.Form.querySelector('.property_name');
        
        this.selectors.$selectMark = this.selectors.$Modal.find('.settings_characteristic-item__default__mark');
        this.selectors.$selectModel = this.selectors.$Modal.find('.settings_characteristic-item__default__model');
        this.selectors.$selectGeneration = this.selectors.$Modal.find('.settings_characteristic-item__default__generation');

        this.selectors.selectMark = this.selectors.Modal.querySelector('.settings_characteristic-item__default__mark');
        this.selectors.selectModel = this.selectors.Modal.querySelector('.settings_characteristic-item__default__model');
        this.selectors.selectGeneration = this.selectors.Modal.querySelector('.settings_characteristic-item__default__generation');

        this.selectors.$btnEdit = this.selectors.$Modal.find('#settings_edit');
        this.selectors.$btnAdd = this.selectors.$Modal.find('#settings_add');
        this.selectors.$btnReset = this.selectors.$Modal.find('#settings_reset');
        this.selectors.$btnCloseModal = this.selectors.$Modal.find('.modal-close');
        
        this.selectors.tpl = 'settings-list__item';
        this.selectors.itemSelect = '.check input';
    }

    initLabels() 
    {
        this.labels = {
            closeConfirm: 'Вы уверены, что хотите закрыть это окно?',
            editConfirm: 'Вы уверены, что хотите изменить эти данные?',
            addConfirm: 'Вы уверены, что хотите добавить эти данные в базу?',
            deleteConfirm: 'Вы уверены, что хотите удалить эту позицию из базы?',
            clearConfirm: 'Вы уверены, что хотите очистить форму?',
            
            errors: {
                invalidName: 'Введите название',
                invalidYear: 'Вы должны указать год',
                invalidClass: 'Вы должны указать класс',
                addError: 'При добавлении данных произошла ошибка.',
                editError: 'При изменении данных произошла ошибка.',
            },
        };
    }
    
    listeners () 
    {
        this.selectors.$btnCloseModal.on('click', (e) => {
            e.preventDefault();
            if (confirm(this.labels.closeConfirm)) {
                this.eventsHundlerOff()
                this.reset();
                this.selectors.$Modal.modal('close');
            }
        });
        
        this.selectors.$btnReset.on('click', (e) => {
            e.preventDefault();
            if (confirm(this.labels.clearConfirm)) {
                this.reset();
            }
        });
        
        this.selectors.$btnAdd.on('click', (e) => {
            e.preventDefault();
            if (confirm(this.labels.addConfirm)) {
                this.save();
            }
        });
        
        this.selectors.$btnEdit.on('click', (e) => {
            e.preventDefault();
            if (confirm(this.labels.editConfirm)) {
                this.save();
            }
        });
        
        this.selectors.$Form.on('keyup', 'input', (e) => {
            this.buttonsHandler();
        });
        
        this.selectors.$List.on('click', this.selectors.itemSelect, e => {
            this.checkHandler(e);
            this.fillForm(e);
            this.buttonsHandler();
            this.deleteListener(e);
        });
    } 
    
    deleteListener(e)
    {
        $(e.target).parent().parent().next('.wrap-icon').find('.auto-info-delete').on('click', (e) => {
            e.preventDefault();
            if (confirm(this.labels.deleteConfirm)) {
                this.delete(e);
            }
        });
    }
    
    fillForm(e)
    {
        let checked = false;
        this.selectors.$List.find(this.selectors.itemSelect).each((idx, el) => {
            if ($(el).prop('checked')) checked = true;
        });
        if(checked){
            this.get(e).then((data)=>{
                if(this.selectors.FormPropertyType.value === 'serie') data['name'] = data['body-type'];
                this.selectors.FormName.value = data['name'] ? data['name'] : '';
                this.selectors.FormYearStart.value = data['year-start'] ? data['year-start'] : '';
                this.selectors.FormYearEnd.value = data['year-stop'] ? data['year-stop'] : '';
                this.selectors.FormNotice.value = data['notice'] ? data['notice'] : '';
            });
        } else {
            this.resetForm();
        }
    }
    
    async get(e)
    {
        const id = $(e.target).closest('tr').attr('data-id');
        const prop_type = this.selectors.FormPropertyType.value;
        const response = await this.app.fetch('CarsBase', 'get', {'id': id, 'prop_type': prop_type});
        if (response?.status) {
            return response.data.formData;
        } else {
            M.toast({html: response.message});
            return false;
        }
    }

    async save()
    {
        await this.app.fetch('CarsBase', 'save', this.getFormData()).then((response) => {
            M.toast({html: response.message});
            this.reloadList();
        })
    }

    async delete(e)
    {
        const id = $(e.target).closest('tr').attr('data-id');
        const prop_type = this.selectors.FormPropertyType.value;
        await this.app.fetch('CarsBase', 'delete', {'id': id, 'prop_type': prop_type}).then((response) => {
            M.toast({html: response.message});
            this.reloadList();
        })
    }
    
    getFormData()
    {
        let property_id = null;
        this.selectors.$List.find('.settings_list__item').each((i, el) => {
            if ($(el).hasClass('added')) property_id = $(el).attr('data-id');
        });
        return {
            'property_id' : property_id, 
            'parent_id' : this.selectors.FormParentPropId.value ?? null,
            'property_type' : this.selectors.FormPropertyType.value ?? null,
            'name' : this.selectors.FormName.value ?? null,
            'notice' : this.selectors.FormNotice.value ?? null,
            'year_start' : this.selectors.FormYearStart.value ?? null,
            'year_end' : this.selectors.FormYearEnd.value ?? null,
        }
    }
    
    reset()
    {
        this.selectors.$ListName.html('')
        this.selectors.FormPropertyName.innerHTML = '';
        this.selectors.FormPropertyType.value = '';
        this.selectors.FormParentPropId.value = '';
        
        this.selectors.$selectMark.val('').trigger('change')
        this.selectors.$selectModel.children('option:not([value=""])').remove()
        this.selectors.$selectGeneration.children('option:not([value=""])').remove()

        this.selectors.FormName.value = '';
        this.selectors.FormYearStart.value = '';
        this.selectors.FormYearEnd.value = '';
        this.selectors.FormNotice.value = '';
        
        this.selectors.FormName.parentElement.hidden = true;
        this.selectors.FormYearStart.parentElement.hidden = true;
        this.selectors.FormYearEnd.parentElement.hidden = true;
        this.selectors.FormNotice.parentElement.hidden = true;
    }
    
    resetForm()
    {
        this.selectors.FormName.value = '';
        this.selectors.FormYearStart.value = '';
        this.selectors.FormYearEnd.value = '';
        this.selectors.FormNotice.value = '';
    }
    
    buttonsHandler()
    {
        let checked = false;
        let values_filled = false;
        this.selectors.$List.find(this.selectors.itemSelect).each((idx, el) => {
            if ($(el).prop('checked')) checked = true;
        });
        this.selectors.$Form.find("#settings input:not([type='hidden'])").each((i, el)=> {
            if(el.value) values_filled = true;
        });
        if(checked || values_filled){
            if(this.selectors.$btnReset.hasClass("disabled")) this.selectors.$btnReset.removeClass('disabled');
        }
        if(checked && values_filled){
            if(!this.selectors.$btnAdd.hasClass("disabled")) this.selectors.$btnAdd.addClass('disabled')
            if(this.selectors.$btnEdit.hasClass("disabled")) this.selectors.$btnEdit.removeClass('disabled');
        }
        if(checked && !values_filled){
            if(!this.selectors.$btnAdd.hasClass("disabled")) this.selectors.$btnAdd.addClass('disabled')
            if(this.selectors.$btnEdit.hasClass("disabled")) this.selectors.$btnEdit.removeClass('disabled');
            if(this.selectors.$btnReset.hasClass("disabled")) this.selectors.$btnReset.removeClass('disabled');
        }
        if(!checked && values_filled){
            if(this.selectors.$btnAdd.hasClass("disabled")) this.selectors.$btnAdd.removeClass('disabled')
            if(!this.selectors.$btnEdit.hasClass("disabled")) this.selectors.$btnEdit.addClass('disabled');
            if(this.selectors.$btnReset.hasClass("disabled")) this.selectors.$btnReset.removeClass('disabled');
        }
        if(!checked && !values_filled){
            if(!this.selectors.$btnAdd.hasClass("disabled")) this.selectors.$btnAdd.addClass('disabled');
            if(!this.selectors.$btnEdit.hasClass("disabled")) this.selectors.$btnEdit.addClass('disabled');
            if(!this.selectors.$btnReset.hasClass("disabled")) this.selectors.$btnReset.addClass('disabled');
        }
    } 
    
    // async load() {
    //     const response = await this.app.fetch('settings', 'getAll');
    //     if (!response.status) return M.Toast({html: response.message});
    //
    //     const $List = $(this.selectors.List).find('tbody');
    //
    //     $List.html('');
    //
    //     response.data.items.forEach(el => {
    //         const $Item = $(this.app.getTemplate(this.selectors.tpl, el));
    //         $List.append($Item);
    //     }); 
    //
    //     if (!this.inited) this.inited = true;
    // }
    
    // async save() {
    //     if (!this.inited || !confirm('Вы уверены, что хотите сохранить эти настройки?')) return;
    //  
    //     const updatedFields = []; 
    //
    //     const Inputs = this.selectors.Form.querySelectorAll('input');
    //
    //     Inputs.forEach(Input => {
    //         if (Input.value !== Input.getAttribute('value')) {
    //             updatedFields.push(Input);
    //         }
    //     });
    //
    //     let response = await this.app.fetch('settings', 'save', {
    //         fields: updatedFields.map(el => {
    //             return {key: el.getAttribute('name'), value: el.value}
    //         })
    //     });
    //
    //     if (true !== response.status) {
    //         this.reset();
    //     } else {
    //         updatedFields.forEach(Input => {
    //             Input.setAttribute('value', Input.value);
    //         });
    //     } 
    //
    //     M.toast({html: response.message});
    // }

    getPropertyId(selector)
    {
        let id = null;
        if (selector.selectedOptions.length) {
            if (selector.selectedOptions[0].dataset.hasOwnProperty('id')) id = selector.selectedOptions[0].dataset.id;
        }
        return id;
    }
    
    async getMark() 
    {
        await this.app.fetch('characteristic', 'getMark').then((data) => {
            this.selectors.$selectMark.children('option:not([value=""])').remove();
            data.data.marks.forEach((obj) => {
                this.selectors.$selectMark.append(`<option value="${obj.name}" data-id="${obj.id}">${obj.name}</option>`);
            })
            this.selectors.$selectMark.val('').trigger('change');
        })
    }

    async getModel() 
    {
        let id = this.getPropertyId(this.selectors.selectMark);
        this.selectors.$selectModel.children('option:not([value=""])').remove();
        if (id !== null) {
            await this.app.fetch('characteristic', 'getModel', {id_car_mark: id}).then((data) => {
                data.data.models.forEach((obj) => {
                    this.selectors.$selectModel.append(`<option value="${obj.name}" data-id="${obj.id}">${obj.name}</option>`);
                })
                this.selectors.$selectModel.val('').trigger('change');
            })
        }
    }

    async getGeneration() 
    {
        let id = this.getPropertyId(this.selectors.selectModel);
        this.selectors.$selectGeneration.children('option:not([value=""])').remove();
        if (id !== null) {
            await this.app.fetch('characteristic', 'getGeneration', {id_car_model: id}).then((response) => {
                response.data.generation.forEach((obj) => {
                    if (obj.year_start == null) obj.year_start = 'н.в';
                    if (obj.year_stop == null) obj.year_stop = 'н.в';
                    const year = obj.year_start || obj.year_stop ? ` (${obj.year_start} - ${obj.year_stop})` : ``;
                    this.selectors.$selectGeneration.append(`<option value="${obj.name}${year}" data-id="${obj.id}">${obj.name}${year}</option>`);
                })
                this.selectors.$selectGeneration.val('').trigger('change');
            })
        }
    }
    
    async listModels()
    {
        let id = this.getPropertyId(this.selectors.selectMark);
        if (id == null) return;
        this.selectors.FormParentPropId.value = id;
        await this.app.fetch('characteristic', 'getModel', {id_car_mark: id}).then((res) => {
            this.selectors.$ListName.html('Список моделей')
            this.selectors.FormPropertyName.innerHTML = 'Данные модели';
            this.selectors.$List.html('');
            res.data.models.forEach(el => {
                this.selectors.$List.append($(this.app.getTemplate(this.selectors.tpl, el)));
            });
            this.selectors.FormPropertyType.value = Object.keys(res.data)[0];
            this.selectors.FormName.parentElement.hidden = false;
            this.selectors.FormYearStart.parentElement.hidden = true;
            this.selectors.FormYearEnd.parentElement.hidden = true;
            this.selectors.FormNotice.parentElement.hidden = true; 
        })
    }

    async listGenerations()
    {
        let id = this.getPropertyId(this.selectors.selectModel);
        if (id == null) return;
        this.selectors.FormParentPropId.value = id;
        await this.app.fetch('characteristic', 'getGeneration', {id_car_model: id}).then((res) => {
            this.selectors.$ListName.html('Список поколений')
            this.selectors.FormPropertyName.innerHTML = 'Данные поколения';
            this.selectors.$List.html('');
            res.data.generation.forEach(el => {
                if (el.year_start == null) el.year_start = 'н.в';
                if (el.year_stop == null) el.year_stop = 'н.в';
                const year = el.year_start || el.year_stop ? ` (${el.year_start} - ${el.year_stop})` : ``;
                el.name = el.name + year;
                this.selectors.$List.append($(this.app.getTemplate(this.selectors.tpl, el)));
            });
            this.selectors.FormPropertyType.value = Object.keys(res.data)[0];
            this.selectors.FormName.parentElement.hidden = false;
            this.selectors.FormYearStart.parentElement.hidden = false;
            this.selectors.FormYearEnd.parentElement.hidden = false;
            this.selectors.FormNotice.parentElement.hidden = true;
        })
    }

    async listSerie()
    {
        let id = this.getPropertyId(this.selectors.selectGeneration);
        if (id == null) return;
        this.selectors.FormParentPropId.value = id;
        await this.app.fetch('characteristic', 'getSerie', {id_car_generation: id}).then((res) => {
            this.selectors.$ListName.html('Список кузовов')
            this.selectors.FormPropertyName.innerHTML = 'Данные кузова';
            this.selectors.$List.html('');
            res.data.serie.forEach(el => {
                el.name = el.name[0].toUpperCase() + el.name.slice(1)
                if (el.notice) el.name = el.name + ' ' + '(' + el.notice + ')'
                this.selectors.$List.append($(this.app.getTemplate(this.selectors.tpl, el)));
            });
            this.selectors.FormPropertyType.value = Object.keys(res.data)[0];
            this.selectors.FormName.parentElement.hidden = false;
            this.selectors.FormNotice.parentElement.hidden = false;
            this.selectors.FormYearStart.parentElement.hidden = true;
            this.selectors.FormYearEnd.parentElement.hidden = true;
        })
    }

    eventsHundlerOff()
    {
        this.selectors.$selectMark.off()
        this.selectors.$selectModel.off()
        this.selectors.$selectGeneration.off()
    }
    
    eventsHundlerOn()
    {
        this.selectors.$selectMark.on('change', () => {
            this.resetForm()
            this.getModel()
            this.getGeneration()
            this.listModels()
            this.buttonsHandler()
        })
        this.selectors.$selectModel.on('change', () => {
            this.resetForm()
            this.getGeneration()
            this.listGenerations()
            this.buttonsHandler()
        })
        this.selectors.$selectGeneration.on('change', () => {
            this.resetForm()
            this.listSerie()
            this.buttonsHandler()
        })
    }
    
    reloadList()
    {
        if(this.selectors.FormPropertyType.value === 'models'){
            this.getModel()
            this.getGeneration()
            this.listModels()
        }
        if(this.selectors.FormPropertyType.value === 'generation'){
            this.getGeneration()
            this.listGenerations()
        }
        if(this.selectors.FormPropertyType.value === 'serie'){
            this.listSerie()
        }
        this.buttonsHandler()
        this.resetForm()
    }
    
    select2FieldsConfig() 
    {
        this.selectors.$selectMark.select2({
            width: '100%',
            dropdownParent: '#settings-modal',
            theme: 'material',
            // templateResult: (data) => {
            //     if (!data.id || data.id === 'all' || data.id === 'empty') return data.text;
            //     const $html = `<div style="display: flex;align-items: center;"><span style="background-image: url('`+this.app.getConfig('logosUrl')+data.element.dataset.id+`.png');width: 30px;height: 30px;margin-right: 5px"></span><span>`+data.text+`</span></div>`;
            //     if (data.element) return $($html);
            // }, 
            placeholder: 'Выберите марку',
        })
        this.selectors.$selectModel.select2({
            width: '100%',
            dropdownParent: '#settings-modal',
            theme: 'material',
            placeholder: 'Выберите модель',
        })
        this.selectors.$selectGeneration.select2({
            width: '100%',
            dropdownParent: '#settings-modal',
            theme: 'material',
            placeholder: 'Выберите поколение',
        })
    }

    checkHandler(e)
    {
        let $checkboxes = this.selectors.$List.find(this.selectors.itemSelect);
        if ($checkboxes.length) {
            $checkboxes.each((idx, el) => {
                if ($(el).prop('checked') && $(el)[0] !== $(e.target)[0]){
                    $(el).prop('checked',false);
                    $(el).closest('tr').removeClass('bg_yellow');
                    $(el).closest('tr').removeClass('added');
                }
            });
        }
        if ($(e.target).prop('checked')){
            $(e.target).closest('tr').addClass('added');
            $(e.target).closest('tr').addClass('bg_yellow');
            this.selectors.$List.find('.wrap-icon').addClass('hiddendiv');
            $(e.target).parent().parent().next('.wrap-icon').removeClass('hiddendiv');
        } else { 
            $(e.target).closest('tr').removeClass('added');
            $(e.target).closest('tr').removeClass('bg_yellow');
            this.selectors.$List.find('.wrap-icon').addClass('hiddendiv');
            $(e.target).parent().parent().next('.wrap-icon').addClass('hiddendiv');
        }
    }
}