import Module from './../base/module';
import {debounce} from 'throttle-debounce';


export default class ProductFilter extends Module{
  init() {
    this.data = {
      prevTarget: {
        group: 'default',
      },
    };

    this.selectors.filter = '#product-list__filter';
    this.selectors._filter = document.querySelector(this.selectors.filter);
    this.selectors.$filter = $(this.selectors.filter);
    this.selectors._name = this.selectors._filter.querySelector('[name="name"]');
    this.selectors._dateFrom = this.selectors._filter.querySelector('[name="date_from"]');
    this.selectors._dateTo = this.selectors._filter.querySelector('[name="date_to"]');
    this.selectors._group = this.selectors._filter.querySelector('[name="group"]');
    this.selectors._barcode = this.selectors._filter.querySelector('[name="barcode"]');

    this.dateInstances = {
      from: M.Datepicker.init(this.selectors._dateFrom, this.app.data.config.datetime),
      to: M.Datepicker.init(this.selectors._dateTo, this.app.data.config.datetime),
    };

    this.selectors._dateFrom.addEventListener('change', e => {
      this.dateInstances.to.options.minDate = this.dateInstances.from.date;
    });
    this.selectors._dateTo.addEventListener('change', e => {
      this.dateInstances.from.options.maxDate = e.target.value ? new Date(this.dateInstances.to.date) : new Date();
    });
  }

  run() {
    this.updateFilters();

    this.selectors._filter.addEventListener('change', () => this.app.modules.Product.resetList());
    this.selectors._name.addEventListener('input', debounce(350, () => this.app.modules.Product.resetList()));
  }

  async initFilters() {
    await this.updateFilters();
  }

  async updateGroups() {
    const res = await this.app.fetch('group', 'getWithProducts');

    if (!res.status) throw new Error('Не удалось получить группы товаров для фильтрации');
    const {data: groups} = res;

    const Select = this.selectors._group;
    Select.querySelectorAll('option:not([value=""])').forEach(el => el.remove());
    groups.map(group => {
      const OptionElement = document.createElement('option');
      OptionElement.value = group.id;
      OptionElement.innerText = group.name;
      Select.appendChild(OptionElement);
    });
  }

  async updateFilters() {
    await this.updateGroups();
    await this.app.modules.Product.fetchAll(true);
    this.app.modules.Product.reinitMaterialize();
  }

  getBarcode() {
    return this.selectors._barcode.value;
  }
  
  getName() {
    const value = this.selectors._name.value.trim().replaceAll(/\s+/g, ' ');
    return value !== '' && value.length > 2 ? value : null;
  }

  getDates() {
    return {
      dateFrom: this.dateInstances.from?.date / 1000 || null,
      dateTo: this.dateInstances.to?.date / 1000 || null,
    };
  }

  getGroup() {
    const value = this.selectors._group.value.trim();
    return value !== '' ? parseInt(value) : null;
  }

  getValues() {
    return {
      ids: (()=>{
        if(sessionStorage.getItem('checkedAllProducts') === "true"){
          return null;
        } else {
          return sessionStorage.getItem('allCheckedIdsProducts') ? sessionStorage.getItem('allCheckedIdsProducts').split(',') : null;
        }
      })(),
      name: this.getName(),
      ...this.getDates(),
      group: this.getGroup(),
      barcode_status: this.getBarcode(),
    }; 
  }
}
