import Module from './../base/module';
import {debounce} from 'throttle-debounce';


export default class TiuProduct extends Module {
    init() {
        this.selectors.ScreenTab = document.querySelector('#tiu_products_screen_tab');
        this.selectors.Screen = document.querySelector('#tiu_products-screen');
        this.selectors.Sidebar = document.querySelector('#tiu_products-sidebar');
        this.selectors.List = document.querySelector('#tiu_products-list');
        this.selectors.tplListItem = 'tiu_products-list__item';
        this.selectors.listItem = '.tiu_products-list__item';
        this.selectors.countSelected = this.selectors.Screen.querySelector('.countSelected');
        this.selectors.CheckAll = this.selectors.List.querySelector('.check-all');
        this.selectors.Total = this.selectors.List.querySelector('#tiu_products-list_total');
        this.selectors.Gallery = this.selectors.Screen.querySelector('#tiu_products-gallery');
        this.selectors.clearSelected = this.selectors.Screen.querySelector('.clearSelected');
        this.selectors.productDelete = this.selectors.Screen.querySelector('.tiu-product-delete');
        this.selectors.$headPanel = $('#tiu_products-screen .head-panel');


        this.data = {
            groups: [],
            products: [],
            total: null,
        };
        
        this.listeners();
    }

      async run() {
        // this.selectors.List.querySelector('tbody').innerHTML = this.app.preloader;
        this.app.modules.TiuProductGroups.initGroups();
        // await this.fetchList();
        // this.fillList();
        this.checkedProductsHandler();
    }    
 
    async fetchProducts() {
        this.resetList();
        await this.fetchList();
        this.fillList();
        this.checkedProductsHandler();
    }
    

    screenTabEventListener(){
       if (this.app.modules.TiuProductGroups.selectors.List.innerHTML.trim() === '' && this.selectors.List.querySelector('tbody').innerHTML.trim() === ''){
            this.app.modules.TiuProductGroups.initGroups();
            this.resetList();
        }   
    }   

    listeners() {
        
        // if (sessionStorage.getItem('currentTabPage') === 'tiu_products_screen_tab') {
        //      this.app.modules.TiuProductGroups.initGroups();
        //      this.resetList(true);
        //  } 
        
        this.selectors.productDelete.addEventListener('click', () => {
                const checkedProducts = sessionStorage.getItem('checkedAll') !== null && sessionStorage.getItem('checkedAll') === 'true' ? 'all' : this.getCheckedIds();
                this.handleDelete(checkedProducts);  
        });   
        
        this.selectors.ScreenTab.addEventListener('click', () => {
               this.screenTabEventListener();
        });
 
        this.selectors.clearSelected.addEventListener('click', () => {
                this.uncheckAll();
                this.checkedProductsHandler();  
        });   
        
        window.addEventListener('scroll', debounce(100, async () => {
            if (!this.activeScreen() || this.selectors.Screen.scrollHeight <= window.innerHeight || this.data.isLoad) return;
            if (window.scrollY + window.innerHeight >= this.selectors.Screen.scrollHeight - window.innerHeight / 4) {
                await this.fetchList();
                this.fillList();
                this.checkedProductsHandler();
            } 
        }));
        this.initChangePresence();
        this.initChangePrice();
        this.initCheckAll();
        this.initGallery();
 
        this.selectors.List.addEventListener('click', () => {
            this.checkedProductsHandler();
        }); 
    }
      
    checkedProductsHandler(){
        if(sessionStorage.getItem('checkedAll') === null) sessionStorage.setItem('checkedAll', 'false');  
        this.#getAllItems().map((El) => {  
            let prevCheckedIds = [];
            const makeUniq = (arr) => [...new Set(arr)];
            if(sessionStorage.getItem('allCheckedIds')) prevCheckedIds = sessionStorage.getItem('allCheckedIds').split(',');
            if(sessionStorage.getItem('checkedAll') !== null && sessionStorage.getItem('checkedAll') === 'false'){ 
                if(El.querySelector('input[type="checkbox"]').checked === true){
                    El.classList.add('bg_yellow');
                    prevCheckedIds.push(El.dataset['id']);
                    prevCheckedIds = makeUniq(prevCheckedIds);
                    sessionStorage.setItem('allCheckedIds', prevCheckedIds);
                } else {
                    El.classList.remove('bg_yellow');
                    if(prevCheckedIds){
                        prevCheckedIds = makeUniq(prevCheckedIds);
                        prevCheckedIds = prevCheckedIds.filter(e => e !== El.dataset['id']);
                        sessionStorage.setItem('allCheckedIds', prevCheckedIds);
                    }
                }
            }  
            if(sessionStorage.getItem('checkedAll') !== null && sessionStorage.getItem('checkedAll') === 'true'){
                El.querySelector('input[type="checkbox"]').checked = true;
                El.classList.add('bg_yellow');
            }    
        });

        if(sessionStorage.getItem('checkedAll') !== null && sessionStorage.getItem('checkedAll') === 'false'){
            this.selectors.countSelected.innerHTML = sessionStorage.getItem('allCheckedIds') ? sessionStorage.getItem('allCheckedIds').split(',').length + '  тов. выбрано' : '0 тов. выбрано';
        } else {  
            this.selectors.countSelected.innerHTML = this.data.total + '  тов. выбрано'; 
        }  
        sessionStorage.getItem('allCheckedIds') || (sessionStorage.getItem('checkedAll') !== null && sessionStorage.getItem('checkedAll') === 'true') ? this.selectors.$headPanel.fadeIn('fast') : this.selectors.$headPanel.fadeOut('fast');
    }   

    activeScreen = () => this.selectors.Screen.classList.contains('active');

    #lockScreen = () => this.selectors.Screen.classList.add('disabled');

    #unlockScreen = () => this.selectors.Screen.classList.remove('disabled');

    getProductById = (id) => this.data.products.filter(({id: _id}) => parseInt(_id) === parseInt(id))?.shift() || null;

     resetList() {
        this.selectors.CheckAll.checked = sessionStorage.getItem('checkedAll') !== null && sessionStorage.getItem('checkedAll') === 'true';
        this.data.products = [];
        //this.uncheckAll();  
        this.selectors.List.querySelector('tbody').innerHTML = '';
    }

    #setTotal(num) {
        this.data.total = isNaN(num) ? null : Number(num);
        this.selectors.Total.textContent = null === num || isNaN(num) ? '' : `${num}`;
        // this.selectors.countSelected.innerHTML = null === num || isNaN(num) ? '' : `${num}`; 
    }

    timeConverter(UNIX_timestamp){
        const a = new Date(UNIX_timestamp * 1000);
        const date = a.toLocaleDateString([], {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
        });
        const time = a.toLocaleTimeString([],{
            hour: "2-digit",
            minute: "2-digit",
        });
        return date+' '+time;
    } 
    
    getOem(productName){
        let namesArr = productName.split(' ');
        let oem = null;
        if(namesArr.includes('oem')) oem = namesArr.indexOf('oem')+1;
        if(namesArr.includes('OEM')) oem = namesArr.indexOf('OEM')+1;
        return oem ? namesArr[oem] : null;
    } 
    
    async fetchList() {
        if (this.data.isLoad) return;
        this.data.isLoad = true;
        
        let fields = ['id', 'last_update', 'sku', 'name', 'price', 'group', 'main_image', 'images', 'presence', 'discount', 'receipt', 'characteristics', 'storage', 'autodonor_id'];
        
        try{
            await this.app.fetch('TiuProduct', 'getAll', { 
                fields: fields, 
                orderby: this.app.modules.TiuProductFilter.getOrderByValues(),
                offset: this.data.products.length,
                filters: this.app.modules.TiuProductFilter.getValues(),
            })
                .then((data) => {
                    this.#setTotal(data.data.total);
                    const products = data.data.products.map(product => ({
                        ...product,
                        available: product.presence === 'available' ? 'checked' : '',
                        oem: this.getOem(product.name) ? this.getOem(product.name) : '&mdash;',
                        mark: (() => {
                            if(product.autodonor_id){
                                return product.autodonors_mark ? JSON.parse(product.autodonors_mark).value : '&mdash;';
                            } else {
                                if (product.characteristics){
                                    if (JSON.parse(product.characteristics)['Производитель']){
                                        return JSON.parse(product.characteristics)['Производитель'];
                                    } else if (JSON.parse(product.characteristics)['Марка']){
                                        return JSON.parse(product.characteristics)['Марка'];
                                    } else {
                                        return '&mdash;';
                                    }
                                } else {
                                    return '&mdash;';
                                }
                            }
                        })(),
                        model: (() => {
                            if(product.autodonor_id){
                                return product.autodonors_model ? JSON.parse(product.autodonors_model).value : '&mdash;';
                            } else {
                                if (product.characteristics){
                                    return JSON.parse(product.characteristics)['Модель'] ? JSON.parse(product.characteristics)['Модель'] : '&mdash;';
                                } else {
                                    return '&mdash;';
                                }
                            }
                        })(),
                        generation: (() => {
                            if(product.autodonor_id){
                                return product.autodonors_generation ? JSON.parse(product.autodonors_generation).value : '&mdash;';
                            } else {
                                if(product.characteristics){
                                    return JSON.parse(product.characteristics)['Поколение'] ? JSON.parse(product.characteristics)['Поколение'] : '&mdash;';
                                } else {
                                    return '&mdash;';
                                }
                            }
                        })(),
                        storage: product.storage ? product.storage : '&mdash;',
                        discount_price: (() => {
                            if(product.discount !== null) {
                                let today = new Date().toLocaleString().slice(0, 10).split('.');
                                today = new Date(today[2],today[1],today[0]);
                                let date = JSON.parse(product.discount).date_end.split('.');
                                date = new Date(date[0], date[1], date[2]);
                                if(today <= date){
                                    if(JSON.parse(product.discount).type === 'percent'){
                                        return product.price - (JSON.parse(product.discount).value * product.price) / 100  + ' руб.';
                                    } else {
                                        return product.price - JSON.parse(product.discount).value + ' руб.';
                                    }
                                } else {
                                    return '&mdash;';
                                }
                            } else {
                                return '&mdash;';
                            }
                        })(),
                        discount: (() => {
                            if(product.discount !== null) {
                                let today = new Date().toLocaleString().slice(0, 10).split('.');
                                today = new Date(today[2],today[1],today[0]);
                                let date = JSON.parse(product.discount).date_end.split('.');
                                date = new Date(date[0], date[1], date[2]);
                                if(today <= date){
                                    let type = JSON.parse(product.discount).type === 'percent' ? '%' : ' руб.';
                                    return JSON.parse(product.discount).value + type + ' до ' + JSON.parse(product.discount).date_end;
                                } else {
                                    return '&mdash;';
                                }
                            } else {
                                return '&mdash;';
                            }
                        })(),
                        thumb: (() => {
                            if (Array.isArray(product.thumbs) && product.thumbs.length > 0) {
                                return product.thumbs[0];
                            } else {
                                return product.main_image;
                            }
                        })(),
                        receipt: product.receipt !== null ? this.timeConverter(product.receipt) : '&mdash;',
                        last_update: this.timeConverter(product.last_update),
                    }));

                    this.data.products = [...this.data.products, ...products];
                    
                    this.data.isLoad = false;
                });
        } catch (err){
            console.error(err)
        }
    } 

    fillList() {
        const TBody = this.selectors.List.querySelector('tbody');
        this.data.products.map(product => { 
            if (TBody.querySelector(`tr[data-id="${product.id}"]`)) return;
            
            if(product.thumb == null) product.thumb = '/data/zaglushka_50_50.png';
            
            TBody.insertAdjacentHTML(
                'beforeend',
                this.app.getTemplate(this.selectors.tplListItem, product),
            );
        });
          
        // if (sessionStorage.getItem('checkedAll') === 'true') this.checkAll(); 
        const prevCheckedIds = sessionStorage.getItem('allCheckedIds') ? sessionStorage.getItem('allCheckedIds').split(',') : null;
        
        this.#getAllItems().map((El) => {
            if(sessionStorage.getItem('checkedAll') !== null && sessionStorage.getItem('checkedAll') === 'false'){
                if(prevCheckedIds !==null){
                    if(prevCheckedIds.includes(El.dataset['id'])){
                        El.classList.add('bg_yellow');
                        El.querySelector('input[type="checkbox"]').checked = true;
                    } else {
                        El.classList.remove('bg_yellow');
                        El.querySelector('input[type="checkbox"]').checked = false;
                    }
                } 
            }
            if(sessionStorage.getItem('checkedAll') !== null && sessionStorage.getItem('checkedAll') === 'true'){
                El.classList.add('bg_yellow'); 
                El.querySelector('input[type="checkbox"]').checked = true;
            }
        });
        
        
    }
    
    #getAllItems = () => Array.from(this.selectors.List.querySelectorAll(this.selectors.listItem));
      
    #getListItem = id => this.selectors.List.querySelector(`${this.selectors.listItem}[data-id="${id}"]`);

    async #togglePresence(id) {
        const Item = this.#getListItem(id);
        const Checkbox = Item?.querySelector('.presence input');
        const res = await this.app.fetch('TiuProduct', 'togglePresence', {id});
        if (!Item || !Checkbox || !res.status) return;
        Checkbox.checked = res.data.presence === 'available';
    } 

    #getEventId(e) {
        const el = e?.target?.closest('tr');
        if (!el) return null;
        const id = el.dataset['id'];
        return id ?
            parseInt(id) :
            null;
    }

    setChecked(id) { 
        const Item = this.#getListItem(id);
        const Checkbox = Item.querySelector('.image input');
        if (!Item || !Checkbox) return;
        Checkbox.checked = true;
    } 

    initChangePresence() {   
        let prevChecked = null;

        // Выделение ренджа товаров по шифту
        const checkBatchHandle = (prev, current) => {
            const List = this.selectors.List;
            const ProductList = Array.from(List.querySelector('tbody').children);

            const getIdx = id => {
                const El = List.querySelector(`tr[data-id="${id}"]`);
                return ProductList.indexOf(El);
            };

            const toggleByIdx = (idx) => {
                const ProductEl = ProductList[idx];
                const Checkbox = ProductEl?.querySelector('label.check input');
                if (!Checkbox) return;
                Checkbox.checked = !Checkbox.checked;
            };

            const getRange = (start, end) => {
                const dir = end > start;
                const max = Math.max(start, end) - 1;
                const min = Math.min(start, end) + 1;
                const length = (dir ? end - start : start - end);
 
                const res = Array.from({length}, (v, k) => dir ? min + k : max - k);

                return res
            };

            const prevIdx = getIdx(prev);
            const curIdx = getIdx(current);

            getRange(prevIdx, curIdx).map(toggleByIdx);
        };

        this.selectors.List.addEventListener('click', (e) => {
            if (!e.target.closest('tbody')) return;
            const id = this.#getEventId(e);
 
            // Наличие
            if (id && e.target.closest('.presence input')) this.#togglePresence(id);
 
            // Клик по чекбоксу выделения товара
            if (id && e.target.closest('label.check')) {
                if (e.target.tagName !== 'SPAN') return;
                if (e.shiftKey && prevChecked) checkBatchHandle(prevChecked, id)
                prevChecked = id;
            }   
        }, false);
    } 

    async #updateItemPrice(id, price) {
        const El = this.#getListItem(id);
        const Price = El?.querySelector('input[name="price"]');
        if (!El || !Price) return;
        price = parseInt(price);
        if (isNaN(price) || price < 0) {
            M.Toast({html: 'Неверная цена'});
            return;
        }

        const res = await this.app.fetch('TiuProduct', 'updatePrice', {id, price});
        if (!res.status) M.toast({html: 'Не удалось обновить цену'});
    }

    initChangePrice() {
        const handleChange = (e) => {
            e.preventDefault();
            const id = this.#getEventId(e);
            if (!id || e.target.name !== 'price') return;
            this.#updateItemPrice(id, e.target.value);
        };

        this.selectors.List.addEventListener('change', handleChange);
    }

    //собирает только на странице, на которой мы находимся 
    getCheckedIds() {
        return sessionStorage.getItem('allCheckedIds') ? sessionStorage.getItem('allCheckedIds').split(',') : '';
         
        // return this.#getAllItems().map(El => {
        //     if (!El.querySelector('input[type="checkbox"]').checked) return null;
        //     return parseInt(El.dataset['id']);
        // }).filter(i => null !== i && undefined !== i && !isNaN(i));
    }

    checkAll() {
        this.#getAllItems().map(El => {
            El.querySelector('input[type="checkbox"]').checked = true;
        });
        sessionStorage.setItem('checkedAll', true);
        sessionStorage.removeItem('allCheckedIds');
    }   

    uncheckAll() { 
        this.selectors.CheckAll.checked = false; 
        sessionStorage.setItem('checkedAll', false);
        this.#getAllItems().map(El => {
            El.querySelector('input[type="checkbox"]').checked = false; 
        });
        sessionStorage.removeItem('allCheckedIds'); 
    }

    initCheckAll() { 
        this.selectors.CheckAll.addEventListener('change', (e) => {
            sessionStorage.getItem('checkedAll') !== null && sessionStorage.getItem('checkedAll') === 'true' ? this.uncheckAll() : this.checkAll();
            this.checkedProductsHandler();   
        });
    }

    async cancelableCallback(callback) {
        let canceled = false;

        const proceed = async () => {
            !canceled && await callback();
            this.#unlockScreen();
        };

        this.#lockScreen();

        const toast = M.toast({
            html: '<span>Отменить изменение</span><button class="btn-flat toast-action">Отменить</button>',
            displayLength: 10000,
            completeCallback: proceed,
        });

        toast.el.querySelector('button').addEventListener('click', () => {
            canceled = true;
            proceed();
            toast.dismiss();
        });
    }

    async handleMove(ids, target) {
        const targetName = this.app.modules.TiuProductGroups.getNameById(target);
        let params = {ids, target, targetName};
        let msg;

        if (ids === 'all') {
            msg = 'Вы уверены, что хотите переместить ВСЕ отфильтрованные товары?';
            params = {...params, filters: this.app.modules.TiuProductFilter.getValues()};
        } else {
            msg = `Вы уверены, что хотите переместить ${ids.length} ${this.app.decl(ids.length,
                ['товар', 'товара', 'товаров'])} в группу ${targetName}`;
        }

        const callback = async () => {
            const res = await this.app.fetch('TiuProduct', 'move', params);
            if (res.status) {
                this.fetchProducts();
                this.app.modules.TiuProductUtils.selectors.ModalGroupEdit.close();
                M.toast({html: res.message});
            } else { 
                M.toast({html: res.message || 'Произошла ошибка'});
            }
        };

        (ids === 'all' || ids.length > 0) && target && confirm(msg) && await this.cancelableCallback(callback);
    }

    async handleDelete(ids) {
        let params = {ids};
        let msg;

        if (ids === 'all') {
            msg = 'Вы уверены, что хотите удалить ВСЕ отфильтрованные товары?';
            params = {...params, filters: this.app.modules.TiuProductFilter.getValues()};
        } else {
            msg = `Вы уверены, что хотите удалить ${ids.length} ${this.app.decl(ids.length, ['товар', 'товара', 'товаров'])}`;
        }
 
        let pass = prompt('Введите секретный ключ');
        if (pass !== '701735') return M.toast({html: 'Неверный секретный ключ'});

        const callback = async () => {
            const res = await this.app.fetch('TiuProduct', 'delete', params);
            if (res.status) {
                this.fetchProducts();
                this.uncheckAll();
                this.checkedProductsHandler(); 
            } else {
                M.toast({html: res.message || 'Произошла ошибка'});
            }
        };

        (ids === 'all' || ids.length > 0) && confirm(msg) && await this.cancelableCallback(callback);
    } 

    #showGallery = (pos = null, images = null) => {
        const el = this.selectors.Gallery;
        const inner = el.querySelector('.inner');
        if (!el || !inner) return;
        
        if (Array.isArray(images)) {
            inner.innerHTML = '';
            inner.insertAdjacentHTML(  
                'beforeend',  
                // images.map(image => `<a href=${image.src} target="_blank"><img loading="lazy" src="${image.thumb}" width="150" height="150"></a>`).join('')
                images.map((image, index) => `<a><img loading="" src="${image.thumb}" data-index="${index}" width="150" height="150"></a>`).join('')
            );
        }

        if (null !== pos && typeof pos === 'string') el.style.top = pos;

        el.classList.add('show');
        
        let GalleryImages = this.app.modules.Gallery.prepareTiuImagesForGallery(images);
        
        this.selectors.Gallery.querySelectorAll('.inner img').forEach((e) => {
            e.addEventListener('click', () => this.app.modules.Gallery.open(GalleryImages, e.getAttribute('data-index')))
        })
    };

    #hideGallery(destroy = false) {
        const el = this.selectors.Gallery;
        const inner = el.querySelector('.inner');

        el.classList.remove('show');

        if (destroy) {
            inner.innerHTML = '';
            el.style.top = null;
        }
    }

    initGallery() {
        const Gallery = this.selectors.Gallery;
        const GalleryInner = Gallery.querySelector('.inner');
        let currentProductId;
        
        const onImage = (e) => e.target.closest('td.image') && e.target.closest('img');
        const getProduct = (e) => {
            const el = e.target.closest('.tiu_products-list__item');
            if (!el) return null;
            return this.getProductById(el.dataset['id']);
        };

        const handleOver = (e) => {
            const product = getProduct(e);
            if (!onImage(e) || !product) return;

            currentProductId = product.id;
            const productEl = this.selectors.List.querySelector(`.tiu_products-list__item[data-id="${product.id}"]`);
            if (!productEl) return;
            
            if (!product.small || !product.small[0] || product.small?.length <= 0) return;
            const images = product.small.map((thumb, index) => ({src: product.images[index], thumb}))
            // this.#showGallery(`${productEl.offsetTop}px`, images);
            this.#showGallery(`${productEl.clientHeight + productEl.offsetTop}px`, images);
        };
        
        this.selectors.List.addEventListener('mouseover', handleOver);
        this.selectors.List.addEventListener('mouseout', () => this.#hideGallery());
        this.selectors.Gallery.addEventListener('mouseenter', () => this.#showGallery());
        this.selectors.Gallery.addEventListener('mouseleave', () => this.#hideGallery());
    }
}
