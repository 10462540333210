import Module from '../base/module';


export default class TiuProductModal extends Module {
  init() {

  }

  run() {

  }
}
