import Module from './../base/module';
import {debounce} from 'throttle-debounce';


export default class TiuProductFilter extends Module{
  init() {
    this.selectors.Filter = document.querySelector('#tiu_products-list__filter');
    this.selectors.Name = this.selectors.Filter.querySelector('[name="name"]');

    this.selectors.$tiuSortPanelSku = $('.tiu_sort_panel .sort_sku');
    
    this.selectors.$tiuSortPanelMark = $('.tiu_sort_panel .sort_mark');
    this.selectors.$tiuSortPanelModel = $('.tiu_sort_panel .sort_model');
    this.selectors.$tiuSortPanelGeneration = $('.tiu_sort_panel .sort_generation');
    this.selectors.$tiuSortPanelPrice = $('.tiu_sort_panel .sort_price');
    this.selectors.$tiuSortPanelDiscount = $('.tiu_sort_panel .sort_discount'); 
    this.selectors.$tiuSortPanelPresence = $('.tiu_sort_panel .sort_presence');
    this.selectors.$tiuSortPanelStorage = $('.tiu_sort_panel .sort_storage');
    // this.selectors.$tiuSortPanelPriceDiscount = $('.tiu_sort_panel .sort_price_discount');
  } 

  run() {
    this.selectors.Filter.addEventListener('change', this.#fetchProducts.bind(this));
    this.selectors.Name.addEventListener('input', debounce(350, this.#fetchProducts.bind(this)));
  }

  async #fetchProducts() {
    this.app.modules.TiuProduct.resetList();
    await this.app.modules.TiuProduct.fetchList();
    await this.app.modules.TiuProduct.fillList();
  } 
    
  getName() {  
    const value = this.selectors.Name.value.trim().replaceAll(/\s+/g, ' ');
    return value !== '' && value.length > 2 ? value : null;
  }
  
  getValues() { 
    return {
      name: this.getName(),
      groups: this.app.modules.TiuProductGroups.getSelectedIds(), 
      sku: this.selectors.$tiuSortPanelSku[0].value !== 'all' ? this.selectors.$tiuSortPanelSku[0].value : null,  
      mark: this.selectors.$tiuSortPanelMark[0].value !== 'all' ? this.selectors.$tiuSortPanelMark[0].value : null,  
      model: this.selectors.$tiuSortPanelModel[0].value !== 'all' ? this.selectors.$tiuSortPanelModel[0].value : null,
      generation: this.selectors.$tiuSortPanelGeneration[0].value !== 'all' ? this.selectors.$tiuSortPanelGeneration[0].value : null,
      presence: this.selectors.$tiuSortPanelPresence[0].value !== 'all' ? this.selectors.$tiuSortPanelPresence[0].value : null,
      discount: this.selectors.$tiuSortPanelDiscount[0].value !== 'all' ? this.selectors.$tiuSortPanelDiscount[0].value : null,
      storage: this.selectors.$tiuSortPanelStorage[0].value !== 'all' ? this.selectors.$tiuSortPanelStorage[0].value : null,
      price: this.selectors.$tiuSortPanelPrice[0].value !== 'all' ? this.selectors.$tiuSortPanelPrice[0].value : null,
    };   
  }

    getOrderByValues() { 
        return { 
            price: this.selectors.$tiuSortPanelPrice[0].value !== 'all' ? this.selectors.$tiuSortPanelPrice[0].value : null,
            // priceDiscount: this.selectors.$tiuSortPanelPriceDiscount[0].value !== 'all' ? this.selectors.$tiuSortPanelPriceDiscount[0].value : null,
        };  
    }
  
} 
 